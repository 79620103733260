<template>
    <div class="form-group" :class="wrapperClass">
        <label v-if="label" :for="id" class="form-control-label">{{ label }}</label>
        <input class="form-control" :type="type ?? 'text'" v-model="value" :id="id" :class="classes" :name="name" :readonly="readonly"/>
    </div>
</template>


<script>
import { nanoid } from 'nanoid'

export default {
    components: {},
    props: ['name', 'modelValue', 'label', 'class', 'type', 'repeaterUpdate', 'repeaterIndex', 'wrapperClass', 'readonly'],
    data() {
        return {};
    },
    methods: {},
    computed: {
        value: {
            get: function () {
                return this.modelValue;
            },
            set: function (newValue) {
                if (typeof this.repeaterUpdate == 'function')
                    this.repeaterUpdate(this.repeaterIndex, this.name, newValue); //Обязательное для функционирования повторителя
                this.$emit('update:modelValue', newValue);
            },
        },
        id: function ()  {
            return this.name + "-" + nanoid(5);
        },
        classes: function() {
            return this.class+' '+(this.readonly ? 'bg-white' : '')
        }
    },
}
</script>