<template>
    <div>
        <div class="arrow-left" @mouseenter="startScrollLeft" @mouseleave="stopScroll">
            <i class="ni ni-bold-left" :class="{'d-none': cantScrollLeft}"></i>
        </div>
        <div class="arrow-right" @mouseenter="startScrollRight" @mouseleave="stopScroll">
            <i class="ni ni-bold-right" :class="{'d-none': cantScrollRight}"></i>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        perfectScrollbar: {
            type: [Object, null],
            required: true
        }
    },
    data() {
        return {
            cantScrollLeft: false,
            cantScrollRight: false,
        }
    },
    methods: {
        startScrollLeft() {
            const ps = this.perfectScrollbar;
            this.scrollInterval = setInterval(() => {
                this.cantScrollRight = false;
                if (ps.scrollLeft <= 0) {
                    this.cantScrollLeft = true;
                    this.stopScroll();
                }
                else {
                    this.cantScrollLeft = false;
                    ps.scrollBy(-15, 0);
                }
            }, 20);
        },
        startScrollRight() {
            const ps = this.perfectScrollbar;
            this.scrollInterval = setInterval(() => {
                this.cantScrollLeft = false;
                if (ps.scrollLeft >= ps.scrollWidth - ps.clientWidth) {
                    this.stopScroll();
                    this.cantScrollRight = true;
                }
                else {
                    this.cantScrollRight = false;
                    ps.scrollBy(15, 0);
                }
            }, 20);
        },
        stopScroll() {
            clearInterval(this.scrollInterval);
        },
    }
}
</script>

<style lang="scss" scoped>
    .arrow-left, .arrow-right{
        position: absolute;
        width: 50px;
        height: calc(100% - 30px);
        top: 0;
        z-index: 10;

        i{
            position: absolute;
            top: 47%;
            font-size: 3rem;
            color: #344767;
            visibility: hidden;
        }

        &:hover i{
            visibility: visible;
            cursor: pointer;
        }
    }

    .arrow-left{
        left: -20px;

        i{
            left: 0;
        }
    }

    .arrow-right{
        right: -20px;

        i{
            right: 0;
        }
    }
</style>