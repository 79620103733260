<template>
    <Head :title="$t('create')"/>
    <div class="container-fluid py-4">
        <div class="row">
            <div class="col-md-8">
                <div class="card">
                    <form @submit.prevent="form.post(route('user.store'))">
                        <div class="card-header pb-0">
                            <div class="d-flex align-items-center">
                                <p class="mb-0">{{ $t('create') }}</p>
                                <button type="submit" :disabled="form.processing" class="btn btn-primary btn-sm ms-auto" v-if="hasPermission('create-user')">{{ $t('save') }}</button>
                            </div>
                        </div>
                        <div class="card-body">
                            <Alert :wrapper="''" :errors="errors"/>
                            <p class="text-uppercase text-sm">{{ $t('user.block_main') }}</p>
                            <div class="row">
                                <div class="col-md-6">
                                    <div class="form-group">
                                        <TextInput v-model="form.username" name="username" :label="$t('user.username')" :class="[(form.errors.username ? 'is-invalid' : '')]"/>
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <div class="form-group">
                                        <TextInput type="email" v-model="form.email" name="email" :label="$t('user.email')" :class="[(form.errors.email ? 'is-invalid' : '')]"/>
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <div class="form-group">
                                        <TextInput v-model="form.firstname" name="firstname" :label="$t('user.firstname')" :class="[(form.errors.firstname ? 'is-invalid' : '')]"/>
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <div class="form-group">
                                        <TextInput v-model="form.lastname" name="lastname" :label="$t('user.lastname')" :class="[(form.errors.lastname ? 'is-invalid' : '')]"/>
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <div class="form-group repeater-wrapper">
                                        <PhoneInput :label="$t('user.phone')" v-model="form.phone" placeholder="+48 12 345 67 89" name="phone" class="repeater-input-wrapper" :class="[(form.errors.phone ? 'is-invalid' : '')]"/>
                                    </div>
                                </div>
                            </div>
                            <hr class="horizontal dark">
                            <p class="text-uppercase text-sm">{{ $t('user.block_password') }}</p>
                            <div class="row">
                                <div class="col-md-6">
                                    <div class="form-group">
                                        <TextInput type="password" v-model="form.password" name="password" :label="$t('user.password')" :class="[(form.errors.password ? 'is-invalid' : '')]"/>
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <div class="form-group">
                                        <TextInput type="password" v-model="form.password_confirmation" name="password_confirmation" :label="$t('user.password_confirmation')" :class="[(form.errors.password_confirmation ? 'is-invalid' : '')]" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
            <div class="col-md-4">
                <div class="card card-profile avatar-profile h-100">
                    <div class="row justify-content-center h-100" v-if="!avatarEditMode">
                        <div class="col-6 col-lg-6 order-lg-2 h-100">
                            <div class="mb-4 mb-lg-0 text-center vetical-align-avatar h-100">
                                <a @click="triggerFileSelection();" href="javascript:;" class="avatar-link rounded-circle img-fluid border border-2 border-white">
                                    <img :src="form.avatar ?? '/img/team-2.jpg'" class="rounded-circle img-fluid border border-2 border-white">
                                    <div class="avatar-hover"><i class="far fa-edit"></i></div>
                                </a>
                            </div>
                        </div>
                    </div>
                    <div class="card-body pt-0 pt-4 pb-4">
                        <div class="row">
                            <div class="col">
                                <div class="d-flex justify-content-center d-none">
                                    <FileInput ref="fileInput" @input="onFileChange($event)" name="avatar" :label="$t('user.avatar')" :class="[(form.errors.avatar ? 'is-invalid' : '')]"/>
                                </div>
                                <div class="row justify-content-center" v-show="uploadedImage && avatarEditMode">
                                    <div class="col-8">
                                        <cropper
                                            class="cropper"
                                            ref="cropper"
                                            :src="uploadedImage"
                                            :stencil-component="$options.components.CropperStencil"
                                            :stencil-props="{
                                                aspectRatio: 1/1,
                                            }"
                                        ></cropper>
                                        <div class="d-flex justify-content-between editor-buttons">
                                            <button class="btn btn-success btn-sm btn-sm" @click="crop">{{ $t('save') }}</button>
                                            <button class="btn btn-secondary btn-sm btn-sm" @click="croppedImage = null; form.avatar = null; uploadedImage = null; avatarEditMode = false;">
                                                {{ $t('cancel_button') }}
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>


<script>
import AuthenticatedLayout from "../../Layouts/AuthenticatedLayout.vue";
import { Head, Link, useForm, usePage } from "@inertiajs/vue3"
import { usePermissions } from '../../Composables/usePermissions';
import { resizeDataUrlImage } from '../../Composables/imageResize';
import CropperStencil from '../../Components/CropperStencil.vue';
import Alert from '../../Components/Alert.vue';
import { Cropper, CircleStencil } from 'vue-advanced-cropper';
import 'vue-advanced-cropper/dist/style.css';

import { TextInput, FileInput, PhoneInput} from "../../Components/Form"

export default {
    layout: AuthenticatedLayout,
    components: {
        Alert, TextInput, Head, FileInput, Cropper, CircleStencil, CropperStencil, PhoneInput
    },
    props: {
        user: Object,
        errors: Object
    },
    setup(props) {
        const { hasRole, hasPermission } = usePermissions();

        const form = useForm({
            username: null,
            firstname: null,
            lastname: null,
            email: null,
            phone: null,
            avatar:  null,
            password: null,
            password_confirmation: null,
        });

        return { form, hasRole, hasPermission };
    },
    data() {
        return {
            avatarEditMode: false,
            croppedImage: null,
			uploadedImage: null
        };
    },
    methods: {
		async crop() {
			const { coordinates, canvas } = this.$refs.cropper.getResult();
			this.coordinates = coordinates;

			if (canvas) {
				this.croppedImage = canvas.toDataURL(); 
                this.form.avatar = await resizeDataUrlImage({
					dataUrl: canvas.toDataURL(),
					width: 250,
					height: 250,
                });
                this.avatarEditMode = false;
			};
		},
		onFileChange(event) {
            const input = event.target;
            this.uploadedImage = null;
            if (input.files && input.files[0]) {
				const reader = new FileReader();
				reader.onload = (e) => { this.uploadedImage = e.target.result;  this.avatarEditMode = true; };
                reader.readAsDataURL(input.files[0]);
			}
		},
        triggerFileSelection()
        {
            this.$refs.fileInput.$el.children.avatar.click();
        }
	},
}

</script>

<style scoped>
.cropper {
    height: 300px;
    border-radius: 1rem;
    overflow: hidden;
}

.vetical-align-avatar{
    display: flex;
    align-items: center;
    justify-content: center;
}
</style>