<template>
    <Head :title="$t('edit')"/>
    <Listnav :title="form.full_name">
        <template #buttons>
            <div class="col-auto my-auto">
                <button class="btn btn-primary btn-sm ms-auto m-0 me-2" :disabled="form.processing" @click.prevent="submitForm()">{{ $t('edit') }}</button>
                <button v-if="hasRole('Admin') || hasRole('Manager')" class="btn btn-danger btn-sm ms-auto m-0" @click.prevent="deleteLead()">{{ $t('delete') }}</button>
            </div>
        </template>
    </Listnav>
    <div class="lead-edit py-4">
        <div class="container-fluid">
            <div class="row">
                <div class="col-md-12">
                    <form class="card pb-4 shadow-none bg-transparent">
                        <div class="nav-wrapper position-relative end-0 mb-3">
                            <ul class="nav nav-pills nav-fill p-1 stages">
                                <li class="nav-item" v-for="stage in stages" :class="(form.stage_id == stage.kb_stage_id)?'active':''" @click="form.stage_id = stage.kb_stage_id">
                                    <a class="nav-link mb-0 px-0 py-1" :class="(form.stage_id == stage.kb_stage_id)?stage.background+' active':''">
                                        {{ stage.title }}
                                    </a>
                                </li>
                            </ul>
                        </div>
                        <div class="row">
                            <div class="col-md-6">
                                <div class="row">
                                    <div class="col-lg-12">
                                        <div class="card">
                                            <div class="card-body">
                                                <h5 class="font-weight-bolder">{{ $t('leads.contact_info') }}</h5>
                                                <Alert :wrapper="'pb-0'" :errors="errors" :fields="['full_name', 'birthday', 'sex', 'phones.0.phone']"/>
                                                <div class="row">
                                                    <div class="col-md-5">
                                                        <TextInput v-model="form.full_name" name="full_name" :label="$t('leads.full_name')" :class="(errors.full_name ? 'is-invalid' : '')"/>
                                                    </div>
                                                    <div class="col-md-3">
                                                        <Date v-model="form.birthday" :label="$t('leads.birthday')" :class="(errors.birthday) ? 'is-invalid' : ''"/>
                                                    </div>
                                                    <div class="col-md-4">
                                                        <SimpleSelect 
                                                            v-model="form.sex"
                                                            :label="$t('leads.sex')"
                                                            name="sex"
                                                            :options="[
                                                                {label: $t('leads.male'), value: 1},
                                                                {label: $t('leads.female'), value: 2},
                                                                {label: $t('leads.family'), value: 3},
                                                            ]"
                                                        />
                                                    </div>
                                                </div>
                                                <div class="row">
                                                    <div class="col-md-12">
                                                        <Repeater :wrapper="'row'" :class="'col-md-6'" name="phones" v-model="form.phones" :errors="errors" :label="$t('leads.phones')">
                                                            <template v-slot="{ item, repeaterUpdate, index, error }">
                                                                <PhoneInput :modelValue="item['phone']" :repeater-index="index" :repeater-update="repeaterUpdate" placeholder="+48 12 345 67 89" name="phone" class="repeater-input-wrapper" :class="[(error?.phone ? 'is-invalid' : '')]"/>
                                                            </template>
                                                        </Repeater>
                                                    </div>
                                                    <div class="col-md-6">
                                                        <SelectAjax  
                                                            v-model="form.city" 
                                                            name="city" 
                                                            :label="$t('leads.city')" 
                                                            :clearable="true" 
                                                            :ajax="{relatedModel: 'App\\Models\\City',resource: 'App\\Http\\Resources\\CityResource',attribute: 'name'}"
                                                            :hasError="form.errors.city_id ? true : false"
                                                        />
                                                    </div>
                                                    <div class="col-md-12">
                                                        <AddressInput v-model="form.google_address" name="google_address" :label="$t('leads.google_address')" :class="(errors.google_address) ? 'is-invalid' : ''"/>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="row mt-4">
                                    <div class="col-lg-12">
                                        <div class="card">
                                            <div class="card-body">
                                                <h5 class="font-weight-bolder">{{ $t('leads.recruiting') }}</h5>
                                                <Alert :wrapper="'pb-0'" :errors="errors" :fields="['vacancy_id', 'first_workday']"/>
                                                <div class="row">
                                                    <div class="col-md-6">
                                                        <SelectAjax  
                                                            v-model="form.vacancy" 
                                                            name="vacancy" 
                                                            :label="$t('leads.vacancy')" 
                                                            :clearable="false" 
                                                            :ajax="{relatedModel: 'App\\Models\\Vacancy', resource: 'App\\Http\\Resources\\VacancyShortResource', attribute: 'title'}"  
                                                            :hasError="form.errors.vacancy_id ? true : false"
                                                        />
                                                    </div>
                                                    <div class="col-md-6">
                                                        <Date v-model="form.first_workday" :label="$t('leads.first_workday')" :class="(errors.first_workday) ? 'is-invalid' : ''"/>
                                                    </div>
                                                </div>
                                                <div class="row" v-if="hasRole('Manager') || hasRole('Admin')">
                                                    <div class="col-md-6">
                                                        <SimpleSelect  
                                                            v-model="form.responsible_id"
                                                            name="user" 
                                                            :label="$t('recruiter')" 
                                                            :multiple="false" 
                                                            :options="users" 
                                                            :class="[(errors.responsible_id ? 'is-invalid' : '')]"
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-6">
                                <div class="row">
                                    <div class="col-12">
                                        <div class="card">
                                            <div class="card-body">
                                                <h5 class="font-weight-bolder">{{ $t('leads.documents') }}</h5>
                                                <Dropzone v-model="form.documents"/>
                                                <div class="text-end">
                                                    <a :href="route('lead.media.all', lead.id)" target="_blank" download title="Скачать все"><i class="fas fa-download"></i></a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="row mt-4">
                                    <div class="col-12">
                                        <div class="card">
                                            <div class="card-body">
                                                <h5 class="font-weight-bolder">{{ $t('leads.comment') }}</h5>
                                                <div class="card bg-gray-200 mt-2" v-for="comment in form.comments">
                                                    <div class="card-body py-2 px-3">
                                                        <p class="mb-0">
                                                            <span v-html="comment.comment"></span>
                                                        </p>
                                                        <div class="d-flex align-items-center text-sm opacity-6">
                                                            <!-- <i class="ni ni-check-bold text-sm me-1"></i> -->
                                                            <small>{{ comment.created }}, {{ comment.author_name }}</small>
                                                        </div>
                                                    </div>
                                                </div>
                                                <Html name="comment" :wrapper="'mt-3'" v-model="form.comment"/>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { useForm, Head, Link, router } from '@inertiajs/vue3'
import { usePermissions } from '../../Composables/usePermissions';

import Listnav from "../../Layouts/Navbars/Auth/Listnav.vue";
import AuthenticatedLayout from '../../Layouts/AuthenticatedLayout.vue';
import Alert from '../../Components/Alert.vue';
import { PerfectScrollbar } from 'vue3-perfect-scrollbar';
import { usePage } from '@inertiajs/vue3';


import { TextInput, PhoneInput, Date, SimpleSelect, SelectAjax, Repeater, Dropzone, Html, AddressInput } from "../../Components/Form"

export default {
    layout: AuthenticatedLayout,
    components: {
        Alert,
        Head,
        Link,
        Listnav,
        PerfectScrollbar,
        TextInput,
        PhoneInput,
        SimpleSelect,
        SelectAjax,
        Date,
        Repeater,
        Dropzone,
        Html,
        AddressInput
    },
    props: {
        lead: Object,
        stages: Object,
        errors: Object,
        users: Array
    },
    setup(props) {
        const { hasRole, hasPermission } = usePermissions();
        const page = usePage();
        const form = useForm({
            stage_id: props.lead.stage_id ?? 0,
            full_name: props.lead.full_name ?? null,
            birthday: props.lead.birthday ?? null,
            sex: props.lead.sex ?? null,
            phones: props.lead.phones ?? [{}],
            vacancy: props.lead.vacancy ?? null,
            city: props.lead.city ?? null,
            first_workday: props.lead.first_workday ?? null,
            documents: props.lead.documents ?? [],
            google_address: props.lead.google_address ?? null,
            comments: props.lead.comments ?? null,
            comment: null,
            responsible_id: props.lead?.responsible?.id ?? page.props.auth.user.id
        });
        
        return { form, hasRole, hasPermission };
    },
    methods: {
        submitForm() {
            this.form.transform((data) => (this.prepareItem(data))).put(route('lead.update', this.lead.id));
        },
        prepareItem(data) {
            data.vacancy_id = data.vacancy?.id ?? null;
            data.city_id = data.city?.id ?? null;

            if (data.documents !== null) {
                let files = [];
                data.documents.forEach(file => {
                    delete file.file;
                    if (!('options' in file))
                        delete file.source;
                    files.push(file);
                });
                data.documents = files;
            }

            return data;
        },
        deleteLead() {
            Swal.fire({
                title: "Подтвердить удаление?",
                text: "Изменения нельзя будет отменить!!!",
                icon: "warning",
                showCancelButton: true,
                confirmButtonText: "Да",
                cancelButtonText: "Отменить",
                confirmButtonColor: "#2dce89",
                cancelButtonColor: "#d33",
            })
            .then((result) => {
                if (result.isConfirmed) {
                    router.delete(route('lead.destroy', this.lead.id));
                }
            });
            
        },
        downloadAll() {
            console.log('download');
        }
    }
}
</script>

<style lang="scss" scoped>
.stages{
    .nav-item{
        margin-left: 5px;
    }
    .nav-item:not(.active):hover{
        cursor: pointer;
        //font-weight: 600;
        box-shadow: 0 1px 5px 1px #ddd;
        //animation: .2s ease;
        background-color: #fff;
        border-radius: 0.5rem;
    }
    .nav-item.active {
        cursor: default;
        .nav-link, .nav-link:hover{
            font-weight: 600;
            box-shadow: 0 1px 5px 1px #ddd;
            color: #fff;
        }
    }
}
</style>