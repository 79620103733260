<template>
    <div class="row">
        <div class="col">
            <div class="nav-wrapper position-relative end-0">
                <ul class="nav nav-pills nav-fill p-1 tabs">
                    <li class="nav-item" :class="route().current(name('edit'))?'active':''">
                        <Link :href="url('edit')" class="nav-link mb-0 px-0 py-1" :title="$t('user.tab_main')" replace>{{ $t('user.tab_main') }}</Link>
                    </li>
                    <li class="nav-item" :class="route().current(name('recruiting'))?'active':''">
                        <Link :href="url('recruiting')" class="nav-link mb-0 px-0 py-1" :title="$t('user.tab_recruiter')" replace>{{ $t('user.tab_recruiter') }}</Link>
                    </li>
                </ul>
            </div>
        </div>
    </div>
</template>

<script>
import { Link } from "@inertiajs/vue3"

export default {
    components: {
        Link
    },
    props: {
        prefix: {
            type: String,
            required: true
        },
        user: {
            type: Object,
            required: true
        }
    },
    methods: {
        name(suffix) {
            return this.prefix + '.' + suffix;
        },
        url(suffix) {
            let id = null;
            if (this.prefix == 'user')
                id = this.user.id;
            return route(this.name(suffix), id);

        }
    }
}
</script>