<template>
    <Head :title="$t('edit')"/>
    <Listnav :title="template.title">
        <template #buttons>
            <div class="col-auto my-auto">
                <button class="btn btn-primary btn-sm ms-auto m-0" :disabled="form.processing" @click.prevent="submitForm()">{{ $t('edit') }}</button>
            </div>
        </template>
    </Listnav>
    <div class="lead-edit py-4">
        <div class="container-fluid">
            <div class="row">
                <div class="col-md-12">
                    <form class="card pb-4 shadow-none bg-transparent">
                        <div class="card">
                            <div class="card-body">
                                <Alert :wrapper="'pb-0'" :errors="errors"/>
                                
                                <div class="row">
                                    <div class="col-6">
                                        <TextInput v-model="form.title" name="title" :label="$t('template.title')" wrapperClass="mb-0" :class="[(form.errors.title ? 'is-invalid' : '')]"/>
                                        <Html v-model="form.blueprint" height="65vh" label="" name="blueprint" class="mt-4" :class="[(form.errors.blueprint ? 'is-invalid' : '')]"></Html>
                                    </div>
                                    <div class="col-6">
                                        <SelectAjax
                                            name="example_vacancy"
                                            v-model="example_vacancy"  
                                            :label="$t('leads.vacancy')" 
                                            :clearable="false" 
                                            :ajax="{relatedModel: 'App\\Models\\Vacancy', resource: 'App\\Http\\Resources\\VacancyShortResource', attribute: 'title'}"
                                        />
                                        <div class="preview-wrap mt-4" v-html="preview"></div>
                                    </div>
                                </div>
                                
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { useForm, Head, Link } from '@inertiajs/vue3'
import { usePermissions } from '../../Composables/usePermissions';

import Listnav from "../../Layouts/Navbars/Auth/Listnav.vue";
import AuthenticatedLayout from '../../Layouts/AuthenticatedLayout.vue';
import Alert from '../../Components/Alert.vue';
import { PerfectScrollbar } from 'vue3-perfect-scrollbar';
import { usePage } from '@inertiajs/vue3'

import { TextInput, SelectAjax } from "../../Components/Form"
import Html from "../../Components/Form/VacancyTemplate/Html.vue"
import axios from 'axios';

export default {
    layout: AuthenticatedLayout,
    components: {
        Alert,
        Head,
        Link,
        Listnav,
        PerfectScrollbar,
        TextInput,
        SelectAjax,
        Html
    },
    props: {
        template: Object,
        errors: Object,
        vacancy: Object
    },
    setup(props) {
        const page = usePage();
        const { hasRole, hasPermission } = usePermissions();
        const form = useForm({
            title: props.template.title,
            blueprint: props.template.blueprint
        });

        return { form, hasRole, hasPermission };
    },
    data() {
        return {
            preview: '',
            example_vacancy: this.vacancy
        };
    },
    watch: {
        'form.blueprint': function (newVal, oldVal) {
            this.makePreview(newVal);
        },
        example_vacancy() {
            this.makePreview(this.form.blueprint);
        }
    },
    methods: {
        makePreview(text) { 
            axios.post(route('template.sample'), { vacancy_id: this.example_vacancy?.id, blueprint: text }).then((response) => {
                this.preview = response.data.text;
            });
        },
        submitForm(){
            this.form.put(route('template.update', this.template.id));
        },
    },
    mounted() {
        this.makePreview(this.form.blueprint);
    }
}
</script>

<style lang="scss" scoped>
.preview-wrap{
    border: 1px solid #ccc;
    border-radius: 0.5rem;
    height: 68.4vh;
    padding: 2rem;
    overflow-y: auto;
}
</style>