import { VueRenderer } from '@tiptap/vue-3'
import tippy from 'tippy.js'

import MentionList from './MentionList.vue'

export default {
    //char: '#',
    items: ({ query }) => {
        return [
            { code: 'featured', label: 'избранная' },
            { code: 'title', label: 'название' },
            { code: 'hot', label: 'срочная' },
            { code: 'medical_book', label: 'санкнижка' },
            { code: 'udt', label: 'права udt' },
            { code: 'experience', label: 'опыт работы' },
            { code: 'min_age', label: 'минимальный возраст' },
            { code: 'max_age', label: 'максимальный возраст' },
            { code: 'allowed_genders', label: 'пол' },
            { code: 'position', label: 'должность' },
            { code: 'number_of_workplaces', label: 'рабочих мест' },
            { code: 'citizenships', label: 'гражданства' },
            { code: 'special_requirements', label: 'особые требования' },
            { code: 'facility', label: 'обьект' },
            { code: 'company', label: 'посредник' },
            { code: 'work_city', label: 'город выполнения работ' },
            { code: 'documents_city', label: 'город оформления документов' },
            { code: 'base_rate', label: 'базовая ставка' },
            { code: 'student_rate', label: 'ставка для студента' },
            { code: 'young_rate', label: 'ставка до 26' },
            { code: 'average_per_month', label: 'средняя в месяц' },
            { code: 'housing_type', label: 'тип жилья' },
            { code: 'housing_cost', label: 'стоимость жилья' },
            { code: 'student_housing_cost', label: 'стоимость жилья для студента' },
            { code: 'housing_compensation', label: 'компенсация за жильё' },
            { code: 'contract_type', label: 'вид трудового договора' },
            { code: 'conditions', label: 'описание условий' },
            { code: 'night_shifts', label: 'ночные смены' },
            { code: 'work_schedule_text', label: 'график работы' }
        ].filter(item => item.label.toLowerCase().includes(query.toLowerCase())).slice(0, 5)
    },

    render: () => {
        let component
        let popup

        return {
            onStart: props => {
                component = new VueRenderer(MentionList, {
                    props,
                    editor: props.editor,
                })

                if (!props.clientRect) {
                    return
                }

                popup = tippy('body', {
                    getReferenceClientRect: props.clientRect,
                    appendTo: () => document.body,
                    content: component.element,
                    showOnCreate: true,
                    interactive: true,
                    trigger: 'manual',
                    placement: 'bottom-start',
                })
            },

            onUpdate(props) {
                component.updateProps(props)

                if (!props.clientRect) {
                    return
                }

                popup[0].setProps({
                    getReferenceClientRect: props.clientRect,
                })
            },

            onKeyDown(props) {
                if (props.event.key === 'Escape') {
                    popup[0].hide()

                    return true
                }

                return component.ref?.onKeyDown(props)
            },

            onExit() {
                popup[0].destroy()
                component.destroy()
            },
        }
    },
}