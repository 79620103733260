//Laravel Vite
import './bootstrap';
import './argon';
import './custom';

import { createApp, h } from 'vue';
import { createInertiaApp } from '@inertiajs/vue3'
import { ZiggyVue } from 'ziggy-js';
import { i18nVue } from "laravel-vue-i18n";
import VueEasyLightbox from 'vue-easy-lightbox';
import Toast from "vue-toastification";
import FloatingVue from 'floating-vue'

//Inertia application
createInertiaApp({
    resolve: name => {
        const pages = import.meta.glob('./Pages/**/*.vue', { eager: true })
        return pages[`./Pages/${name}.vue`]
    },
    setup({ el, App, props, plugin }) {
        const app = createApp({ render: () => h(App, props) })
            .use(plugin)
            .use(ZiggyVue)
            .use(VueEasyLightbox)
            .use(Toast)
            .use(FloatingVue)
            .use(i18nVue, {
                fallbackLang: 'en',
                lang: 'ru',
                resolve: async lang => {
                    const langs = import.meta.glob('../../lang/*.json');
                    return await langs[`../../lang/${lang}.json`]();
                }
            });
        
        app.mount(el);
    },
});