<template>
    <Head :title="$t('create')"/>
    <Listnav>
        <template #title>
            <div class="col-md-10 my-sm-auto ms-sm-0 me-sm-auto mx-auto mt-3">
                <input class="form-control vacancy-title-input h5" type="text" :value="title" @change="form.title = $event.target.value"/>
            </div>
        </template>
        <template #middle>
            <div class="col-auto my-auto">
            </div>
        </template>
        <template #buttons>
            <div class="col-auto my-auto">
                <button class="btn btn-primary btn-sm ms-auto m-0" :disabled="form.processing" @click.prevent="submitForm">{{ $t('save') }}</button>
            </div>
        </template>
    </Listnav>
    <div class="vacancy-edit py-4">
        <div class="container-fluid">
            <div class="row">
                <div class="col-md-12">
                    <form class="card pb-4 shadow-none bg-transparent" @submit.prevent="submitForm">
                        <div class="row mt-2">
                            <div class="col">
                                <div class="nav-wrapper position-relative end-0 mb-3">
                                    <ul class="nav nav-pills nav-fill p-1 tabs">
                                        <li class="nav-item" :class="(tab===1)?'active':''">
                                            <a class="nav-link mb-0 px-0 py-1" @click.prevent="tab=1" :class="(tab===1)?'active':''">
                                                {{ $t('vacancies.parameters') }}
                                                <div class="error-warning" v-if="Object.keys(errors).length > 0"></div>
                                            </a>
                                        </li>
                                        <li class="nav-item" :class="(tab===2)?'active':''">
                                            <a class="nav-link mb-0 px-0 py-1" @click.prevent="tab=2" :class="(tab===2)?'active':''">
                                                {{ $t('vacancies.description') }}
                                            </a>
                                        </li>
                                        <li class="nav-item" :class="(tab===3)?'active':''">
                                            <a href="javascript:void(0);" class="nav-link mb-0 px-0 py-1" @click.prevent="tab=3" :class="(tab===3)?'active':''">
                                                {{ $t('vacancies.photo') }}
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div class="tab1" v-show="tab===1">
                            <div class="row mt-2">
                                <div class="col-lg-4">
                                    <div class="card">
                                        <div class="card-body">
                                            <Alert :wrapper="'pb-0'" :errors="errors" :fields="['active', 'featured', 'hot', 'title']"/>
                                            <div class="row">
                                                <div class="col-12 col-sm-4">
                                                    <checkbox :label="$t('vacancies.active')" name="active" template="inline" v-model="form.active"></checkbox>
                                                </div>
                                                <div class="col-12 col-sm-4">
                                                    <checkbox :label="$t('vacancies.featured')" name="featured" template="inline" v-model="form.featured"></checkbox>
                                                </div>
                                                <div class="col-12 col-sm-4">
                                                    <checkbox :label="$t('vacancies.hot')" name="hot" template="inline" v-model="form.hot"></checkbox>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="card mt-4">
                                        <div class="card-body">
                                            <h5 class="font-weight-bolder">{{ $t('vacancies.requirements') }}</h5>
                                            <Alert :wrapper="'pb-0'" :errors="errors" :fields="['medical_book', 'udt', 'experience', 'min_age', 'max_age', 'allowed_genders', 'position', 'number_of_workplaces', 'citizenships']"/>
                                            <div class="row">
                                                <div class="col-12 col-sm-4">
                                                    <checkbox :label="$t('vacancies.medical_book')" name="medical_book" v-model="form.medical_book"></checkbox>
                                                </div>
                                                <div class="col-12 col-sm-4">
                                                    <checkbox :label="$t('vacancies.udt')" name="udt" v-model="form.udt"></checkbox>
                                                </div>
                                                <div class="col-12 col-sm-4">
                                                    <checkbox :label="$t('vacancies.experience')" name="experience" v-model="form.experience"></checkbox>
                                                </div>
                                            </div>
                                            <div class="row mt-4">
                                                <div class="col-12 col-sm-6">
                                                    <label>{{ $t('vacancies.age') }}</label>
                                                    <div class="row">
                                                        <div class="col-6">
                                                            <TextInput v-model="form.min_age" name="min_age" label="" :class="[(form.errors.min_age ? 'is-invalid' : '')]"/>
                                                        </div>
                                                        <div class="col-6">
                                                            <TextInput v-model="form.max_age" name="max_age" label="" :class="[(form.errors.max_age ? 'is-invalid' : '')]"/>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-12 col-sm-6">
                                                    <SimpleSelect  v-model="form.allowed_genders" name="allowed_genders" :label="$t('vacancies.allowed_genders')" :multiple="true" :options="[{label: 'М',value: 1},{label: 'Ж',value: 2},{label: 'М+Ж',value: 3}]" :class="[(form.errors.allowed_genders ? 'is-invalid' : '')]"/>
                                                </div>
                                            </div>
                                            <div class="row mt-4">
                                                <div class="col-12 col-sm-6">
                                                    <TextInput v-model="form.position" name="position" :label="$t('vacancies.position')" :class="[(form.errors.position ? 'is-invalid' : '')]"/>
                                                </div>
                                                <div class="col-12 col-sm-3">
                                                    <TextInput v-model="form.number_of_workplaces" name="number_of_workplaces" :label="$t('vacancies.number_of_workplaces')" :class="[(form.errors.number_of_workplaces ? 'is-invalid' : '')]"/>
                                                </div>
                                                <div class="col-12 col-sm-3">
                                                    <TextInput v-model="form.required_workdays" name="required_workdays" :label="$t('vacancies.required_workdays')" :class="[(form.errors.required_workdays ? 'is-invalid' : '')]"/>
                                                </div>
                                            </div>
                                            <div class="row mt-4">
                                                <div class="col-12 col-sm-12">
                                                    <SelectAjaxMultiple  v-model="form.citizenships" name="citizenships" :label="$t('vacancies.citizenships')" :multiple="true" :clearable="false" :ajax="{relatedModel: 'App\\Models\\Citizenship', resource: 'App\\Http\\Resources\\CitizenshipResource', attribute: 'name'}"  :hasError="form.errors.citizenships ? true : false"/>
                                                </div>
                                            </div>
                                            <div class="row mt-4">
                                                <div class="col-12">
                                                    <Html v-model="form.special_requirements" name="special_requirements" :label="$t('vacancies.special_requirements')" :class="[(form.errors.special_requirements ? 'is-invalid' : '')]" />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-8 mt-lg-0 mt-4">
                                    <div class="row">
                                        <div class="col-sm-4">
                                            <div class="card">
                                                <div class="card-body">
                                                    <h5 class="font-weight-bolder">{{ $t('vacancies.workplace') }}</h5>
                                                    <Alert :wrapper="'pb-0'" :errors="errors" :fields="['facility_id', 'company_id', 'work_city_id', 'documents_city_id']"/>
                                                    <SelectAjax  v-model="form.facility" name="facility" :label="$t('vacancies.facility')" :clearable="false" :ajax="{relatedModel: 'App\\Models\\Vacancies\\Facility',resource: 'App\\Http\\Resources\\FacilityResource',attribute: 'name'}"  :hasError="form.errors.facility_id ? true : false"/>
                                                    <SelectAjax  v-model="form.company" name="company" :label="$t('vacancies.company')" :clearable="false" :ajax="{relatedModel: 'App\\Models\\Vacancies\\Company',resource: 'App\\Http\\Resources\\CompanyResource',attribute: 'name'}"  :hasError="form.errors.company_id ? true : false"/>
                                                    <SelectAjax  v-model="form.work_city" name="work_city" :label="$t('vacancies.work_city')" :clearable="false" :ajax="{relatedModel: 'App\\Models\\City',resource: 'App\\Http\\Resources\\CityResource',attribute: 'name'}"  :hasError="form.errors.work_city_id ? true : false"/>
                                                    <SelectAjax  v-model="form.documents_city" name="documents_city" :label="$t('vacancies.documents_city')" :clearable="false" :ajax="{relatedModel: 'App\\Models\\City',resource: 'App\\Http\\Resources\\CityResource',attribute: 'name'}"  :hasError="form.errors.documents_city_id ? true : false"/>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-sm-4">
                                            <div class="card">
                                                <div class="card-body">
                                                    <h5 class="font-weight-bolder">{{ $t('vacancies.salary') }}</h5>
                                                    <Alert :wrapper="'pb-0'" :errors="errors" :fields="['base_rate', 'young_rate', 'student_rate', 'avg_salary']"/>
                                                    <TextInput v-model="form.base_rate" name="base_rate" :label="$t('vacancies.base_rate')" wrapperClass="mb-0" :class="[(form.errors.base_rate ? 'is-invalid' : '')]"/>
                                                    <TextInput v-model="form.young_rate" name="young_rate" :label="$t('vacancies.young_rate')"  wrapperClass="mb-0" :class="[(form.errors.young_rate ? 'is-invalid' : '')]"/>
                                                    <TextInput v-model="form.student_rate" name="student_rate" :label="$t('vacancies.student_rate')"  wrapperClass="mb-0" :class="[(form.errors.student_rate ? 'is-invalid' : '')]"/>
                                                    <TextInput v-model="form.avg_salary" name="avg_salary" :label="$t('vacancies.avg_salary')"  wrapperClass="mb-0" :class="[(form.errors.avg_salary ? 'is-invalid' : '')]"/>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-sm-4">
                                            <div class="card">
                                                <div class="card-body">
                                                    <h5 class="font-weight-bolder">{{ $t('vacancies.housing') }}</h5>
                                                    <Alert :wrapper="'pb-0'" :errors="errors" :fields="['housing_compensation', 'housing_type', 'housing_cost', 'student_housing_cost']"/>
                                                    <checkbox :label="$t('vacancies.housing_compensation')" name="housing_compensation" template="inline" v-model="form.housing_compensation"></checkbox>
                                                    <SimpleSelect  v-model="form.housing_type" name="housing_type" :label="$t('vacancies.housing_type')" :multiple="true" :options="[{label: 'Свое',value: 1},{label: 'Предоставляемое',value: 2}]" :class="[(form.errors.housing_type ? 'is-invalid' : '')]"/>
                                                    <TextInput v-model="form.housing_cost" name="housing_cost" :label="$t('vacancies.housing_cost')" wrapperClass="mb-0" :class="[(form.errors.housing_cost ? 'is-invalid' : '')]"/>
                                                    <TextInput v-model="form.student_housing_cost" name="student_housing_cost" :label="$t('vacancies.student_housing_cost')" wrapperClass="mb-0" :class="[(form.errors.student_housing_cost ? 'is-invalid' : '')]"/>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-sm-6 mt-4">
                                            <div class="card">
                                                <div class="card-body">
                                                    <h5 class="font-weight-bolder">{{ $t('vacancies.conditions') }}</h5>
                                                    <Alert :wrapper="'pb-0'" :errors="errors" :fields="['contract_type', 'conditions', 'closest_city_id', 'closest_city_km']"/>
                                                    <div class="row">
                                                        <div class="col-8">
                                                            <SelectAjax  v-model="form.closest_city" name="closest_city" :label="$t('vacancies.closest_city')" :clearable="false" :ajax="{relatedModel: 'App\\Models\\City',resource: 'App\\Http\\Resources\\CityResource',attribute: 'name'}"  :hasError="form.errors.closest_city_id ? true : false"/>
                                                        </div>
                                                        <div class="col-4">
                                                            <TextInput v-model="form.closest_city_km" name="closest_city_km" :label="$t('vacancies.closest_city_km')" wrapperClass="mb-0" :class="[(form.errors.closest_city_km ? 'is-invalid' : '')]"/>
                                                        </div>
                                                    </div>
                                                    <SimpleSelect  v-model="form.contract_type" name="contract_type" :label="$t('vacancies.contract_type')" :options="[{label: 'Umowa zlecenia',value: 1},{label: 'Umowa o pracę',value: 2}]" :class="[(form.errors.contract_type ? 'is-invalid' : '')]"/>
                                                    <Html v-model="form.conditions" name="conditions" :label="$t('vacancies.conditions_description')" :class="[(form.errors.conditions ? 'is-invalid' : '')]" />
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-sm-6 mt-4">
                                            <div class="card">
                                                <div class="card-body">
                                                    <h5 class="font-weight-bolder">{{ $t('vacancies.work_hours') }}</h5>
                                                    <Alert :wrapper="'pb-0'" :errors="errors" :fields="['night_shifts', 'work_schedule_text']"/>
                                                    <checkbox :label="$t('vacancies.night_shifts')" name="night_shifts" template="inline" v-model="form.night_shifts"></checkbox>
                                                    <div class="row mt-4">
                                                        <div class="col-12">
                                                            <Html v-model="form.work_schedule_text" name="work_schedule_text" label="" :class="[(form.errors.work_schedule_text ? 'is-invalid' : '')]" />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="tab2" v-show="tab===2">
                            <div class="row">
                                <div class="col-sm-12 mt-4">
                                    <div class="card">
                                        <div class="card-body">
                                            <h5 class="font-weight-bolder">{{ $t('vacancies.recruiter_description') }}</h5>
                                            <Alert :wrapper="'pb-0'" :errors="errors" :fields="['recruiter_description']"/>
                                            <Html v-model="form.recruiter_description" height="65vh" name="recruiter_description" label="" :class="[(form.errors.recruiter_description ? 'is-invalid' : '')]" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-sm-12 mt-4">
                                    <div class="card">
                                        <div class="card-body">
                                            <h5 class="font-weight-bolder">{{ $t('vacancies.vacancy_description') }}</h5>
                                            <Alert :wrapper="'pb-0'" :errors="errors" :fields="['description']"/>
                                            <Html v-model="form.description" height="65vh" name="description" label="" :class="[(form.errors.description ? 'is-invalid' : '')]" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="tab3" v-show="tab===3">
                            <div class="row">
                                <div class="col-sm-12 mt-4">
                                    <div class="card">
                                        <Alert :wrapper="'pb-0'" :errors="errors" :fields="['files']"/>
                                        <Dropzone v-model="form.files"/>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { useForm, Head, Link } from '@inertiajs/vue3'
import { usePermissions } from '../../Composables/usePermissions';
import Listnav from "../../Layouts/Navbars/Auth/Listnav.vue";
import AuthenticatedLayout from '../../Layouts/AuthenticatedLayout.vue';
import Alert from '../../Components/Alert.vue';
import { PerfectScrollbar } from 'vue3-perfect-scrollbar';
import { TextInput, Checkbox, SimpleSelect, SelectAjaxMultiple, SelectAjax, Html, Dropzone} from "../../Components/Form"

export default {
    layout: AuthenticatedLayout,
    components: {
        Alert, Head, Link, Listnav, PerfectScrollbar, Checkbox, TextInput, SimpleSelect, SelectAjaxMultiple, Html, SelectAjax, Dropzone
    },
    props: {
        errors: Object
    },
    setup() {
        const { hasRole, hasPermission } = usePermissions();

        const form = useForm({
            "title": null,
            "active": false,
            "featured": false,
            "hot": false,
            "medical_book": false,
            "udt": false,
            "experience": false,
            "min_age": null,
            "max_age": null,
            "allowed_genders": null,
            "position": null,
            "number_of_workplaces": null,
            "citizenships": null,
            "special_requirements": null,
            "facility": null,
            "company": null,
            "work_city": null,
            "closest_city": null,
            "closest_city_km": null,
            "documents_city": null,
            "base_rate": null,
            "young_rate": null,
            "student_rate": null,
            "avg_salary": null,
            "housing_compensation": false,
            "housing_type": null,
            "housing_cost": null,
            "student_housing_cost": null,
            "contract_type": null,
            "conditions": null,
            "night_shifts": false,
            "work_schedule_text": null,
            "description": null,
            "recruiter_description": null,
            "required_workdays": null,
            "files": []
        });

        return { form, hasRole, hasPermission };
    },
    data() {
        return {
            tab: 1,
        }
    },
    computed: {
        title: {
            get: function () {
                return !this.form.title ? this.titleGenerated : this.form.title;
            }
        },
        titleGenerated: function () {
            return `${this.form.position ?? ''} ${this.form.work_city?.name ?? ''}`.trim();
        },
    },
    methods: {
        submitForm(){
            this.form.transform((data) => (this.prepareItem(data))).post(route('vacancy.store'));
        },
        prepareItem(data) {
            if (data.title === null || data.title === '') {
                data.title = this.titleGenerated;
            }

            if (data.citizenships !== null) {
                let values = [];
              
                data.citizenships.forEach(element => {
                    values.push(element.id);
                });
                
                data.citizenships = values;
            }

            let selectAjaxs = ['facility', 'company', 'work_city', 'documents_city'];

            data.facility_id = data.facility?.id ?? null;
            data.company_id = data.company?.id ?? null;
            data.work_city_id = data.work_city?.id ?? null;
            data.closest_city_id = data.closest_city?.id ?? null;
            data.documents_city_id = data.documents_city?.id ?? null;

            if (data.files !== null) {
                let files = [];
                data.files.forEach(file => {
                    delete file.file;
                    if (!('options' in file))
                        delete file.source;
                    files.push(file);
                });
                data.files = files;
            }

            return data;
        }
    }
}
</script>

<style lang="scss" scoped>
    .vacancy-title-input{
        border-bottom: 1px solid rgb(210, 214, 218);
    }
</style>