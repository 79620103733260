<template>
    <label v-if="label" :for="name">{{ label }}</label>
    <span class="form-control" :class="{ 'empty-text-input': !text }" v-html="text"></span>
</template>

<script>
export default {
    components: {},
    props: ['label', 'modelValue', 'type', 'name'],
    data() {
        return {};
    },
    methods: {},
    computed: {
        text() {
            let text = '';
            if (this.modelValue) {
                text = this.modelValue;
            }
            
            return text;
        }
    },
    mounted() {},
}
</script>
<style scoped lang="scss">
    .empty-text-input{
        min-height: 2.525rem;
    }
</style>
