<template>
    <div>
        <file-pond 
            name="tmp_file" 
            ref="pond"
            class="mt-3"
            v-bind:allow-multiple="true"
            accepted-file-types="image/jpeg, image/png, application/pdf" 
            :server="serverConfig"
            v-bind:files="modelValue" 
            @processfile="handleProcessFile"
            @removefile="handleRemoveFile"
            @activatefile="handleActivateFile"
        />
        <vue-easy-lightbox :visible="visible" :imgs="gallery" :index="index" @hide="onHide" :moveDisabled="true">
            <template v-slot:toolbar="{ toolbarMethods }">
                <div class="vel-toolbar">
                    <div @click="toolbarMethods.zoomIn" role="button" aria-label="zoom in button" class="toolbar-btn toolbar-btn__zoomin">
                        <svg class="vel-icon icon" aria-hidden="true">
                        <use xlink:href="#icon-zoomin"></use>
                        </svg>
                    </div>
                    <div @click="toolbarMethods.zoomOut" role="button" aria-label="zoom out button" class="toolbar-btn toolbar-btn__zoomout">
                        <svg class="vel-icon icon" aria-hidden="true">
                        <use xlink:href="#icon-zoomout"></use>
                        </svg>
                    </div>
                    <a :href="gallery[index].src" download target="_blank" role="button" aria-label="download" class="toolbar-btn toolbar-btn__download">
                        <i class="fas fa-download"></i>
                    </a>
                    <div @click="toolbarMethods.rotateLeft" role="button" aria-label="image rotate left button" class="toolbar-btn toolbar-btn__rotate">
                        <svg class="vel-icon icon" aria-hidden="true">
                        <use xlink:href="#icon-rotate-left"></use>
                        </svg>
                    </div>
                    <div @click="toolbarMethods.rotateRight" role="button" aria-label="image rotate right button" class="toolbar-btn toolbar-btn__rotate">
                        <svg class="vel-icon icon" aria-hidden="true">
                        <use xlink:href="#icon-rotate-right"></use>
                        </svg>
                    </div>
                </div>
            </template>
        </vue-easy-lightbox>
    </div>
</template>

<script>
// Import Vue FilePond
import vueFilePond, { setOptions } from "vue-filepond";

// Import FilePond styles


// Import FilePond plugins
// Please note that you need to install these plugins separately

// Import image preview plugin styles

// Import image preview and file type validation plugins
import FilePondPluginFileValidateType from "filepond-plugin-file-validate-type";
import FilePondPluginImagePreview from "filepond-plugin-image-preview";
import FilePondFilePoster from 'filepond-plugin-file-poster';
import VueEasyLightbox from 'vue-easy-lightbox';

// Create component
const FilePond = vueFilePond(
    FilePondPluginFileValidateType,
    FilePondPluginImagePreview,
    FilePondFilePoster,
);

setOptions({
    filePosterMinHeight: 44,
    filePosterMaxHeight: 256,
    credits: false,
    labelIdle: 'Перетащите файлы сюда или <span class="filepond--label-action"> Выберите файлы </span>',
    labelInvalidField: 'Поле содержит файлы с ошибкой',
    labelFileWaitingForSize: 'Определение размера',
    labelFileSizeNotAvailable: 'Размер недоступен',
    labelFileLoading: 'Получение',
    labelFileLoadError: 'Ошибка получения',
    labelFileProcessing: 'Загрузка',
    labelFileProcessingComplete: 'Загружен',
    labelFileProcessingAborted: 'Загрузка отменена',
    labelFileProcessingError: 'Ошибка при загрузке',
    labelFileProcessingRevertError: 'Ошибка отмены загрузки',
    labelFileRemoveError: 'Ошибка удаления файла',
    labelTapToCancel: 'нажмите для отменить',
    labelTapToRetry: 'нажмите для повтора',
    labelTapToUndo: 'нажмите для удаления',
    labelButtonRemoveItem: 'Удалить',
    labelButtonAbortItemLoad: 'Прервать',
    labelButtonRetryItemLoad: 'Повторить',
    labelButtonAbortItemProcessing: 'Отменить',
    labelButtonUndoItemProcessing: 'Откатить',
    labelButtonRetryItemProcessing: 'Повторить',
    labelButtonProcessItem: 'Загрузить',
    labelMaxFileSizeExceeded: 'Превышен максимальный размер',
    labelMaxFileSize: 'Максимальный размер: {filesize}',
    labelMaxTotalFileSizeExceeded: 'Превышен общий максимальный размер',
    labelMaxTotalFileSize: 'Общий максимальный размер :{filesize}',
    labelFileTypeNotAllowed: 'Запрещенный тип файла',
    fileValidateTypeLabelExpectedTypes: 'Недопустимый тип файла',
    imageValidateSizeLabelFormatError: 'Недопустимый формат',
    imageValidateSizeLabelImageSizeTooSmall: 'Слишком маленькое изображение',
    imageValidateSizeLabelImageSizeTooBig: 'Слишком большое изображение',
    imageValidateSizeLabelExpectedMinSize: 'Минимальный размер {minWidth} × {minHeight}',
    imageValidateSizeLabelExpectedMaxSize: 'Максимальный размер {maxWidth} × {maxHeight}',
    imageValidateSizeLabelImageResolutionTooLow: 'Слишком маленькое разширение',
    imageValidateSizeLabelImageResolutionTooHigh: 'Слишком большое расширение',
    imageValidateSizeLabelExpectedMinResolution: 'Минимальное расширение {minResolution}',
    imageValidateSizeLabelExpectedMaxResolution: 'Максимальное расширение {maxResolution}1',
});

export default {
    props: ['modelValue'],
    components: {
        FilePond,
        VueEasyLightbox
    },
    data: function () {
        return {
            serverConfig: {
                process: route('tmp.upload'),
                revert: route('tmp.delete'),
                headers: {
                    'X-CSRF-TOKEN': this.$page.props.csrf_token
                }
            },
            index: 0,
            visible: false
        };
    },
    methods: {
        handleRemoveFile(error, file) {
            const meta = file.getMetadata();
            var tmpFiles = this.modelValue;
            let index;
            if ('poster' in meta) {
                index = tmpFiles.findIndex(item => item.source === file.serverId);
            }
            else {
                index = tmpFiles.findIndex(item => item.id === file.id);
            }
            tmpFiles.splice(index, 1);
            this.$emit('update:modelValue', tmpFiles);
        },
        handleProcessFile(error, file) {
            var tmpFiles = this.modelValue;
            tmpFiles.push({
                file: file.file,
                fileExtension: file.fileExtension,
                fileSize: file.fileSize,
                fileType: file.fileType,
                filename: file.filename,
                filenameWithoutExtension: file.filenameWithoutExtension,
                id: file.id,
                origin: file.origin,
                relativePath: file.relativePath,
                serverId: file.serverId,
                source: file.source,
                status: file.status,
                transferId: file.transferId,
            });
            this.$emit('update:modelValue', tmpFiles);
        },
        handleActivateFile(file) {
            const index = this.getArrayIndex(file.filename);
            if (index > -1) {
                this.index = index;
                this.visible = true;
            }
        },
        onHide() {
            this.visible = false;
            this.index = 0;
        },
        getArrayIndex(filename) {
            return this.gallery.findIndex(item => item.title === filename);
        },
        download() {
            console.log(this.gallery[this.index]);
        }
    },
    computed: {
        gallery() {
            let gallery = []
            this.modelValue.forEach(file => {
                if (file?.options?.metadata.poster)
                    gallery.push({
                        src: file.options.metadata.poster,
                        title: file.filename
                    });
            });

            return gallery;
        }
    }
};
</script>

<style>
@import "filepond/dist/filepond.min.css";
@import "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.min.css";
@import "filepond-plugin-file-poster/dist/filepond-plugin-file-poster.css";

.filepond--panel-root {
    background-color: #fff;
}

.vel-toolbar .toolbar-btn.toolbar-btn__download{
    margin-right: 0;
    color: #7ea6f6;
}

@media (min-width: 30em) {
    .filepond--item {
        width: calc(50% - 0.5em);
    }
}

@media (min-width: 50em) {
    .filepond--item {
        width: calc(33.33% - 0.5em);
    }
}
</style>