<template>
    <div class="form-group">
        <label v-if="label" :for="id" class="form-control-label">{{ label }}</label>
        <input :value="modelValue?.formatted_address" @input="updateValue($event.target.value)" class="form-control" type="text" ref="autocompleteInput" placeholder="" :id="id" :class="class" :name="name" />
    </div>
</template>

<script>
import { nanoid } from 'nanoid'
import { Loader } from '@googlemaps/js-api-loader';

export default {
    name: 'AddressAutocomplete',
    props: ['name', 'modelValue', 'label', 'class'],
    data() {
        return {
            autocompleteService: null,
            autocomplete: null,
        };
    },
    mounted() {
        const loader = new Loader({
            apiKey: 'AIzaSyAckEiJcwKyMHThypkVsWNDmg1T4oMxOMY', //TODO: В настройки
            version: 'weekly',
            libraries: ['places'],
            language: 'pl',
        });

        loader.load().then(() => {
            this.initAutocomplete();
        });
    },
    methods: {
        initAutocomplete() {
            // Initialize autocomplete service
            this.autocompleteService = new google.maps.places.AutocompleteService();

            // Initialize autocomplete instance
            this.autocomplete = new google.maps.places.Autocomplete(
                this.$refs.autocompleteInput,
                {
                    types: ['address'],
                    componentRestrictions: { country: 'pl' },
                    fields: ['geometry','address_components', 'formatted_address', 'name']
                }
            );

            // Add event listener for place changed event
            this.autocomplete.addListener('place_changed', this.placeChanged);
        },
        placeChanged() {
            // Get the selected place from the autocomplete object
            const place = this.autocomplete.getPlace();

            // Check if the place is a full address with a house number
            //if (this.isFullAddress(place)) {
                // Emit the selected place
                this.$emit('update:modelValue', place);
            //} else {
                // Clear the input value if the selected place is not a full address
                //this.$refs.autocompleteInput.value = '';
            //}
        },
        isFullAddress(place) {
            // Check if the place has address components including street_number
            return place.address_components.some(component =>
                component.types.includes('street_number')
            );
        },
        updateValue(newValue) {
            // Emit the updated value using v-model
            this.$emit('input', newValue);
        },
    },
    computed: {
        value: {
            get: function () {
                return this.modelValue;
            },
            set: function (newValue) {
                this.$emit('update:modelValue', newValue);
            },
        },
        id: function () {
            return this.name + "-" + nanoid(5);
        }
    },
};
</script>