<template>
    <div class="m-auto" v-if="(onlyActive === true && checked === true) || onlyActive === false">
        <span v-if="iconClass">
            <a href="javascript:void(0);" v-tooltip="label" class="icon-wraper">
                <i class="show-icon" :class="checked === true ? 'active' : '', iconClass" :style=" checked === true ? 'color:'+iconColor : 'color:#000'"></i>
            </a>
        </span>
        <span v-else>
            <div class="icon icon-shape icon-xs rounded-circle shadow text-center" :class="checked === true ? 'bg-gradient-success' : 'bg-gradient-danger'">
                <i class="fas fa-check opacity-10" aria-hidden="true" v-if="checked === true"></i>
                <i class="fas fa-times opacity-10" aria-hidden="true" v-if="checked !== true"></i>
            </div>
        </span>
    </div>
</template>

<script>
export default {
    components: {},
    props: ['label', 'modelValue', 'iconClass', 'iconColor', 'name', 'onlyActive'],
    data() {
        return {};
    },
    methods: {},
    computed: {
        checked() {
            return this.modelValue == true;
        }
    },
    mounted() {},
}
</script>