<template>

    <Head :title="$t('Daily report')" />
    <Listnav :create-url="route('template.create')" :title="$t('Daily report')" />
    <div class="container-fluid py-4">
        <div class="d-flex flex-wrap justify-content-between">
            <div class="first-col">
                <div class="card">
                    <div class="card-header pb-0">
                        <div class="d-flex align-items-center">
                            <p class="mb-0">Введите данные для отчета</p>
                            <button type="submit" :disabled="form.processing" @click="submitForm" class="btn btn-primary btn-sm ms-auto">{{ $t('save') }}</button>
                        </div>
                    </div>
                    <div class="card-body">
                        <Alert :wrapper="''" :errors="errors"/>
                            
                        <table class="table table-bordered align-items-center text-center">
                            <thead>
                                <tr>
                                    <th></th>
                                    <th>ПН</th>
                                    <th>ВТ</th>
                                    <th>СР</th>
                                    <th>ЧТ</th>
                                    <th>ПТ</th>
                                    <th>СБ</th>
                                    <th>ВС</th>
                                    <th>План</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>Обьявлений</td>
                                    <td v-for="i in Array(7).keys()">
                                        <TextInput v-model="form.ads[i]"></TextInput>
                                    </td>
                                    <td>{{ plan?.ads }}</td>
                                </tr>
                                <tr>
                                    <td>Звонков</td>
                                    <td v-for="i in Array(7).keys()">
                                        <TextInput v-model="form.calls[i]"></TextInput>
                                    </td>
                                    <td>{{ plan?.calls }}</td>
                                </tr>
                                <tr>
                                    <td>Переписок</td>
                                    <td v-for="i in Array(7).keys()">
                                        <TextInput v-model="form.messengers[i]"></TextInput>
                                    </td>
                                    <td>{{ plan?.messengers }}</td>
                                </tr>
                                <tr>
                                    
                                </tr>
                            </tbody>
                        </table>


                    </div>
                </div>
            </div>
            <div class="second-col">
                <div class="card">
                    <div class="card-body">
                        <VueDatePicker 
                            class="mx-auto"
                            v-model="week" 
                            model-type="yyyy-MM-dd" 
                            format="dd.MM.yyyy" 
                            locale="ru"
                            :enable-time-picker="false"
                            week-picker
                            inline 
                            auto-apply 
                        />
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import AuthenticatedLayout from "../../Layouts/AuthenticatedLayout.vue";
import { ref } from 'vue';
import { Head, useForm, Link, router } from "@inertiajs/vue3"
import { usePermissions } from '../../Composables/usePermissions';
import Alert from '../../Components/Alert.vue';
import Listnav from "../../Layouts/Navbars/Auth/Listnav.vue";
import { TextInput } from "../../Components/Form";
import VueDatePicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css';
import moment from "moment";

export default {
    layout: AuthenticatedLayout,
    components: {
        Alert, TextInput, Head, Link, Listnav, VueDatePicker
    },
    props: {
        user: Object,
        reported: Object,
        errors: Object,
        plan: Object
    },
    setup(props) {
        const params = route().params;
        let dates;

        if (params.from && params.to) {
            dates = [moment(params.from, 'YYYY-MM-DD'), moment(params.to, 'YYYY-MM-DD')];
        }
        else {
            dates = [moment().startOf('isoWeek'), moment().endOf('isoWeek')];
        }

        const reported = props.reported;
        const form = useForm({
            ads: reported?.ads ?? Array(7),
            calls: reported?.calls ?? Array(7),
            messengers: reported?.messengers ?? Array(7),
            from: dates[0].format('YYYY-MM-DD'),
            to: dates[1].format('YYYY-MM-DD')
        });

        const week = ref(dates);
        const { hasRole, hasPermission } = usePermissions();
        return { form, hasRole, hasPermission , week};
    },
    methods: {
        submitForm() {
            this.form.post(route('daily-report.store'));
        }
    },
    watch: {
        week(value) {
            if (value) {
                router.get('daily-report', {
                    from: moment(value[0]).format('YYYY-MM-DD'),
                    to: moment(value[1]).format('YYYY-MM-DD'),
                });
            }
        }
    }
}

</script>

<style lang="scss" scoped>
    .card .card-body{
        overflow: auto;
    }

    .first-col{
        width: 75%; 
    }

    @media (max-width: 1600px) {
        .first-col{
            width: 65%; 
        }
    }

    @media (max-width: 1280px) {
        .first-col{
            width: 55%; 
        }
    }

    @media (max-width: 800px) {
        .first-col{
            width: 100%; 
            order: 1;
        }
        .second-col{
            width: 100%; 
            order: 0;
            margin-bottom: 20px;
            .dp__main{
                justify-content: center;
                width: fit-content;
            }
        }
    }
</style>