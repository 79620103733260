<template>
    <Head :title="$t('create')"/>
    <div class="container-fluid py-4">
        <div class="row">
            <div class="col-md-12">
                <div class="card shadow-lg min-vh-80">
                    <Alert :errors="errors"/>
                    <form @submit.prevent="form.post(route('citizenship.store'))">
                        <div class="card-header pb-0">
                            <div class="d-flex align-items-center">
                                <p class="mb-0">{{ $t('create') }}</p>
                                <button type="submit" :disabled="form.processing" class="btn btn-primary btn-sm ms-auto">{{ $t('save') }}</button>
                            </div>
                        </div>
                        <div class="card-body">
                            <div class="row">
                                <TextInput v-model="form.name" name="name" :label="$t('Citizenship')" :class="[(form.errors.name ? 'is-invalid' : '')]"/>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { useForm, Head } from '@inertiajs/vue3'
import { usePermissions } from '../../Composables/usePermissions';

import AuthenticatedLayout from '../../Layouts/AuthenticatedLayout.vue';
import Alert from '../../Components/Alert.vue';
import TextInput from "../../Components/Form/TextInput.vue";


export default {
    layout: AuthenticatedLayout,
    components: {
        Alert, TextInput, Head
    },
    props: {
        errors: Object
    },
    setup() {
        const { hasRole, hasPermission } = usePermissions();

        const form = useForm({
            name: '',
        });

        return { form, hasRole, hasPermission };
    }
}
</script>