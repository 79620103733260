<template>
    <div class="ajax-select-wrapper">
        <label class="form-control-label">{{ label }}</label>
        <vue-select
            :label="ajax.attribute"
            :clearable="clearable"
            :clear-search-on-select="true"
            :name="name"
            v-model="selectValue"
            :options = options
            @open="onOpen"
            @search="onSearch"
            @close="onClose"
            @option:selected="onSelect"
            :filterable="false"
            :class="hasError ? 'is-invalid' : ''"
        >   
            <template #open-indicator="{ attributes }">
                <span class="select-indicator"></span>
            </template>
            <template  #list-footer>
                <li v-show="pagination.has_next_page" ref="load" :id="'load-element-'+name" class="loader text-center">
                    {{ loadingLabel }}
                </li>
            </template>
            <template #no-options="{ search, searching, loading }">
                Нет результатов
            </template>
        </vue-select>
    </div>
</template>

<script>
import vSelect from "vue-select";
import _ from 'lodash';
import axios from "axios";

export default {
    components: {
        "vue-select":vSelect
    },
    props: {
        modelValue: null,
        hasError: {
            type: Boolean,
            default: false
        },
        clearable: {
            type: Boolean,
            default: true
        },
        ajax: {
            type: Object,
            required: true
        },
        name: {
            type: String,
            required: true
        },
        ajax_url: {
            type: String,
            default: '/ajax/select2'
        },
        loading_label: {
            type: String,
            default: 'Загрузка...'
        },
        label: {
            type: String,
            default: null
        },
        class: {
            type: String,
            default: null
        }
    },
    data() {
        return {
            observer: null,
            options: [],
            pagination: {}
        }
    },
    methods: {
        async infiniteScroll([{ isIntersecting, target }]) {
            if (isIntersecting) {
                const ul = target.offsetParent;
                const scrollTop = target.offsetParent.scrollTop;
                await this.onScroll(target);
                await this.$nextTick();
                ul.scrollTop = scrollTop; //Не работает
            }
        },

        async onSearch(search, loading) {
            if (search.length) {
                this.observer.disconnect();
                this.clearOptions();
                this.options = [];
                this.pagination = {};
                let url = this.ajaxUrl;
                //loading(true); //Ломает привязку обсервера
                this.fetchOptions(this, url, search, loading);
                this.pagination.search = search;
                await this.$nextTick();
                this.observer.observe(document.querySelector('#load-element-' + this.name));
            }
            else if(!search.length && this.pagination.search.leangh == 1){
                this.onOpen();
            }
        },
        async onOpen() {
            if (this.pagination.search) {
                this.clearOptions();
            }

            if (this.options.length == 0) {
                this.clearOptions();
                let url = this.ajaxUrl;
                this.fetchOptions(this, url);
            }
            await this.$nextTick();
            this.observer.observe(document.querySelector('#load-element-'+this.name));
            
        },
        onSelect() {

        },
        onClose() {
            this.observer.disconnect();
        },
        clearOptions() {
            this.options = [];
            this.pagination = {};
        },
        async onScroll(target) {
            let url = this.pagination.next_page_url;
            if (url) {
                let search = this.pagination.search;
                this.fetchOptions(this, url, search, null, target);
            }

        },
        fetchOptions: _.debounce((vm, url, search=null, loading=null, target=null) => {
            if (url) {
                const ul = target?.offsetParent;
                const scrollTop = target?.offsetParent.scrollTop;
                axios
                    .post(url, {
                        q: search,
                        selected: vm.selectValue,
                        attribute: vm.ajax.title,
                        related_model: vm.ajax.relatedModel,
                        resource: vm.ajax.resource,
                    })
                    .then(response => {
                        vm.options = vm.options.concat(response.data.data);
                        vm.pagination.next_page_url = response.data.next_page_url;
                        vm.pagination.current_page = response.data.current_page;
                        vm.pagination.last_page = response.data.last_page;
                        vm.pagination.has_next_page = (response.data.current_page != response.data.last_page);
                        //if (loading)
                            //loading(false);
                    }).then(function () {
                        if (ul !== undefined)
                            ul.scrollTop = scrollTop;
                    });
            }
        }, 350)
    },
    mounted() {
        this.observer = new IntersectionObserver(this.infiniteScroll)
    },
    computed: {
        selectValue:{
            get: function () {
                if (!_.isEmpty(this.modelValue))
                    return this.modelValue;
                return null;
            },
            set: function (newValue) {
                this.$emit('update:modelValue',newValue);
            }
        },
        ajaxUrl: function(){
            return this.ajax_url ?? '/ajax/select2';
        },
        loadingLabel: function() {
            return this.loading_label ?? 'Загрузка...';
        }
    },
}
</script>