<template>
    <nav v-if="pages.length > 1" class="d-flex justify-items-center justify-content-between pagination-wrap mt-3">
        <div class="d-none flex-sm-fill d-sm-flex align-items-sm-center justify-content-sm-between">
            <div>
                <p class="small text-muted">
                    Показано
                    <span class="fw-semibold">{{ meta.from }}</span>
                    до
                    <span class="fw-semibold">{{ meta.to }}</span>
                    из
                    <span class="fw-semibold">{{ meta.total }}</span>
                    результатов
                </p>
            </div>
            <div>
                <ul class="pagination">
                    <li class="page-item pagination-prev-nav">
                        <Link v-if="links.prev" :href="links.prev" class="page-link" aria-label="Previous" tabindex="false">
                            <span aria-hidden="true">«</span>
                        </Link>
                    </li>
                    <li v-for="(link, index) in pages" class="page-item pagination-page-nav" :class="link.active?'active':''">
                        <Link v-if="!link.active" :href="link.url" class="page-link">{{ link.label }}</Link>
                        <a v-if="link.active" href="#" class="page-link" aria-current="page">{{ link.label }}</a>
                    </li>
                    <li class="page-item pagination-next-nav">
                        <Link v-if="links?.next" :href="links?.next" class="page-link" aria-label="Next" tabindex="false">
                            <span aria-hidden="true">»</span>
                        </Link>
                    </li>
                </ul>
            </div>
        </div>
    </nav>
</template>

<script>
import { Link } from '@inertiajs/vue3';
export default {
    components: {
        Link
    },
    props: {
        links: Object,
        meta: Object
    },
    computed: {
        pages() {
            let links = _.cloneDeep(this.meta.links);
            links.splice(0, 1);
            links.splice(-1);
            return links;
        }
    },
}
</script>