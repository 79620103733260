<template>
    <div class="select-wrapper form-group">
        <label class="form-control-label">{{ label }}</label>
        <vue-select
            :multiple="multiple"
            :clearable="false"
            :options = "options"
            :name="name"
            :reduce="label => label.value"
            :class="class"
            v-model="selectValue"
        >
            <template #open-indicator="{ attributes }">
                <span class="select-indicator"></span>
            </template>
            <template #no-options="{ search, searching, loading }">
                Нет результатов
            </template>
        </vue-select>
    </div>
</template>

<script>
import vSelect from "vue-select";

import 'vue-select/dist/vue-select.css';

export default {
    components: {
        "vue-select":vSelect,
    },
    props: {
        modelValue: null,
        multiple: {
            type: Boolean,
            default: false
        },
        options: {
            type: Object,
            required: true
        },
        name: {
            type: String,
            required: true
        },
        label: {
            type: String,
            default: null
        },
        class: {
            type: String,
            default: null
        }
    },
    computed: {
        selectValue:{
            get: function () {
                let value = this.modelValue;
                if (typeof this.modelValue === 'string'){
                    value = parseInt(value);
                }
                else if(Array.isArray(this.modelValue)){
                    value = value.map((x) => parseInt(x));
                }
                return value;
            },
            set: function (newValue) {
                this.$emit('update:modelValue',newValue);
            }
        }
    },    
}
</script>