<template>
    <div class="mention-wrap shadow-lg">
        <ul class="dropdown-menu">
            <template v-if="items.length">
                <li v-for="(item, index) in items" :key="index">
                    <a class="dropdown-item" :class="{ 'active': index === selectedIndex }" href="#" @click.prevent="selectItem(index)">
                        {{ item.label }}
                    </a>
                </li>
            </template>
            <div class="item" v-else>
                No result
            </div>
        </ul>
    </div>
</template>

<script>
export default {
    props: {
        items: {
            type: Array,
            required: true,
        },

        command: {
            type: Function,
            required: true,
        },
    },

    data() {
        return {
            selectedIndex: 0,
        }
    },

    watch: {
        items() {
            this.selectedIndex = 0
        },
    },

    methods: {
        onKeyDown({ event }) {
            if (event.key === 'ArrowUp') {
                this.upHandler()
                return true
            }

            if (event.key === 'ArrowDown') {
                this.downHandler()
                return true
            }

            if (event.key === 'Enter') {
                this.enterHandler()
                return true
            }

            return false
        },

        upHandler() {
            this.selectedIndex = ((this.selectedIndex + this.items.length) - 1) % this.items.length
        },

        downHandler() {
            this.selectedIndex = (this.selectedIndex + 1) % this.items.length
        },

        enterHandler() {
            this.selectItem(this.selectedIndex)
        },

        selectItem(index) {
            const item = this.items[index]

            if (item) {
                this.command({ id: item?.code })
            }
        },
    },
}
</script>

<style lang="scss">
    .mention-wrap {
        .dropdown-menu{
            background: #fff;
            border: 1px solid #d2d6da;
            border-radius: 0.7rem;
            box-shadow: 0 8px 26px -4px rgba(20, 20, 20, 0.15), 0 8px 9px -5px rgba(20, 20, 20, 0.06) !important;
            display: flex;
            flex-direction: column;
            gap: 0.1rem;
            overflow: auto;
            padding: 0.4rem;
            position: relative;

            .dropdown-item.active{
                color: #344767;
                background-color: #e9ecef;
            }
        }
    }
</style>