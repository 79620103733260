<template>
    <Head :title="$t('edit')"/>
    <div class="container-fluid py-4">
        <div class="row">
            <div class="col-md-12">
                <div class="card shadow-lg min-vh-80">
                    <Alert :errors="errors"/>
                    <form @submit.prevent="form.post(route('company.update', company.id))">
                        <div class="card-header pb-0">
                            <div class="d-flex align-items-center">
                                <p class="mb-0">{{ $t('edit') }}</p>
                                <button type="submit" :disabled="form.processing" class="btn btn-primary btn-sm ms-auto">{{ $t('save') }}</button>
                            </div>
                        </div>
                        <div class="card-body">
                            <div class="row">
                                <div class="col-md-6">
                                    <TextInput v-model="form.name" name="name" :label="$t('v_companies.name')" :class="[(form.errors.name ? 'is-invalid' : '')]"/>
                                </div>
                                <div class="col-md-6">
                                    <FileInput @input="form.logo = $event.target.files[0]" name="logo" :label="$t('v_companies.logo')" :class="[(form.errors.name ? 'is-invalid' : '')]"/>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-md-6">
                                    <TextInput v-model="form.required_workdays" name="required_workdays" :label="$t('v_companies.required_workdays')" :class="[(form.errors.required_workdays ? 'is-invalid' : '')]"/>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-md-6">
                                    <Repeater name="v_contacts" v-model="form.v_contacts" :errors="form.errors" :label="$t('v_companies.contact_person')">
                                        <template v-slot="{ item, repeaterUpdate, index, error }">
                                            <TextInput :modelValue="item['name']" :repeater-index="index" :repeater-update="repeaterUpdate" name="name" :label="$t('name')" :class="[(error?.name ? 'is-invalid' : '')]"/>
                                            <PhoneInput :modelValue="item['phone']" :repeater-index="index" :repeater-update="repeaterUpdate" placeholder="+48 12 345 67 89" name="phone" :label="$t('phone')" class="repeater-input-wrapper" :class="[(error?.phone ? 'is-invalid' : '')]"/>
                                        </template>
                                    </Repeater>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-12">
                                    <Html v-model="form.recruiter_description" :label="$t('v_companies.recruiter_description')" height="300px" name="recruiter_description" :class="[(form.errors.recruiter_description ? 'is-invalid' : '')]"></Html>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { useForm, Head } from '@inertiajs/vue3'
import { usePermissions } from '../../Composables/usePermissions';

import AuthenticatedLayout from '../../Layouts/AuthenticatedLayout.vue';
import Alert from '../../Components/Alert.vue';
import TextInput from "../../Components/Form/TextInput.vue";
import FileInput from "../../Components/Form/FileInput.vue";
import PhoneInput from "../../Components/Form/PhoneInput.vue";
import Repeater from "../../Components/Form/Repeater.vue";
import Html from "../../Components/Form/Html.vue";

import 'vue-tel-input/vue-tel-input.css';


export default {
    layout: AuthenticatedLayout,
    components: {
        Head, Alert, TextInput, FileInput, PhoneInput, Repeater, Html
    },
    props: {
        errors: Object,
        company: Object
    },
    setup(props) {
        const { hasRole, hasPermission } = usePermissions();

        const form = useForm({
            name: props.company.name,
            logo: null,
            required_workdays: props.company.required_workdays,
            v_contacts: props.company.v_contacts ?? [{}],
            recruiter_description: props.company.recruiter_description,
            _method: 'PUT' //При работе с файлами без этого не обновит
        });

        return { form, hasRole, hasPermission };
    },
}
</script>