<template>

    <Head :title="$t('DescriptionTemplate')" />
    <Listnav :create-url="route('template.create')" create-permission="create-DescriptionTemplate"
        :title="$t('DescriptionTemplate')" />
    <div class="container-fluid py-4">
        <div class="row">
            <div class="col-12">
                <div class="card">
                    <div class="card-body">
                        <ul class="template-list list-group">
                            <template v-for="template in templates.data">
                                <li class="list-group-item d-flex">
                                    <div class="me-auto p-2">{{ template.title }}</div>
                                    <div class="list-item-tools p-2">
                                        <Link class="px-2" :href="route('template.edit', template.id)" v-tooltip="$t('edit')"><i class="fas fa-edit text-secondary"></i></Link>
                                        <a class="px-2" href="" @click.prevent="destroy(template.id)" v-tooltip="$t('delete')">
                                            <i class="fas fa-trash text-secondary"></i>
                                        </a>
                                    </div>
                                </li>
                            </template>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { Link, Head } from "@inertiajs/vue3"
import AuthenticatedLayout from "../../Layouts/AuthenticatedLayout.vue";
import Listnav from "../../Layouts/Navbars/Auth/Listnav.vue";
import Pagination from "../../Components/Pagination.vue";
import { usePermissions } from '../../Composables/usePermissions';
import { useToast } from "vue-toastification";

export default {
    layout: AuthenticatedLayout,
    components: {
        Listnav, Link, Head, Pagination
    },
    props: {
        templates: Object
    },
    setup() {
        return usePermissions();
    },
    methods: {
        destroy(id) {
            Swal.fire({
                title: "Подтвердить удаление?",
                text: "Изменения нельзя будет отменить",
                icon: "warning",
                showCancelButton: true,
                confirmButtonText: "Да",
                cancelButtonText: "Отменить",
                confirmButtonColor: "#2dce89",
                cancelButtonColor: "#d33",
            })
                .then((result) => {
                    if (result.isConfirmed) {
                        this.$inertia.delete(route('template.destroy', id));
                    }
                });
        }
    },
    mounted() {
        const toast = useToast();
        if (this.$page.props.flash.success) {
            toast.success(this.$page.props.flash.success, {
                timeout: 2000
            });
        }
    }
}
</script>

<style lang="scss">
.template-list {
    .list-item-tools a:hover i{
        color: #5e72e4 !important;
    }
}
</style>