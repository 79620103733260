<template>
    <div class="form-group" :class="wrapperClass">
        <label class="form-control-label" v-if="label">{{ label }}</label>
        <datepicker 
            class="customized-catendar" 
            @keydown.enter.prevent auto-apply 
            :action-row="{ showSelect: false, showCancel: false, showNow: false, showPreview: false }" 
            :text-input="{ enterSubmit: true, tabSubmit: true, openMenu: true, rangeSeparator: '-' }" 
            :enable-time-picker="false" 
            type="date" 
            :class="class" 
            v-model="date"  
            model-type="yyyy-MM-dd" 
            format="dd.MM.yyyy" 
            locale="ru">
        </datepicker>
    </div>
</template>

<script>
import VueDatePicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css';
import moment from 'moment';

export default {
    components: {
        "datepicker": VueDatePicker
    },
    props: ['label','wrapperClass','class', 'modelValue'],
    data() {
        return [];
    },
    methods: {},
    computed: {
        date: {
            get: function () {
                return this.modelValue;
            },
            set: function (newValue) {
                this.$emit('update:modelValue', newValue);
                this.$emit('input', newValue);
            }
        },
    },
    mounted() {},
}
</script>