<template>
    <nav class="navbar navbar-main navbar-expand-lg px-0 mx-4 shadow-none border-radius-xl" id="navbarBlur" data-scroll="false">
        <div class="container-fluid py-1" :class="showBack ? 'ps-3 pe-0' : 'px-3'">
            <Breadcrumbs/>
            <div class="collapse navbar-collapse mt-sm-0 mt-2 me-md-0 me-sm-4" id="navbar">
                <div class="ms-md-auto pe-md-3 d-flex align-items-center"></div>
                <ul class="navbar-nav  justify-content-end">
                    <li class="nav-item d-flex align-items-center">
                        <Link class="nav-link text-white font-weight-bold px-0" :href="route('profile.edit')">
                            <i class="fa fa-user me-sm-1"></i>
                            <span class="d-sm-inline d-none">{{ user.username }}</span>
                        </Link>
                    </li>
                    <li class="nav-item d-xl-none ps-3 d-flex align-items-center">
                        <a href="javascript:;" @click.prevent="toggleSidenav()" class="nav-link text-white p-0" id="iconNavbarSidenav">
                            <div class="sidenav-toggler-inner">
                                <i class="sidenav-toggler-line bg-white"></i>
                                <i class="sidenav-toggler-line bg-white"></i>
                                <i class="sidenav-toggler-line bg-white"></i>
                            </div>
                        </a>
                    </li>
                    <li class="nav-item dropdown px-3 d-flex align-items-center">
                        <a href="javascript:;" class="nav-link text-white p-0 notify-button" id="dropdownMenuButton"
                            data-bs-toggle="dropdown" aria-expanded="false">
                            <i class="fa fa-bell fa-lg cursor-pointer"></i>
                            <span v-show="notificationsCount > 0" class="badge badge-circle badge-floating">{{ notificationsCount }}</span>
                        </a>
                        <ul class="dropdown-menu  dropdown-menu-end  px-2 py-3 me-sm-n4" aria-labelledby="dropdownMenuButton" v-show="notifications.length > 0">
                            <li class="mb-2" v-for="notification in notifications">
                                <a class="dropdown-item border-radius-md" href="javascript:;">
                                    <Notification
                                        :notification="notification"
                                        @read-notification="readNotification"
                                    />
                                </a>
                            </li>
                        </ul>
                    </li>
                    <li class="nav-item px-3 d-flex align-items-center">
                        <Link :href="route('daily-report')"><i class="fas fa-calendar text-white"></i></Link>
                    </li>
                    <li class="nav-item ps-3 d-flex align-items-center text-end" v-if="showBack">
                        <a href="#" @click.prevent="back()"><i class="fas fa-times text-white"></i></a>
                    </li>
                    <!-- <li class="nav-item px-3 d-flex align-items-center">
                        <form role="form" method="post" :action="route('logout')" id="logout-form">
                            <input type="hidden" name="_token" :value="$page.props.csrf_token">
                            <a :href="route('logout')"
                                title="Выход"
                                @click.prevent="logout"
                                class="nav-link text-white font-weight-bold px-0">
                                <i class="fa fa-sign-out-alt me-sm-1"></i>
                            </a>
                        </form>
                    </li> -->
                </ul>
            </div>
        </div>
    </nav>
</template>

<script >
import { Link, usePage } from "@inertiajs/vue3";
import Breadcrumbs from '../../../Components/Breadcrumbs.vue';
import Notification from '../../../Components/Notification.vue';
import { useToast } from "vue-toastification";
import axios from "axios";

export default {
    components: {
        Breadcrumbs, Link, Notification
    },
    props: {
        auth: Object,
    },
    data: function () {
        return {
            showSidenav: false,
            user: usePage().props.auth.user,
            notifications: []
        }
    },
    methods: {
        logout() {
            document.getElementById('logout-form').submit();
        },
        toggleSidenav() {
            this.$emit('toggle-sidenav');
        },
        back() {
            window.history.back();
        },
        fetchNotifications() {
            axios.get(route('notifications.unread')).then(response => {
                const res = response.data;
                this.notifications = res;
            });
        },
        readNotification(id) {
            axios.patch(route('notifications.mark', id)).then(response => {
                if (response.data) {
                    const index = this.notifications.findIndex((notification) => notification.id == id);
                    if (index > -1) {
                        this.notifications.splice(index, 1);
                    }
                }
            });
        }
    },
    computed: {
        showBack() {
            return this.$page.props.breadcrumbs.length > 2;
        },
        notificationsCount() {
            if (this.notifications)
                return this.notifications.length;
            return 0;
        }
    },
    created() {
        const toast = useToast();
        Echo.private(`App.Models.User.${usePage().props.auth.user.id}`)
            .notification((notification) => {
                toast({
                    component: Notification,
                    props:  {
                        notification: notification
                    },
                }, {
                    timeout: 3000,
                    icon: false,
                });
                this.fetchNotifications();
            });
        
        this.fetchNotifications();
    }
}
</script>

<style lang="scss" scoped>
.notify-button{
    .badge{
        position: relative;
        bottom: -5px;
        left: -5px;
        background-color: #f80031;
        color: #fff;
    }
}
</style>