<template>
    <div class="m-auto" v-if="(man === true && onlyActive) || onlyActive === false">
        <span>
            <a href="javascript:void(0);" v-tooltip="'Мужчина'" class="icon-wraper">
                <i class="show-icon fas fa-mars" :class="man === true ? 'active' : ''" :style=" man === true ? 'color:#0dcaf0' : 'color:#000'"></i>
            </a>
        </span>
    </div>
    <div class="m-auto" v-if="(woman === true && onlyActive) || onlyActive === false">
        <span>
            <a href="javascript:void(0);" v-tooltip="'Женщина'" class="icon-wraper">
                <i class="show-icon fas fa-venus" :class="woman === true ? 'active' : ''" :style=" woman === true ? 'color:#d63384' : 'color:#000'"></i>
            </a>
        </span>
    </div>
    <div class="m-auto" v-if="(both === true && onlyActive) || onlyActive === false">
        <span>
            <a href="javascript:void(0);" v-tooltip="'Мужчина и женщина'" class="icon-wraper">
                <i class="show-icon fas fa-venus-mars" :class="both === true ? 'active' : ''" :style=" both === true ? 'color:#596CFF' : 'color:#000'"></i>
            </a>
        </span>
    </div>
</template>

<script>
export default {
    components: {},
    props: ['modelValue', 'label', 'name', 'onlyActive'],
    data() {
        return {};
    },
    methods: {
        searchVal(array, need_find) {
            if (array && array instanceof Array) {
                for (let index = 0; index < array.length; index++) {
                    const element = array[index];
                    if (element === need_find) {
                        return true;
                    }
                }
            }
            return false;
        }
    },
    computed: {
        man() {
            return this.searchVal(this.modelValue, 1);
        },
        woman() {
            return this.searchVal(this.modelValue, 2);
        },
        both() {
            return this.searchVal(this.modelValue, 3);
        }
    },
    mounted() {},
}
</script>