<template>
    <div class="d-flex py-1" @click.prevent.stop="readNotification">
        <div class="my-auto">
            <img :src="avatar" class="avatar avatar-sm  me-3 ">
        </div>
        <div class="d-flex flex-column justify-content-center">
            <h6 class="text-sm font-weight-normal mb-1">
                Новая вакансия <span class="font-weight-bold">{{ text }}</span>
            </h6>
            <p class="text-xs text-secondary mb-0">
                <i class="fa fa-clock me-1" aria-hidden="true"></i>
                {{ time }}
            </p>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        notification: {
            type: Object,
            required: true
        }
    },
    methods: {
        readNotification() {
            this.$emit('readNotification', this.notification.id);
        }
    },
    computed: {
        avatar() {
            return this.notification.avatar;
        },
        text() {
            return this.notification.title;
        },
        time() {
            return this.notification.time;
        }
    }
}   
</script>