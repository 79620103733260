<template>
    <nav v-if="breadcrumbs" aria-label="breadcrumb">
        <ol class="breadcrumb bg-transparent mb-0 pb-0 pt-1 px-0 me-sm-6 me-5">
            <li v-for="breadcrumb in breadcrumbs" class="breadcrumb-item text-sm text-white"><Link class="opacity-5 text-white" :href="breadcrumb.url">{{ $t(breadcrumb.title) }}</Link></li>
            <li class="breadcrumb-item text-sm text-white active" aria-current="page">{{ $t(current.title) }}</li>
        </ol>
        <!-- <h6 class="font-weight-bolder text-white mb-0">{{ $t(current.title) }}</h6> -->
    </nav>
</template>

<script>
import { Link } from "@inertiajs/vue3";
export default {
    components: {
        Link
    },
    computed: {
        breadcrumbs() {
            const breadcrumbs = this.$page.props.breadcrumbs;
            return breadcrumbs.slice(0,-1);
        },
        current() {
            const breadcrumbs = this.$page.props.breadcrumbs;
            return breadcrumbs[breadcrumbs.length - 1];
        }
    },
}
</script>