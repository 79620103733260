<template>
    <div>
      <template v-if="thumbs.length > 0 && imgs.length > 0">
        <div class="pic-wrapper p-3">
          <div v-for="(src, index) in thumbs" :key="index" class="pic card p-2" @click="showImg(index)">
            <img :src="src" />
          </div>
        </div>

        <vue-easy-lightbox :visible="visible" :imgs="imgs" :index="index" @hide="onHide" :moveDisabled="true"></vue-easy-lightbox>
      </template>
      <template v-else>
        <div class="p-3 text-center">
          <span>{{ $t('No photos') }}</span>
        </div>
      </template>
    </div>  
</template>
  
  <script>
import VueEasyLightbox from 'vue-easy-lightbox';
  
  export default {
    components: {
      VueEasyLightbox
    },
    props: {
      images: {
        type: Object,
        required: true
      }
    },
    data() {
      return {
        visible: false,
        index: 0
      }
    },
    computed: {
      imgs: function () {
          if (this.images) {
            return Object.values(this.images).map(item => item.original_url);
          } else {
            return [];
          }
        },
      thumbs: function () {
          if (this.images) {
            return Object.values(this.images).map(item => item?.options?.metadata?.thumb ?? null);
          } else {
            return [];
          }
        },
    },
    methods: {
      showImg(index) {
        this.index = index
        this.visible = true
      },
      onHide() {
        this.visible = false
      }
    }
  }
  </script>
  
  <style lang="scss" scoped>
  .pic-wrapper {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    .pic {
      width: 200px;
      display: flex;
      margin: 15px;
      align-self: center;
      cursor: pointer;
      transition: all 0.15s ease-in;
      img {
        max-width: 100%;
      }
    }
    .pic:hover {
      box-shadow: 0 7px 14px rgba(50, 50, 93, 0.1), 0 3px 6px rgba(0, 0, 0, 0.08);
      transform: translateY(-1px);
    }
  }
  </style>
  