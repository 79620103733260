<template>
    <Head :title="$t('create')"/>
    <div class="container-fluid py-4">
        <div class="row">
            <div class="col-md-12">
                <div class="card shadow-lg min-vh-80">
                    <Alert :errors="errors"/>
                    <form @submit.prevent="form.post(route('facility.store'))">
                        <div class="card-header pb-0">
                            <div class="d-flex align-items-center">
                                <p class="mb-0">{{ $t('create') }}</p>
                                <button type="submit" :disabled="form.processing" class="btn btn-primary btn-sm ms-auto">{{ $t('save') }}</button>
                            </div>
                        </div>
                        <div class="card-body">
                            <div class="row">
                                <div class="col-md-6">
                                    <TextInput v-model="form.name" name="name" :label="$t('v_facilities.name')" :class="[(form.errors.name ? 'is-invalid' : '')]"/>
                                </div>
                                <!-- <div class="col-md-4">
                                    <TextInput v-model="form.official_name" name="official_name" label="Название официальное" :class="[(form.errors.official_name ? 'is-invalid' : '')]"/>
                                </div> -->
                                <div class="col-md-6">
                                    <TextInput v-model="form.nip" name="nip" :label="$t('v_facilities.nip')" :class="[(form.errors.nip ? 'is-invalid' : '')]"/>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-md-6">
                                    <AddressInput v-model="form.google_address" name="google_address" :label="$t('v_facilities.google_address')" :class="[(form.errors.google_address ? 'is-invalid' : '')]"/>
                                </div>
                                <div class="col-md-6">
                                    <TextInput v-model="form.city" name="city" :label="$t('v_facilities.city')" :class="[(form.errors.city ? 'is-invalid' : '')]"/>
                                </div>
                                <div class="col-md-6">
                                    <TextInput v-model="form.street" name="street" :label="$t('v_facilities.street')" :class="[(form.errors.street ? 'is-invalid' : '')]"/>
                                </div>
                                <div class="col-md-6">
                                    <TextInput v-model="form.postcode" name="postcode" :label="$t('v_facilities.postcode')" :class="[(form.errors.postcode ? 'is-invalid' : '')]"/>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { useForm, Head } from '@inertiajs/vue3'
import { usePermissions } from '../../Composables/usePermissions';

import AuthenticatedLayout from '../../Layouts/AuthenticatedLayout.vue';
import Alert from '../../Components/Alert.vue';
import TextInput from "../../Components/Form/TextInput.vue";
import AddressInput from '../../Components/Form/AddressInput.vue';


export default {
    layout: AuthenticatedLayout,
    components: {
        Alert, Head, TextInput, AddressInput
    },
    props: {
        errors: Object
    },
    setup() {
        const { hasRole, hasPermission } = usePermissions();

        const form = useForm({
            name: null,
            official_name: null,
            nip: null,
            google_address: null,
            city: null,
            street: null,
            postcode: null
        });

        return { form, hasRole, hasPermission };
    },
    methods: {
        parseGoogleAddressComponents(addressComponents) {
            let components = {};
            addressComponents.forEach((addressComponent) => {
                addressComponent.types.forEach((type) => {
                    components[type] = addressComponent.long_name;
                });
            });
        
            return components;
        }
    },
    watch: {
        'form.google_address': function (value) {
            if ('address_components' in value) {
                const address = this.parseGoogleAddressComponents(value['address_components']);
                this.form.city = address?.locality;
                this.form.street = address?.route+' '+address?.street_number;
                this.form.postcode = address?.postal_code;
            }
        }
    }
}
</script>