<template>
    <span v-if="template === 'inline' && ((onlyActive === true && checked === true) || onlyActive === false)">
        <div class="d-flex align-items-center">
            <label class="m-0" :for="name">{{ label }}</label>
            <div class="form-check m-auto">
                <span v-if="iconClass">
                    <a href="javascript:void(0);" :title="label">
                        <i class="show-icon" :class="checked === true ? 'active' : '', iconClass" :style=" checked === true ? 'color:'+iconColor : 'color:#000'"></i>
                    </a>
                </span>
                <span v-else>
                    <div class="icon icon-shape icon-xs rounded-circle shadow text-center" :class="checked === true ? 'bg-gradient-success' : 'bg-gradient-danger'">
                        <i class="fas fa-check opacity-10" aria-hidden="true" v-if="checked === true"></i>
                        <i class="fas fa-times opacity-10" aria-hidden="true" v-if="checked !== true"></i>
                    </div>
                </span>
            </div>
        </div>
    </span>
    <span v-else-if="((onlyActive === true && checked === true) || onlyActive === false)">
        <label :for="name">{{ label }}</label>
        <div>
            <div class="form-check form-switch ms-auto">
                <span v-if="iconClass">
                    <a href="javascript:void(0);" :title="label">
                        <i class="show-icon" :class="checked === true ? 'active' : '', iconClass" :style=" checked === true ? 'color:'+iconColor : 'color:#000'"></i>
                    </a>
                </span>
                <span v-else>
                    <div class="icon icon-shape icon-xs rounded-circle shadow text-center" :class="checked === true ? 'bg-gradient-success' : 'bg-gradient-danger'">
                        <i class="fas fa-check opacity-10" aria-hidden="true" v-if="checked === true"></i>
                        <i class="fas fa-times opacity-10" aria-hidden="true" v-if="checked !== true"></i>
                    </div>
                </span>
            </div>
        </div>
    </span>
</template>

<script>
export default {
    components: {},
    props: ['label', 'modelValue', 'iconClass', 'iconColor', 'template', 'name', 'onlyActive'],
    data() {
        return {};
    },
    methods: {},
    computed: {
        checked() {
            return this.modelValue == true;
        }
    },
    mounted() {},
}
</script>