<template>
    <template  v-if="template === 'inline'">
        <div class="d-flex align-items-center">
            <label class="m-0" :for="name">{{label }}</label>
            <div class="form-check form-switch ms-auto">
                <input class="form-check-input" type="checkbox" :id="name" v-model="checked" :checked="checked">
            </div>
        </div>
    </template>
    <template v-else>
        <label :for="name">{{ label }}</label>
        <div>
            <div class="form-check form-switch ms-auto">
                <input class="form-check-input" type="checkbox" :id="name" v-model="checked" :checked="checked">
            </div>
        </div>
    </template>
</template>

<script>
export default {
    components: {},
    props: ['template', 'modelValue', 'name', 'label'],
    data() {
        return {};
    },
    methods: {},
    computed: {
        checked: {
            get: function () {
                return this.modelValue == true;
            },
            set: function (newValue) {
                this.$emit('update:modelValue', newValue);
            },
        }
    },
    mounted() {},
}
</script>