<template>
    <Head :title="$t('Vacancies')"/>
    <Listnav :title="$t('Vacancies')">
        <template #plugins>
            <div class="d-inline-block me-4 search-wrapper">
                <input class="form-control" type="text"  v-on:input="onSearchChange()" v-model="search_text" :placeholder="$t('search')"/>
                <a href="javascript:void(0);" @click="cleanSearch()" class="clean-search-btn" v-if="search_text"><i class="ni ni-fat-remove"></i></a>
            </div>
            <a href="javascript:;" class="filter-link p-0" @click.prevent="this.showFilter = !this.showFilter">
                <i class="fas fa-filter filter-plugin-button-nav cursor-pointer" aria-hidden="true"></i>
            </a>
        </template>
        <template #buttons>
            <div v-if="hasPermission('create-vacancy')" class="col-auto my-auto">
                <Link :href="route('vacancy.create')" class="btn btn-primary btn-sm ms-auto m-0">{{ $t('create') }}</Link>
            </div>
        </template>
    </Listnav>
    <div class="container-fluid pt-4">
        <div id="list_wrap">
            <div class="row">
                <div class="col-12">
                    <div class="card" id="list">
                        <div class="card-body pb-2">
                            <scroll-arrows :perfect-scrollbar="ps"/>
                            <perfect-scrollbar ref="scroll" id="list_container" class="table-responsive p-0" :options="scrollOptions">
                                <table class="table align-items-center mb-0">
                                    <thead>
                                        <tr>
                                            <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 px-2">
                                                <a href="javascript:;" @click.prevent="onSort('active')">
                                                    {{ $t('vacancies.active') }}
                                                </a>
                                            </th>
                                            <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 px-2">
                                                <a href="javascript:;" @click.prevent="onSort('title')">
                                                    {{ $t('vacancies.name') }}
                                                </a>
                                            </th>
                                            <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 px-2">
                                                <a href="javascript:;" @click.prevent="onSort('updated_at')">
                                                    {{ $t('vacancies.updated_at') }}
                                                </a>
                                            </th>
                                            <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 px-2">
                                                <a href="javascript:;" @click.prevent="onSort('number_of_workplaces')">
                                                    {{ $t('vacancies.number_of_workplaces_short') }}
                                                </a>
                                            </th>
                                            <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 px-2">
                                                <a href="javascript:;" @click.prevent="onSort('base_rate')">
                                                    {{ $t('vacancies.base_rate_short') }}
                                                </a>
                                            </th>
                                            <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 px-2">
                                                <a href="javascript:;" @click.prevent="onSort('student_rate')">
                                                    {{ $t('vacancies.student_rate_short') }}
                                                </a>
                                            </th>
                                            <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 px-2">
                                                <a href="javascript:;" @click.prevent="onSort('young_rate')">
                                                    {{ $t('vacancies.young_rate_short') }}
                                                </a>
                                            </th>
                                            <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 px-2">
                                                <a href="javascript:;" @click.prevent="onSort('max_age')">
                                                    {{ $t('vacancies.max_age_short') }}
                                                </a>
                                            </th>
                                            <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2">
                                                {{ $t('vacancies.work_city') }}
                                            </th>
                                            <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2" v-if="hasPermission('edit-vacancy-company')">
                                                {{ $t('vacancies.company') }}
                                            </th>
                                            <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2">
                                                {{ $t('vacancies.facility') }}
                                            </th>
                                            <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2">
                                                {{ $t('vacancies.contract_type_short') }}
                                            </th>
                                            <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2">
                                                {{ $t('vacancies.housing_type') }}
                                            </th>
                                            <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2">
                                                {{ $t('vacancies.citizenships') }}
                                            </th>
                                            <th class="text-secondary action-buttons"></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr v-for="(vacancy, key) in vacancies.data">
                                            <td class="align-middle text-center">
                                                <template v-if="this.hasRole('Manager') || this.hasRole('Admin')">
                                                    <div class="form-check form-switch justify-content-center">
                                                        <input class="form-check-input" v-on:change="onActiveChange(vacancy.id, $event.target.checked)" type="checkbox" id="" :checked="vacancy.active">
                                                    </div>
                                                </template>
                                                <template v-else>
                                                    <div class="icon icon-shape icon-xs rounded-circle bg-gradient-success shadow text-center" v-if="vacancy.active">
                                                        <i class="fas fa-check opacity-10" aria-hidden="true"></i>
                                                    </div>
                                                </template>
                                            </td>
                                            <td>
                                                <p class="text-sm font-weight-bold mb-0">
                                                    <Link class="vacancy-title" :href="hasPermission('edit-vacancy') === true ? route('vacancy.edit', vacancy.id) : route('vacancy.show', vacancy.id)">
                                                        <div class="title-wrapper">
                                                            {{ vacancy.title }}
                                                        </div>
                                                        <div class="list-icons-wrapper">
                                                            <div class="list-icons">
                                                                <icon :onlyActive="true" :label="$t('vacancies.hot')" name="hot" template="icon" iconColor="#ff7e05" iconClass="fab fa-hotjar" v-model="vacancy.hot"></icon>
                                                                <gendersIcon :onlyActive="true" :label="$t('vacancies.allowed_genders')" v-model="vacancy.allowed_genders"></gendersIcon>
                                                                <icon :onlyActive="true" :label="$t('vacancies.medical_book')" name="medical_book" template="icon" iconColor="#ff0000" iconClass="fas fa-book-medical" v-model="vacancy.medical_book"></icon>
                                                                <icon :onlyActive="true" :label="$t('vacancies.udt')" name="udt" template="icon" iconColor="#ffa500" iconClass="fas fa-dolly" v-model="vacancy.udt"></icon>
                                                                <icon :onlyActive="true" :label="$t('vacancies.experience')" name="experience" template="icon" iconColor="#454545" iconClass="fas fa-user-tie" v-model="vacancy.experience"></icon>
                                                            </div>
                                                        </div>
                                                    </Link>
                                                </p>
                                            </td>
                                            <td>
                                                <p class="text-sm font-weight-bold mb-0 text-center">
                                                    {{ vacancy.updated_at ?? '' }}
                                                </p>
                                            </td>
                                            <td>
                                                <template v-if="this.hasRole('Manager') || this.hasRole('Admin')">
                                                    <TextInput v-model="vacancy.number_of_workplaces" :name="vacancy.id+'_number_of_workplaces'" label="" wrapperClass="mb-0" class="list-input font-weight-bold vacancy-list-edit" @update:modelValue="onWorkplacesCntChange(vacancy.id, $event)"/>
                                                </template>
                                                <template v-else>
                                                    <p class="text-sm font-weight-bold mb-0 text-center">
                                                        {{ vacancy.number_of_workplaces ?? '' }}
                                                    </p>
                                                </template>
                                            </td>
                                            <td>
                                                <p class="text-sm font-weight-bold mb-0 text-center">
                                                    {{ vacancy.base_rate ?? '' }}
                                                </p>
                                            </td>
                                            <td>
                                                <p class="text-sm font-weight-bold mb-0 text-center">
                                                    {{ vacancy.student_rate ?? '' }}
                                                </p>
                                            </td>
                                            <td>
                                                <p class="text-sm font-weight-bold mb-0 text-center">
                                                    {{ vacancy.young_rate ?? '' }}
                                                </p>
                                            </td>
                                            <td>
                                                <p class="text-sm font-weight-bold mb-0 text-center">
                                                    {{ vacancy.max_age ?? '' }}
                                                </p>
                                            </td>
                                            <td>
                                                <p class="text-sm font-weight-bold mb-0">
                                                    {{ vacancy.work_city ?? '' }}
                                                </p>
                                            </td>
                                            <td v-if="hasPermission('edit-vacancy-company')">
                                                <p class="text-sm font-weight-bold mb-0">
                                                    <span>{{ vacancy.company_name }}</span>
                                                </p>
                                            </td>
                                            <td>
                                                <p class="text-sm font-weight-bold mb-0">
                                                    <span>{{ vacancy.facility_name }}</span>
                                                </p>
                                            </td>
                                            <td>
                                                <p class="text-sm font-weight-bold mb-0">
                                                    <span class="badge badge-sm bg-gradient-purple d-inline mx-1" v-tooltip="vacancy.contract_type_name">
                                                        {{ vacancy.contract_type_name_short }}
                                                    </span>
                                                </p>
                                            </td>
                                            <td>
                                                <p class="text-sm font-weight-bold mb-0">
                                                    <span class="badge badge-sm bg-gradient-purple d-inline mx-1" v-for="housing_type in vacancy.housing_type_names">
                                                        {{ housing_type }}
                                                    </span>
                                                </p>
                                            </td>
                                            <td>
                                                <p class="text-sm font-weight-bold mb-0">
                                                    <span class="badge badge-sm bg-gradient-purple d-inline mx-1" v-for="citizenship in vacancy.citizenships_names">
                                                    {{ citizenship }}
                                                    </span>
                                                </p>
                                            </td>
                                            <td class="align-middle">
                                                <Link v-if="hasPermission('show-vacancy')" :href="route('vacancy.show', vacancy.id)" data-bs-toggle="tooltip"
                                                    data-bs-original-title="">
                                                    <i class="far fa-eye text-secondary"></i>
                                                </Link>

                                                <Link v-if="hasPermission('edit-vacancy')" :href="route('vacancy.edit', vacancy.id)" class="mx-3" data-bs-toggle="tooltip"
                                                    data-bs-original-title="">
                                                    <i class="fas fa-user-edit text-secondary"></i>
                                                </Link>

                                                <a v-if="hasPermission('delete-vacancy')" href="javascript:;" @click.prevent="destroy(vacancy.id)"
                                                    data-bs-toggle="tooltip"
                                                    data-bs-original-title="Delete">
                                                    <i class="fas fa-trash text-secondary"></i>
                                                </a>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </perfect-scrollbar>
                            <Pagination :meta="vacancies.meta" :links="vacancies.links"/>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <sidebar-filter :show="showFilter" :fields="filterFields" :preset="this.preset?.filter" @close="showFilter=false" @filter-change="onFilterChange" @filter-clear="onFilterClear"></sidebar-filter>
</template>

<script>
import { Link, Head, router } from "@inertiajs/vue3"
import AuthenticatedLayout from "../../Layouts/AuthenticatedLayout.vue";
import Listnav from "../../Layouts/Navbars/Auth/Listnav.vue";
import Pagination from "../../Components/Pagination.vue";
import SidebarFilter from "../../Components/SidebarFilter.vue";
import { usePermissions } from '../../Composables/usePermissions';
import { reactive } from "vue";
import { PerfectScrollbar } from 'vue3-perfect-scrollbar';
import ScrollArrows from '../../Components/ScrollArrows.vue';
import { useToast } from "vue-toastification";
import Icon from "../../Components/FormShow/Icon.vue";
import GendersIcon from "../../Components/FormShow/GendersIcon.vue";
import Checkbox from "../../Components/Form/Checkbox.vue";
import { TextInput } from "../../Components/Form";
import _ from 'lodash';

export default {
    layout: AuthenticatedLayout,
    components: {
        PerfectScrollbar, ScrollArrows, Listnav, Link, Head, Pagination, SidebarFilter, Icon, GendersIcon, Checkbox, TextInput
    },
    props: {
        vacancies: Object,
        filter_options: Object,
        preset: {
            type: Object,
            default: {}
        }
    },
    data() {
        return {
            queries: this.preset,
            showFilter: false,
            filterFields: {
                number_of_workplaces: {
                    label: 'Количество мест',
                    type: 'range',
                    format: null,
                    value: [           
                        (this.preset?.filter?.number_of_workplaces ?? [])[0] ?? this.filter_options.number_of_workplaces_min, 
                        (this.preset?.filter?.number_of_workplaces ?? [])[1] ?? this.filter_options.number_of_workplaces_max
                    ],
                    min: this.filter_options.number_of_workplaces_min,
                    max: this.filter_options.number_of_workplaces_max,
                    step: 1,
                },
                base_rate: {
                    label: 'Ставка стандартная',
                    type: 'range',
                    format: 'decimals',
                    value: [           
                        (this.preset?.filter?.base_rate ?? [])[0] ?? this.filter_options.base_rate_min, 
                        (this.preset?.filter?.base_rate ?? [])[1] ?? this.filter_options.base_rate_max
                    ],
                    min: this.filter_options.base_rate_min,
                    max: this.filter_options.base_rate_max,
                    step: -1,
                },
                student_rate: {
                    label: 'Ставка студенческая',
                    type: 'range',
                    format: 'decimals',
                    value: [           
                        (this.preset?.filter?.student_rate ?? [])[0] ?? this.filter_options.student_rate_min, 
                        (this.preset?.filter?.student_rate ?? [])[1] ?? this.filter_options.student_rate_max
                    ],
                    min: this.filter_options.student_rate_min,
                    max: this.filter_options.student_rate_max,
                    step: -1,
                },
                young_rate: {
                    label: 'Ставка до 26',
                    type: 'range',
                    format: 'decimals',
                    value: [           
                        (this.preset?.filter?.young_rate ?? [])[0] ?? this.filter_options.young_rate_min, 
                        (this.preset?.filter?.young_rate ?? [])[1] ?? this.filter_options.young_rate_max
                    ],
                    min: this.filter_options.young_rate_min,
                    max: this.filter_options.young_rate_max,
                    step: -1,
                },
                max_age: {
                    label: 'Максимальный возраст',
                    type: 'range',
                    format: null,
                    value: [           
                        (this.preset?.filter?.max_age ?? [])[0] ?? this.filter_options.max_age_min, 
                        (this.preset?.filter?.max_age ?? [])[1] ?? this.filter_options.max_age_max
                    ],
                    min: this.filter_options.max_age_min,
                    max: this.filter_options.max_age_max,
                    step: 1,
                },
                allowed_genders: {
                    label: 'Пол',
                    type: 'simple-select',
                    multiple: true,
                    options: [
                        {
                            label: 'мужчина',
                            value: 1
                        },
                        {
                            label: 'женщина',
                            value: 2
                        },
                        {
                            label: 'пара',
                            value: 3
                        },
                    ],
                },
                contract_type: {
                    label: 'Вид трудового договора',
                    type: 'simple-select',
                    multiple: true,
                    options: [
                        {
                            label: 'Umowa zlecenia',
                            value: 1
                        },
                        {
                            label: 'Umowa o pracę',
                            value: 2
                        }
                    ]
                },
                closest_city_id: {
                    label: 'Ближайший крупный город',
                    type: 'select-ajax',
                    ajax: {
                        relatedModel: 'App\\Models\\City',
                        resource: 'App\\Http\\Resources\\CityResource',
                        attribute: 'name'
                    }
                },
                closest_city_km: {
                    label: 'До крупного города (км)',
                    type: 'input',
                },
                housing_type: {
                    label: 'Тип жилья',
                    type: 'simple-select',
                    //multiple: true,
                    options: [
                        {
                            label: 'Свое',
                            value: 1
                        },
                        {
                            label: 'Предоставляемое',
                            value: 2
                        }
                    ]
                },
                work_city_id: {
                    label: 'Город выполнения работ',
                    type: 'select-ajax',
                    ajax: {
                        relatedModel: 'App\\Models\\City',
                        resource: 'App\\Http\\Resources\\CityResource',
                        attribute: 'name'
                    }
                },
                citizenships: {
                    label: 'Гражданство',
                    type: 'select-ajax-multiple',
                    ajax: {
                        relatedModel: 'App\\Models\\Citizenship',
                        resource: 'App\\Http\\Resources\\CitizenshipResource',
                        attribute: 'name'
                    }
                }
            },
            scrollOptions: {
                //suppressScrollX: true,
                handlers: ['drag-thumb', 'keyboard', 'touch', 'wheel']
            },
            ps: null,
            search_text: this.preset['search'] ?? null
        };
    },
    setup() {
        const { hasRole, hasPermission } = usePermissions();
        const toast = useToast();
        return { toast, hasRole, hasPermission };
    },
    created() {
        this.searchStart = _.debounce((vm, text) => {
            if (text && text.length >= 3) {
                vm.queries['search'] = text;
            } else if(vm.queries['search']){
                Reflect.deleteProperty(vm.queries, 'search');
            }
        }, 1200);

        this.workplacesCntChange = _.debounce((vm, id, value) => {
            axios.post(route('set-field-value', id), {
                field_name: 'number_of_workplaces',
                value: value
            })
            .then(response => {
                    router.reload();
                this.toast.success(response.data.success, { timeout: 2000 });
            })
            .catch(error => {
                this.toast.error('Ошибка', { timeout: 2000 });
            });
        }, 500);
    },
    methods: {
        destroy(id) {
            Swal.fire({
                title: "Подтвердить удаление?",
                text: "Изменения нельзя будет отменить",
                icon: "warning",
                showCancelButton: true,
                confirmButtonText: "Да",
                cancelButtonText: "Отменить",
                confirmButtonColor: "#2dce89",
                cancelButtonColor: "#d33",
            })
            .then((result) => {
                if (result.isConfirmed) {
                    this.$inertia.delete(route('vacancy.destroy', id));
                }
            });
        },
        onWorkplacesCntChange(id, value) {
            this.workplacesCntChange(this, id, value);
        },
        onActiveChange(id, value) {
            axios.post(route('set-field-value', id), {
                field_name: 'active',
                value: value
            })
            .then(response => {
                router.reload();
                this.toast.success(response.data.success, { timeout: 2000 });
            })
            .catch(error => {
                this.toast.error('Ошибка', { timeout: 2000 });
            });
        },
        cleanSearch() {
            this.search_text = null;
            this.onSearchChange();
        },
        onSearchChange() {
            this.searchStart(this, this.search_text);
        },
        onSort(column)
        {
            if (this.queries.sort == column)
                column = '-'+column;
            this.queries.sort = column;
        },
        onFilterChange(filter) {
            Object.keys(this.queries).forEach((key) => {
                if (key.includes('filter'))
                    delete this.queries[key];
            });
            this.queries = { ...this.queries, ...filter };
        },
        onFilterClear() {
            this.onFilterChange({});
        },
        setHeight() {
            const correction = 110;
            //const correction = 134;
            let offset = document.getElementById("list_wrap").offsetTop;
            let height = window.innerHeight - offset - correction;
            document.getElementById('list_container').style.height = height + "px"
        },
    },
    mounted() {
        window.onload = this.setHeight;
        window.onresize = this.setHeight;
        this.setHeight();
        this.ps = this.$refs.scroll.$el;

        const toast = useToast();
        if (this.$page.props.flash.success) {
            toast.success(this.$page.props.flash.success, {
                timeout: 2000
            });
        }
    },
    watch: {
        queries: {
            handler(newValue) {
                let queryRoute = route(
                    "vacancy.index",
                    newValue
                );
                this.$inertia.get(queryRoute, {}, { preserveState: true, replace: true });
            },
            deep: true
        }
    },
}
</script>

<style lang="scss">
.table-responsive{
    max-height: 100vh;
    position: relative;
    th, thead{
        position: sticky;
        top: 0;
        background-color: #fff;
    }

    .ps__rail-x {
        margin-right: 10px;
    }

    .ps__rail-y {
        margin-top: 42px;
        margin-bottom: 10px;
    }
}

.pagination-wrap{
    margin-top: 10px;
    .pagination{
        margin-bottom: 10px;
    }
}

.vacancy-title{
    display: flex;
    align-items: center;
}

.list-icons-wrapper{
    display: inline-block;
}

.list-icons{
    display: flex;
    align-self: center;
}

.list-icons a, .title-wrapper{
    margin-right: 10px;
}

.list-icons .form-check {
    padding-left: 0;
}

.list-icons a .show-icon{
    font-size: 1.2rem;
}

.vacancy-list-edit{
    padding: 0.25rem 0.25rem;
    width: 40px;
}

thead{
    z-index: 999;
}
</style>