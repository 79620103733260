<template>
    <Head :title="$t('Facility')"/>
    <Listnav :create-url="route('facility.create')" create-permission="create-vacancy-facility" :title="$t('Facility')" />
    <div class="container-fluid py-4" id="list">
        <div class="row">
            <div class="col-12">
                <div class="card min-vh-80 mb-4">
                    <div class="card-body pb-2">
                        <p v-if="!facilities" class="text-sm text-center">{{ $t('No records') }}</p>

                        <div class="table-responsive p-0">
                            <table class="table align-items-center mb-0">
                                <thead>
                                    <tr>
                                        <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">
                                            {{ $t('v_facilities.name') }}
                                        </th>
                                        <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2">
                                            {{ $t('v_facilities.nip') }}
                                        </th>
                                        <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2">
                                            {{ $t('v_facilities.google_address') }}
                                        </th>
                                        <th class="text-secondary action-buttons"></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr v-for="facility in facilities.data">
                                        <td>
                                            <p class="text-sm font-weight-bold mb-0">
                                                <Link v-if="hasPermission('edit-vacancy-facility')" :href="route('facility.edit', facility.id)">{{ facility.name }}</Link>
                                                <span v-if="!hasPermission('edit-vacancy-facility')">{{ facility.name }}</span>
                                            </p>
                                        </td>
                                        <td>
                                            <p class="text-sm font-weight-bold mb-0">
                                                <span>{{ facility.nip ?? '' }}</span>
                                            </p>
                                        </td>
                                        <td>
                                            <p class="text-sm font-weight-bold mb-0">
                                                <span>{{ facility.city ?? '' }} {{ facility.street ?? '' }}</span>
                                            </p>
                                        </td>
                                        <td class="align-middle">
                                            <Link v-if="hasPermission('edit-vacancy-facility')" :href="route('facility.edit', facility.id)" class="mx-3" data-bs-toggle="tooltip"
                                                data-bs-original-title="">
                                                <i class="fas fa-user-edit text-secondary"></i>
                                            </Link>

                                            <a v-if="hasPermission('delete-vacancy-facility')" href="" @click.prevent="destroy(facility.id)"
                                                data-bs-toggle="tooltip"
                                                data-bs-original-title="Delete">
                                                <i class="fas fa-trash text-secondary"></i>
                                            </a>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <Pagination :meta="facilities.meta" :links="facilities.links"/>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { Link, Head } from "@inertiajs/vue3"
import AuthenticatedLayout from "../../Layouts/AuthenticatedLayout.vue";
import Listnav from "../../Layouts/Navbars/Auth/Listnav.vue";
import Pagination from "../../Components/Pagination.vue";
import { usePermissions } from '../../Composables/usePermissions';
import { useToast } from "vue-toastification";

export default {
    layout: AuthenticatedLayout,
    components: {
        Listnav, Link, Head, Pagination
    },
    props: {
        facilities: Object
    },
    setup() {
        return usePermissions();
    },
    methods: {
        destroy(id) {
            Swal.fire({
                title: "Подтвердить удаление?",
                text: "Изменения нельзя будет отменить",
                icon: "warning",
                showCancelButton: true,
                confirmButtonText: "Да",
                cancelButtonText: "Отменить",
                confirmButtonColor: "#2dce89",
                cancelButtonColor: "#d33",
            })
            .then((result) => {
                if (result.isConfirmed) {
                    this.$inertia.delete(route('facility.destroy', id));
                }
            });
        }
    },
    mounted() {
        const toast = useToast();
        if (this.$page.props.flash.success) {
            toast.success(this.$page.props.flash.success, {
                timeout: 2000
            });
        }
    }
}
</script>