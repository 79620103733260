<template>
    <div class="min-height-300 bg-primary position-absolute w-100"></div>
    <Sidenav :show="showSidenav"/>
    <main class="main-content border-radius-lg">
        <Topnav @toggleSidenav="toggleSidenav"/>
        <slot />
    </main>
</template>

<script>
import Sidenav from './Navbars/Auth/Sidenav.vue';
import Topnav from './Navbars/Auth/Topnav.vue';

export default {
    components: {
        Topnav,
        Sidenav
    },
    data: function () {
        return {
            showSidenav: false,
        }
    },
    methods: {
        toggleSidenav() {
            this.showSidenav = !this.showSidenav;
        }
    }
}
</script>