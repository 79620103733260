<template>
    <div v-show="show" class="row">
        <div v-if="param" class="col-md-12">
            <pre>{{ JSON.stringify(param, null, '\t') }}</pre>
        </div>
        <div v-if="!param" v-for="item in params" class="col-md-6">
            <pre>{{ JSON.stringify(item, null, '\t') }}</pre>
        </div>
    </div>
</template>

<script>
export default {
    props: ['show', 'params', 'param']
}
</script>