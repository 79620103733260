<template>
    <Head :title="$t('edit')"/>
    <Listnav :title="user?.username">
        <template #buttons>
            <div class="col-lg-4 col-md-6 my-sm-auto ms-sm-auto me-sm-0 mx-auto mt-3 text-end">
                <button type="submit" :disabled="form.processing" class="btn btn-primary btn-sm ms-auto my-0 me-2" @click.prevent="submitForm()">{{ $t('edit') }}</button>
                <button class="btn btn-danger btn-sm ms-auto my-0" @click.prevent="logout()">{{ $t('logout') }}</button>
            </div>
        </template>
    </Listnav>
    <div class="container-fluid py-4">
        <Usernav :user="form" :prefix="routePrefix"/>
        <div class="row py-4">
            <div class="col-md-8">
                <div class="card">
                    <form>
                        <div class="card-body">
                            <Alert :wrapper="''" :errors="errors"/>
                            <p class="text-uppercase text-sm">{{ $t('user.block_main') }}</p>
                            <div class="row">
                                <div class="col-md-6">
                                    <div class="form-group">
                                        <TextInput v-model="form.username" name="username" :label="$t('user.username')" :class="[(form.errors.username ? 'is-invalid' : '')]"/>
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <div class="form-group">
                                        <TextInput type="email" v-model="form.email" name="email" :label="$t('user.email')" :class="[(form.errors.email ? 'is-invalid' : '')]"/>
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <div class="form-group">
                                        <TextInput v-model="form.firstname" name="firstname" :label="$t('user.firstname')" :class="[(form.errors.firstname ? 'is-invalid' : '')]"/>
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <div class="form-group">
                                        <TextInput v-model="form.lastname" name="lastname" :label="$t('user.lastname')" :class="[(form.errors.lastname ? 'is-invalid' : '')]"/>
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <div class="form-group repeater-wrapper">
                                        <PhoneInput :label="$t('user.phone')" v-model="form.phone" placeholder="+48 12 345 67 89" name="phone" class="repeater-input-wrapper" :class="[(form.errors.phone ? 'is-invalid' : '')]"/>
                                    </div>
                                </div>
                            </div>
                            <hr class="horizontal dark">
                            <p class="text-uppercase text-sm">{{ $t('user.block_password') }}</p>
                            <div class="row">
                                <div class="col-md-6">
                                    <div class="form-group">
                                        <TextInput type="password" v-model="form.password" name="password" :label="$t('user.password')" :class="[(form.errors.password ? 'is-invalid' : '')]"/>
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <div class="form-group">
                                        <TextInput type="password" v-model="form.password_confirmation" name="password_confirmation" :label="$t('user.password_confirmation')" :class="[(form.errors.password_confirmation ? 'is-invalid' : '')]" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
            <div class="col-md-4">
                <div class="card card-profile avatar-profile h-100">
                    <div class="row justify-content-center h-100" v-if="!avatarEditMode">
                        <div class="col-6 col-lg-6 order-lg-2 h-100">
                            <div class="mb-4 mb-lg-0 text-center vetical-align-avatar h-100">
                                <a @click="triggerFileSelection();" href="javascript:;" class="avatar-link rounded-circle img-fluid border border-2 border-white">
                                    <img :src="form.avatar ?? '/img/team-2.jpg'" class="rounded-circle img-fluid border border-2 border-white">
                                    <div class="avatar-hover"><i class="far fa-edit"></i></div>
                                </a>
                                <!-- <label class="text-center d-block mt-4">Аватар</label> -->
                            </div>
                        </div>
                    </div>
                    <div class="card-body pt-0 pt-4 pb-4" v-show="uploadedImage && avatarEditMode">
                        <div class="row">
                            <div class="col">
                                <div class="d-flex justify-content-center d-none">
                                    <FileInput ref="fileInput" @input="onFileChange($event)" name="avatar" :label="$t('user.avatar')" :class="[(form.errors.avatar ? 'is-invalid' : '')]"/>
                                </div>
                                <div class="row justify-content-center">
                                    <div class="col-8">
                                        <cropper
                                            class="cropper"
                                            ref="cropper"
                                            :src="uploadedImage"
                                            :stencil-component="$options.components.CropperStencil"
                                            :stencil-props="{
                                                aspectRatio: 1/1,
                                            }"
                                        ></cropper>
                                        <div class="d-flex justify-content-between editor-buttons">
                                            <button class="btn btn-success btn-sm btn-sm" @click="crop">{{ $t('save') }}</button>
                                            <button class="btn btn-secondary btn-sm btn-sm" @click="croppedImage = null; form.avatar = avatar_old; uploadedImage = null; avatarEditMode = false;">
                                                {{ $t('cancel_button') }}
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import AuthenticatedLayout from "../../Layouts/AuthenticatedLayout.vue";
import { Head, useForm, Link, router } from "@inertiajs/vue3"
import { usePermissions } from '../../Composables/usePermissions';
import Alert from '../../Components/Alert.vue';
import { Cropper, CircleStencil } from 'vue-advanced-cropper';
import 'vue-advanced-cropper/dist/style.css';
import { resizeDataUrlImage } from '../../Composables/imageResize';
import CropperStencil from '../../Components/CropperStencil.vue';
import Usernav from "../../Layouts/Navbars/Auth/Usernav.vue";
import Listnav from "../../Layouts/Navbars/Auth/Listnav.vue";

import { TextInput, FileInput, PhoneInput} from "../../Components/Form"

export default {
    layout: AuthenticatedLayout,
    components: {
        Alert, TextInput, Head, FileInput, Cropper, CircleStencil, CropperStencil, Link, Usernav, Listnav, PhoneInput
    },
    props: {
        user: Object,
        errors: Object,
        avatar_old: String,
    },
    setup(props) {
        const { hasRole, hasPermission } = usePermissions();

        const form = useForm({
            id: props.user.id,
            username: props.user.username,
            firstname: props.user.firstname,
            lastname: props.user.lastname,
            email: props.user.email,
            phone: props.user.phone,
            avatar:  props.user.avatar,
            password: '',
            password_confirmation: '',
            _method: 'PUT' //При работе с файлами без этого не обновит
        });

        const avatar_old = props.user.avatar;

        return { form, hasRole, hasPermission, avatar_old };
    },
    data() {
        return {
            avatarEditMode: false,
            croppedImage: null,
            uploadedImage: null,
        };
    },
    methods: {
        submitForm() {
            this.form.post(this.formRoute);
        },
        async crop() {
			const { coordinates, canvas } = this.$refs.cropper.getResult();
			this.coordinates = coordinates;

			if (canvas) {
				this.croppedImage = canvas.toDataURL(); 
                this.form.avatar = await resizeDataUrlImage({
					dataUrl: canvas.toDataURL(),
					width: 250,
					height: 250,
                });
                this.avatarEditMode = false;
			};
		},
        onFileChange(event) {
            const input = event.target;
            this.uploadedImage = null;
            if (input.files && input.files[0]) {
				const reader = new FileReader();
				reader.onload = (e) => { this.uploadedImage = e.target.result; this.avatarEditMode = true;};
                reader.readAsDataURL(input.files[0]);
			}
        },
        triggerFileSelection()
        {
            this.$refs.fileInput.$el.children.avatar.click();
        },
        logout() {
            router.post('/logout');
        }
    },
    computed: {
        formRoute(){
            if (this.routePrefix == 'profile'){
                return route('profile.update');
            }
            else if (this.hasPermission('edit-user')){
                return route('user.update',this.user.id);
            }
        },
        routePrefix() {
            const current = route().current();
            return current.substring(0, current.indexOf('.'));
        }
    },
}

</script>

<style scoped>
.cropper {
    height: 300px;
    border-radius: 1rem;
    overflow: hidden;
}

.vetical-align-avatar{
    display: flex;
    align-items: center;
    justify-content: center;
}
</style>