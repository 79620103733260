<template>
    <div :class="wrapper">
        <label v-if="label" :for="name">{{ label }}</label>
        <div class="editor" :class="hasError ? 'is-invalid' : ''">
            <div class="menubar">
                <span class="menu-group">
                    <span v-for="actionName in activeButtons" :key="actionName">
                        <button type="button" v-if="actionName === 'bold'" class="menubar-button"
                            :class="{ 'is-active': editor?.isActive('bold') }"
                            @click="editor.chain().focus().toggleBold().run()">
                            <i class="fas fa-bold"></i>
                        </button>
                        <button type="button" v-if="actionName === 'italic'" class="menubar-button"
                            :class="{ 'is-active': editor?.isActive('italic') }"
                            @click="editor.chain().focus().toggleItalic().run()">
                            <i class="fas fa-italic"></i>
                        </button>

                        <button type="button" v-if="actionName === 'strike'" class="menubar-button"
                            :class="{ 'is-active': editor?.isActive('strike') }"
                            @click="editor.chain().focus().toggleStrike().run()">
                            <i class="fas fa-strikethrough"></i>
                        </button>

                        <button type="button" v-if="actionName === 'underline'" class="menubar-button"
                            :class="{ 'is-active': editor?.isActive('underline') }"
                            @click="editor.chain().focus().toggleUnderline().run()">
                            <i class="fas fa-underline"></i>
                        </button>

                        <button type="button" v-if="actionName === 'code'" class="menubar-button"
                            :class="{ 'is-active': editor?.isActive('code') }"
                            @click="editor.chain().focus().toggleCode().run()">
                            <i class="fas fa-code"></i>
                        </button>

                        <button type="button" v-if="actionName === 'h1'" class="menubar-button"
                            :class="{ 'is-active': editor?.isActive('heading', { level: 1 }) }"
                            @click="editor.chain().focus().toggleHeading({ level: 1 }).run()">
                            H1
                        </button>

                        <button type="button" v-if="actionName === 'h2'" class="menubar-button"
                            :class="{ 'is-active': editor?.isActive('heading', { level: 2 }) }"
                            @click="editor.chain().focus().toggleHeading({ level: 2 }).run()">
                            H2
                        </button>

                        <button type="button" v-if="actionName === 'h3'" class="menubar-button"
                            :class="{ 'is-active': editor?.isActive('heading', { level: 3 }) }"
                            @click="editor.chain().focus().toggleHeading({ level: 3 }).run()">
                            H3
                        </button>

                        <button type="button" v-if="actionName === 'bulletList'" class="menubar-button"
                            :class="{ 'is-active': editor?.isActive('bulletList') }"
                            @click="editor.chain().focus().toggleBulletList().run()">
                            <i class="fas fa-list-ul"></i>
                        </button>

                        <button type="button" v-if="actionName === 'orderedList'" class="menubar-button"
                            :class="{ 'is-active': editor?.isActive('orderedList') }"
                            @click="editor.chain().focus().toggleOrderedList().run()">
                            <i class="fas fa-list-ol"></i>
                        </button>

                        <button type="button" v-if="actionName === 'blockquote'" class="menubar-button"
                            :class="{ 'is-active': editor?.isActive('blockquote') }"
                            @click="editor.chain().focus().toggleBlockquote().run()">
                            <i class="fas fa-quote-right"></i>
                        </button>

                        <button type="button" v-if="actionName === 'codeBlock'" class="menubar-button"
                            :class="{ 'is-active': editor?.isActive('codeBlock') }"
                            @click="editor.chain().focus().toggleCodeBlock().run()">
                            <i class="fas fa-code"></i>
                        </button>

                        <button type="button" v-if="actionName === 'undo'" class="menubar-button"
                            @click="editor.chain().focus().undo().run()">
                            <i class="fas fa-undo"></i>
                        </button>

                        <button type="button" v-if="actionName === 'redo'" class="menubar-button"
                            @click="editor.chain().focus().redo().run()">
                            <i class="fas fa-redo"></i>
                        </button>

                        <button type="button" v-if="actionName === 'copy'" v-tooltip="'Копировать'"  class="menubar-button"
                            :class="{ 'is-active': editor?.isActive('copy') }"
                            @click="copyAllToClipboard()">
                            <i class="far fa-copy"></i>
                        </button>
                    </span>
                </span>
            </div>
            <editor-content class="editor-content" :editor="editor" :style="(height)?'height: '+height+';':''"/>
        </div>
    </div>
</template>

<script>
import StarterKit from '@tiptap/starter-kit'
import { Editor, EditorContent } from '@tiptap/vue-3'
import Underline from '@tiptap/extension-underline'
import Link from '@tiptap/extension-link'
import Youtube from '@tiptap/extension-youtube'
import Mention from '@tiptap/extension-mention'

import suggestion from './suggestion.js'

export default {
    components: {
        EditorContent,
    },
    props: {
        hasError: {
            type: Boolean,
            default: false
        },
        modelValue: {
            type: String,
            default: '',
        },
        name: {
            type: String,
            required: true
        },
        height: {
            type: String,
            default: '',
        },
        label: {
            type: String,
            default: '',
        },
        wrapper: {
            type: String,
            default: null
        },
        activeButtons: {
            type: Array,
            validator: function (list) {
                for (let el of list) {
                    if (
                        [
                            'bold',
                            'italic',
                            'strike',
                            'underline',
                            'code',
                            'h1',
                            'h2',
                            'h3',
                            'bulletList',
                            'orderedList',
                            'blockquote',
                            'codeBlock',
                            'undo',
                            'redo',
                            'copy'
                        ].indexOf(el) === -1
                    ) {
                        return -1;
                    }
                }
                return 1;
            },
            default: () => ['bold','italic','strike','underline','bulletList','orderedList','blockquote', 'copy'],
        },
    },
    emits: ['update:modelValue'],
    data() {
        return {
            editor: null,
        }
    },
    watch: {
        modelValue(value) {
            // HTML
            const isSame = this.editor.getHTML() === value
            // JSON
            // const isSame = JSON.stringify(this.editor.getJSON()) === JSON.stringify(value)

            if (isSame) {
                return
            }

            this.editor.commands.setContent(value, false)
        },
    },
    methods: {
        copyAllToClipboard() {
            navigator.clipboard.writeText(this.editor.getText());
        }
    },
    mounted() {
        this.editor = new Editor({
            extensions: [
                StarterKit,
                Underline,
                Link,
                Youtube,
                Mention.configure({
                    HTMLAttributes: {
                        class: 'mention',
                    },
                    suggestion,
                }),
            ],
            content: this.modelValue,
            onUpdate: () => {
                // HTML
                const value = this.editor.isEmpty ? '' : this.editor.getHTML()
                this.$emit('update:modelValue', value)

                // JSON
                // this.$emit('update:modelValue', this.editor.getJSON())
            },
        })
    },
    beforeUnmount() {
        this.editor.destroy()
    },
}
</script>

<style lang="scss">
    .is-invalid{
        .editor{
            border-color: #fd5c70;
        }
    }
    .editor{
        border: 1px solid #ccc;
        border-radius: 0.5rem;
        .menubar{
            border-bottom: 1px solid #ccc;
            padding: 8px;

            .menu-group{
                display: inline-block;
                margin-right: 15px;
                vertical-align: middle;
            }
            .menubar-button{
                background: none;
                border: none;
                cursor: pointer;
                float: left;
                height: 24px;
                padding: 3px 5px;
                width: 28px;
                line-height: 1em;
                font-size: 0.9rem;

                &:hover, &.is-active{
                    color: #06c;
                }
            }
        }

        .editor-content{
            .tiptap{
                box-sizing: border-box;
                line-height: 1.42;
                height: 100%;
                outline: none;
                overflow-y: auto;
                padding: 12px 15px;
                tab-size: 4;
                -moz-tab-size: 4;
                text-align: left;
                white-space: pre-wrap;
                word-wrap: break-word;

                :first-child {
                    margin-top: 0;
                }

                .mention {
                    background-color: var(--purple-light);
                    border-radius: 0.4rem;
                    box-decoration-break: clone;
                    color: var(--purple);
                    padding: 0.1rem 0.3rem;
                }
            }

            .ProseMirror {
                height: 100%; // To make the content clickable along the height
            }
        }
    }
</style>