<template>

<Head :title="'Рекрутация'" />
    <div class="recruiter-statistics">
        <div class="card shadow-lg mx-4">
            <div class="card-body p-3">
                <div class="row gx-4">
                    <div class="col-auto">
                        <div class="avatar avatar-xl position-relative">
                            <img :src="user.avatar ?? '/img/team-2.jpg'" alt="profile_image"
                                class="w-100 border-radius-lg shadow-sm">
                        </div>
                    </div>
                    <div class="col-auto my-auto">
                        <div class="h-100">
                            <h5 class="mb-1">
                                {{ user?.username }}
                            </h5>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="container-fluid py-4">
            <Usernav :user="user" :prefix="routePrefix"/>
            <div class="row py-4">
                <div class="row mb-3">
                    <div class="col-xl-3 col-sm-6 mb-xl-0 mb-4">
                        <div class="card">
                            <div class="card-body p-3">
                                <div class="row">
                                    <div class="col-8">
                                        <div class="numbers">
                                            <p class="text-sm mb-0 text-uppercase font-weight-bold">{{
                                                $t('user.recruited') }}</p>
                                            <h5 class="font-weight-bolder mb-0">
                                                {{ counters.recruited }}
                                                <span v-if="counters.recruited_diff != 0" class="text-sm font-weight-bolder" :class="counters.recruited_diff < 0 ? 'text-danger' : 'text-success'">
                                                    <span v-if="counters.recruited_diff > 0">+</span>{{ counters.recruited_diff }}
                                                </span>
                                            </h5>
                                        </div>
                                    </div>
                                    <div class="col-4 text-end">
                                        <div
                                            class="icon icon-shape bg-gradient-dark shadow text-center border-radius-md">
                                            <i class="ni ni-user-run text-lg opacity-10" aria-hidden="true"></i>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-xl-3 col-sm-6 mb-xl-0 mb-4">
                        <div class="card">
                            <div class="card-body p-3">
                                <div class="row">
                                    <div class="col-8">
                                        <div class="numbers">
                                            <p class="text-sm mb-0 text-uppercase font-weight-bold">{{
                                                $t('user.worked') }}</p>
                                            <h5 class="font-weight-bolder mb-0">
                                                {{ counters.worked }}
                                                <span v-if="counters.worked_diff != 0" class="text-sm font-weight-bolder" :class="counters.worked_diff < 0 ? 'text-danger' : 'text-success'">
                                                    <span v-if="counters.worked_diff > 0">+</span>{{ counters.worked_diff }}
                                                </span>
                                            </h5>
                                        </div>
                                    </div>
                                    <div class="col-4 text-end">
                                        <div
                                            class="icon icon-shape bg-gradient-dark shadow text-center border-radius-md">
                                            <i class="ni ni-paper-diploma text-lg opacity-10"
                                                aria-hidden="true"></i>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-xl-3 col-sm-6 mb-xl-0 mb-4">
                        <div class="card">
                            <div class="card-body p-3">
                                <div class="row">
                                    <div class="col-8">
                                        <div class="numbers" v-if="!showCalcInfo">
                                            <p class="text-sm mb-0 text-uppercase font-weight-bold">
                                                {{ $t('user.summ') }}
                                            </p>
                                            
                                            <h5 v-if="!showCalcInfo" class="font-weight-bolder mb-0">
                                                {{ counters.award }}
                                                <span v-if="counters.award_diff != 0" class="text-sm font-weight-bolder" :class="counters.award_diff < 0 ? 'text-danger' : 'text-success'">
                                                    <span v-if="counters.award_diff > 0">+</span>{{ counters.award_diff }}
                                                </span>
                                            </h5>
                                        </div>
                                        <div class="information" v-if="showCalcInfo">
                                            <p class="mb-0 text-sm text-dark">
                                                <span v-tooltip="'Мотивация рассчитывается только за подтвержденных отработавших'" class="text-decoration-underline">Подтверждена</span> отработка <strong>{{ counters.worked }}</strong> человек. 
                                                <span v-if="padawans.length">Так же <strong>{{ counters.padawan_worked }}</strong> отработало у подопечных.</span>
                                            </p>
                                            <div class="formula">
                                                {{ counters.worked }}<i>×</i>{{ counters.price }}<i>zl</i>
                                                <span v-show="padawans.length"> <i>+</i> {{ counters.padawan_worked }}<i>×</i>{{counters.price_mentor}}<i>zl</i></span> <i>=</i> {{counters.award}}<i>zl</i>
                                            </div>
                                            <div class="mb-0" v-if="counters.award_diff">
                                                <i class="text-xs">
                                                    Это на <span class="font-weight-bolder" :class="counters.award_diff < 0 ? 'text-danger' : 'text-success'">
                                                    {{ Math.abs(counters.award_diff) }}</span>zl {{ counters.award_diff > 0 ? 'больше' : 'меньше' }} чем в прошлом месяце
                                                </i>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-4 text-end">
                                        <a href="javascript:;" @click.prevent="showCalcInfo = !showCalcInfo" @mouseover="hoverIcon = true" @mouseout="hoverIcon = false" class="icon icon-shape bg-gradient-dark shadow text-center border-radius-md">
                                            <i v-show="!showInfoIcon" class="ni ni-money-coins text-lg opacity-10" aria-hidden="true"></i>
                                            <i v-show="showInfoIcon" class="fas fa-info-circle text-lg opacity-10" aria-hidden="true"></i>
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-xl-3 col-sm-6 mb-xl-0 mb-4">
                        <div class="card">
                            <div class="card-body p-3">
                                <div class="row">
                                    <div class="col-9"></div>
                                    <div class="col-3 text-end">
                                        <div class="dropleft ms-auto position-relative m-1" >
                                            <button class="btn bg-gradient-dark dropdown-toggle m-0" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                                                {{ months[currentPeriod] }}
                                            </button>
                                            <ul class="dropdown-menu" aria-labelledby="dropdownImport">
                                                <li v-if="currentPeriod !== 'prev'">
                                                    <a href="javascript:;"  class="dropdown-item" @click.prevent="switchFilter('prev')">{{ months.prev }}</a>
                                                </li>
                                                <li v-if="currentPeriod !== 'current'">
                                                    <a href="javascript:;" class="dropdown-item" @click.prevent="switchFilter('current')">{{ months.current }}</a>
                                                </li>
                                                <li v-if="currentPeriod !== 'next'">
                                                    <a href="javascript:;"  class="dropdown-item" @click.prevent="switchFilter('next')">{{ months.next }}</a>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-lg-7 col-md-12">
                        <div class="card">
                            <div class="card-header pb-0 p-3">
                                <h6 class="mb-0">{{ $t('user.leads') }}</h6>
                                <div class="d-flex align-items-center">
                                    <span class="badge badge-md badge-dot me-4">
                                        <i class="bg-success"></i>
                                        <span class="text-dark text-xs">{{ $t('user.recruited') }}</span>
                                    </span>
                                    <span class="badge badge-md badge-dot me-4">
                                        <i class="bg-primary"></i>
                                        <span class="text-dark text-xs">{{ $t('user.worked') }}</span>
                                    </span>
                                    <span class="badge badge-md badge-dot me-4">
                                        <i class="bg-danger"></i>
                                        <span class="text-dark text-xs">{{ $t('user.denied') }}</span>
                                    </span>
                                </div>
                            </div>
                            <div class="card-body p-3">
                                <div class="chart" v-if="analitic.leads_chart && leads_chart_options">
                                    <div class="card">
                                        <div class="card-body p-3">
                                            <LineChart :data="analitic.leads_chart" :options="leads_chart_options"
                                                class="chart-canvas" height="300" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-5 vacancies-block">
                        <div class="card h-100">
                            <div class="card-header pb-0 p-3">
                                <div class="d-flex align-items-center">
                                    <h6 class="mb-0">{{ $t('user.vacancies') }}</h6>
                                </div>
                            </div>
                            <div class="card-body p-3">
                                <div class="row">
                                    <div class="col-lg-5 col-12 text-center">
                                        <div class="chart mt-5" v-if="analitic.vacancies_chart && vacancies_chart_options">
                                            <DoughnutChart :data="analitic.vacancies_chart"
                                                :options="vacancies_chart_options" class="chart-canvas"
                                                height="200" />
                                        </div>
                                    </div>
                                    <div class="col-lg-7 col-12">
                                        <div class="table-responsive">
                                            <table class="table vacancies-list align-items-center mb-0">
                                                <tbody>
                                                    <tr v-for="(count, vacancy) in analitic.vacancies">
                                                        <td>
                                                            <div class="px-2 py-1">
                                                                <h6 class="mb-0 text-sm">{{ vacancy }}</h6>
                                                            </div>
                                                        </td>
                                                        <td class="align-middle text-center text-sm">
                                                            <span class="text-xs font-weight-bold"> {{ count }} </span>
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row mt-4">
                    <div class="col-12">
                        <div class="card">
                            <div class="card-body d-flex p-2">
                                <!-- <div class="col-lg-1 col-md-2 col-sm-3 col-4 text-center">
                                    <a @click.prevent="togglePadawan(user.id)" :class="true ? 'border-primary' : ''" href="javascript:;" class="avatar avatar-sm rounded-circle border">
                                        <img :alt="user.full_name" class="p-1" :src="user.avatar">
                                    </a>
                                    <p class="mb-0 text-xs">{{ user.firstname }} {{ user.lastname }}</p>
                                </div> -->
                                <div class="col-lg-1 col-md-2 col-sm-3 col-4 text-center" v-for="padawan in padawanFilter">
                                    <a @click.prevent="togglePadawan(padawan.id)" :class="padawan.active ? 'border-primary' : ''" href="javascript:;" class="avatar avatar-sm rounded-circle border">
                                        <img :alt="padawan.full_name" class="p-1" :src="padawan.avatar">
                                    </a>
                                    <p class="mb-0 text-xs">{{ padawan.full_name }}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row leads-table-block mt-2">
                    <div class="col-12">
                        <div class="card mb-4">
                            <!-- <div class="card-header pb-0 p-3">
                                <h6>{{ $t('user.my_leads') }}</h6>
                            </div> -->
                            <div class="card-body px-0 pt-2 pb-2">
                                <div class="table-responsive p-0">
                                    <table class="table align-items-center mb-0">
                                        <thead>
                                            <tr>
                                                <th
                                                    class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">
                                                    {{ $t('user.name') }}</th>
                                                    <th
                                                    class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2">
                                                    {{ $t('user.recruited_by') }}</th>
                                                <th
                                                    class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2">
                                                    {{ $t('user.start_work_date') }}</th>
                                                <th
                                                    class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">
                                                    {{ $t('user.vacancy') }}</th>
                                                <th
                                                    class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">
                                                    {{ $t('user.status') }}</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr v-for="lead in leads">
                                                <td>
                                                    <div class="d-flex px-3 py-1">
                                                        <div class="icon-wrapper">
                                                            <i v-if="lead.sex == 1" class="fas fa-mars"></i>
                                                            <i v-if="lead.sex == 2" class="fas fa-venus"></i>
                                                            <i v-if="lead.sex == 3" class="fas fa-venus-mars"></i>
                                                        </div>
                                                        <div class="d-flex flex-column justify-content-center">
                                                            <Link :href="route('lead.edit', lead.id)" class="h6 mb-0 text-sm">{{ lead.full_name }}</Link>
                                                            <p class="text-sm text-secondary mb-0">{{ $t('user.days_from') }} {{ lead.statistics.show_worked_days }}/{{ lead.statistics.required_workdays }}</p>
                                                        </div>
                                                    </div>
                                                </td>
                                                <td>
                                                    <div class="d-inline-block text-center">
                                                        <a href="javascript:;" class="avatar avatar-xs rounded-circle disable-drag me-1" v-tooltip="lead.responsible?.firstname+' '+lead.responsible?.lastname">
                                                            <img :alt="lead.responsible?.lastname" :src="lead.responsible?.avatar">
                                                        </a>
                                                        <p class="text-sm font-weight-bold mb-0">{{ lead.statistics.recruited_date }}</p>
                                                    </div>
                                                </td>
                                                <td>
                                                    <p class="text-sm font-weight-bold mb-0">{{ lead.first_workday }}</p>
                                                </td>
                                                <td class="align-middle text-center text-sm">
                                                    <Link :href="route('vacancy.show', lead?.vacancy?.id)" class="text-sm font-weight-bold mb-0">{{ lead?.vacancy.title }}</Link>
                                                </td>
                                                <td class="align-middle text-center">
                                                    <p class="text-sm font-weight-bold mb-0">
                                                        <span v-if="lead.statistics.work_status == 'fired'" class="text-danger">{{ $t('user.fired') }}</span>
                                                        <span v-if="lead.statistics.work_status == 'worked' || lead.statistics.work_status == 'confirmed'" class="text-success">
                                                            {{ $t('user.worked_in_table') }}
                                                            <div v-if="lead.statistics.work_status == 'confirmed'" class="icon icon-shape icon-xs rounded-circle bg-gradient-success shadow text-center">
                                                                <i class="fas fa-check opacity-10" style="top: -1px;" aria-hidden="true"></i>
                                                            </div>
                                                        </span>
                                                        <span v-if="lead.statistics.work_status == 'working'" class="text-primary">{{ $t('user.working') }}</span>
                                                    </p>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import AuthenticatedLayout from "../../Layouts/AuthenticatedLayout.vue";
import { Head, Link } from "@inertiajs/vue3";
import LineChart from '../../Components/Charts/LineChart.vue';
import DoughnutChart from '../../Components/Charts/DoughnutChart.vue';
import Usernav from "../../Layouts/Navbars/Auth/Usernav.vue";
import { router } from '@inertiajs/vue3';
import { controllers } from "chart.js";

export default {
    layout: AuthenticatedLayout,
    components: {
        Head, LineChart, DoughnutChart, Link, Usernav
    },
    props: {
        months: Object,
        user: Object,
        analitic: Object,
        leads: Object,
        counters: Object,
        padawans: Object,
        filter: Object,
        //period: String
    },
    data() {
        return {
            leads_chart_options: {
                "responsive": true,
                "maintainAspectRatio": false,
                "plugins": {
                    "legend": {
                        "display": false
                    }
                },
            },
            vacancies_chart_options: {
                "responsive": true,
                "maintainAspectRatio": false,
                "plugins": {
                    "legend": {
                        "display": false
                    }
                },
            },
            padawanFilter: this.preparePadawanFilter(),
            showCalcInfo: false,
            hoverIcon: false
        };
    },
    methods: {
        link(params={}) {
            if (this.routePrefix == 'user')
                params.user = this.user.id;
            return route(this.routePrefix + '.recruiting', params);
        },
        $queryParams(...args) {
            let queryString = this.$page.url;
            if (queryString.indexOf("?") === -1) {
                return {};
            }
            queryString = queryString.substring(queryString.indexOf("?") + 1);
            return Object.assign(Object.fromEntries(new URLSearchParams(queryString)), ...args);
        },
        preparePadawanFilter() {
            let user = _.cloneDeep(this.user);
            let responsibles = this.filter?.responsible_id;

            if (this.padawans) {
                let padawans = this.padawans;

                if (responsibles) {
                    responsibles = responsibles.map((id) => parseInt(id, 10));
                    padawans = padawans.map((padawan) => {
                        if (responsibles.indexOf(padawan.id) > -1) {
                            padawan.active = true;
                        }
                        return padawan;
                    });

                    user.active = responsibles.indexOf(user.id) > -1;
                }
                else {
                    user.active = true;
                }

                //console.log(responsibles);
                //console.log([...[user], ...padawans]);
                return [...[user], ...padawans];
            }
            return [user];
        },
        togglePadawan(id) {
            const index = this.padawanFilter.findIndex((padawan) => padawan.id == id);
            this.padawanFilter[index].active = !this.padawanFilter[index].active;
            this.switchFilter();
        },
        switchFilter(period = null) {
            let filter = this.filterQuery;
            if (!period)
                period = this.currentPeriod;
            if (period)
                filter.period = period;
            router.get(this.link(), this.filterQuery, {
                preserveScroll: true,
                replace: true
            });
        }
    },
    computed: {
        currentPeriod() {
            if(route().params?.period)
                return route().params?.period;
            return 'current';
        },
        routePrefix() {
            const current = route().current();
            return current.substring(0, current.indexOf('.'));
        },
        filterQuery() {
            let ids = [];
            this.padawanFilter.forEach(padawan => {
                if (padawan.active)
                    ids.push(padawan.id);
            });
            return { filter: { responsible_id: ids.join(',') } };
        },
        showInfoIcon() {
            if (this.showCalcInfo)
                return !this.hoverIcon;
            return this.hoverIcon;
        }
    },
}
</script>

<style lang="scss" scoped>
.formula {
    display: inline-block;
    padding-left: 1rem;
    padding-right: 1rem;
    background-color: rgba(86, 61, 124, 0.15);
    border: 1px solid rgba(86, 61, 124, 0.15);
    font-size: 0.85rem;
    color: #344767;
    font-weight: 600;
    margin-top: 5px;
    margin-bottom: 0;

    i{
        color: #8392ab;
        font-style: normal;
    }
}
</style>