<template>
    <div class="edit-plugin full-width" :class="show ? 'show' : ''">
       <div class="card edit-plugin-card shadow-lg pb-4">
           <div class="min-height-300 bg-primary z-index-n1 position-absolute w-100"></div>
           <div class="container-fluid">
                <div class="row">
                    <div class="col-12">
                        <a class="btn btn-link p-0 edit-plugin-close-button text-white" @click.prevent="show = false">
                            <i class="fas fa-times"></i>
                        </a>
                    </div>
                </div>
            </div>
           <perfect-scrollbar>
            <Listnav :title="$t('Vacancies')">
                <template #title>
                    <span  class="form-control vacancy-title-input h5 px-2">{{ vacancy.title }}</span>
                </template>
            </Listnav>
            <div class="vacancy-edit py-4">
                <div class="container-fluid">
                    <div class="row">
                        <div class="col-md-12">
                            <div class="card pb-4 shadow-none bg-transparent">
                                <div class="row mt-2">
                                    <div class="col">
                                        <div class="nav-wrapper position-relative end-0 mb-3">
                                            <ul class="nav nav-pills nav-fill p-1 tabs">
                                                <li class="nav-item" :class="(tab===1)?'active':''">
                                                    <a href="javascript:void(0);" class="nav-link mb-0 px-0 py-1" @click.prevent="tab=1" :class="(tab===1)?'active':''">
                                                        {{ $t('vacancies.parameters') }}
                                                    </a>
                                                </li>
                                                <li class="nav-item" :class="(tab===2)?'active':''">
                                                    <a href="javascript:void(0);" class="nav-link mb-0 px-0 py-1" @click.prevent="tab=2" :class="(tab===2)?'active':''">
                                                        {{ $t('vacancies.description') }}
                                                    </a>
                                                </li>
                                                <li class="nav-item" :class="(tab===3)?'active':''">
                                                    <a href="javascript:void(0);" class="nav-link mb-0 px-0 py-1" @click.prevent="tab=3" :class="(tab==3)?'active':''">
                                                        {{ $t('vacancies.photo') }}
                                                    </a>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                                <div class="tab1" v-show="tab===1">
                                    <div class="row mt-2">
                                        <div class="col-lg-4">
                                            <div class="card">
                                                <div class="card-body">
                                                    <div class="row">
                                                        <div class="col-12 col-sm-4">
                                                            <checkbox :label="$t('vacancies.active')" :onlyActive="false" name="active" template="inline" v-model="vacancy.active"></checkbox>
                                                        </div>
                                                        <div class="col-12 col-sm-4">
                                                            <checkbox :label="$t('vacancies.featured')" :onlyActive="false" name="featured" template="inline" v-model="vacancy.featured"></checkbox>
                                                        </div>
                                                        <div class="col-12 col-sm-4">
                                                            <checkbox :label="$t('vacancies.hot')" :onlyActive="false" name="hot" template="inline" iconColor="#ff7e05" iconClass="fab fa-hotjar" v-model="vacancy.hot"></checkbox>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="card mt-4">
                                                <div class="card-body">
                                                    <div class="card-header p-0">
                                                        <h5 class="font-weight-bolder">{{ $t('vacancies.requirements') }}</h5>
                                                        <div class="header-icons">
                                                            <icon :onlyActive="false" :label="$t('vacancies.medical_book')" name="medical_book" template="icon" iconColor="#ff0000" iconClass="fas fa-book-medical" v-model="vacancy.medical_book"></icon>
                                                            <icon :onlyActive="false" :label="$t('vacancies.udt')" name="udt" template="icon" iconColor="#ffa500" iconClass="fas fa-dolly" v-model="vacancy.udt"></icon>
                                                            <icon :onlyActive="false" :label="$t('vacancies.experience')" name="experience" template="icon" iconColor="#454545" iconClass="fas fa-user-tie" v-model="vacancy.experience"></icon>
                                                            <gendersIcon :onlyActive="false" :label="$t('vacancies.allowed_genders')" v-model="vacancy.allowed_genders"></gendersIcon>
                                                        </div>
                                                    </div>
                                                    <div class="row mt-4">
                                                        <div class="col-12 col-sm-6">
                                                            <label>{{ $t('vacancies.age') }}</label>
                                                            <div class="row">
                                                                <div class="col-6">
                                                                    <Text v-model="vacancy.min_age" name="min_age" label=""/>
                                                                </div>
                                                                <div class="col-6">
                                                                    <Text v-model="vacancy.max_age" name="max_age" label=""/>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="col-12 col-sm-6">
                                                            <Text v-model="vacancy.number_of_workplaces" name="number_of_workplaces" :label="$t('vacancies.number_of_workplaces')"/>
                                                        </div>
                                                    </div>
                                                    <div class="row mt-4">
                                                        <div class="col-12 col-sm-12">
                                                            <Text v-model="vacancy.position" name="position" :label="$t('vacancies.position')"/>
                                                        </div>
                                                    </div>
                                                    <div class="row mt-4">
                                                        <div class="col-12 col-sm-12">
                                                            <Text  v-model="vacancy.citizenships" name="citizenships" :label="$t('Citizenship')"/>
                                                        </div>
                                                    </div>
                                                    <div class="row mt-4">
                                                        <div class="col-12">
                                                            <Text v-model="vacancy.special_requirements" name="special_requirements" :label="$t('vacancies.special_requirements')" />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-lg-8 mt-lg-0 mt-4">
                                            <div class="row">
                                                <div class="col-sm-4">
                                                    <div class="card">
                                                        <div class="card-body">
                                                            <h5 class="font-weight-bolder">{{ $t('vacancies.workplace') }}</h5>
                                                            <Text  v-model="vacancy.facility" name="facility" :label="$t('Facility')"/>
                                                            <Text  v-model="vacancy.company" name="company" :label="$t('Company')"/>
                                                            <Text  v-model="vacancy.work_city" name="work_city" :label="$t('vacancies.work_city')"/>
                                                            <Text  v-model="vacancy.documents_city" name="documents_city" :label="$t('vacancies.documents_city')"/>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-sm-4">
                                                    <div class="card">
                                                        <div class="card-body">
                                                            <h5 class="font-weight-bolder">{{ $t('vacancies.salary') }}</h5>
                                                            <Text v-model="vacancy.base_rate" name="base_rate" :label="$t('vacancies.base_rate')" />
                                                            <Text v-model="vacancy.young_rate" name="young_rate" :label="$t('vacancies.young_rate')" />
                                                            <Text v-model="vacancy.student_rate" name="student_rate" :label="$t('vacancies.student_rate')"/>
                                                            <Text v-model="vacancy.avg_salary" name="avg_salary" :label="$t('vacancies.avg_salary')" />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-sm-4">
                                                    <div class="card">
                                                        <div class="card-body">
                                                            <h5 class="font-weight-bolder">{{ $t('vacancies.housing') }}</h5>
                                                            <checkbox :onlyActive="false" :label="$t('vacancies.housing_compensation')" name="housing_compensation" template="inline" v-model="vacancy.housing_compensation"></checkbox>
                                                            <Text  v-model="vacancy.housing_type" name="housing_type" :label="$t('vacancies.housing_type')"/>
                                                            <Text v-model="vacancy.housing_cost" name="housing_cost" :label="$t('vacancies.housing_cost')"/>
                                                            <Text v-model="vacancy.student_housing_cost" name="student_housing_cost" :label="$t('vacancies.student_housing_cost')" />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-sm-6 mt-4">
                                                    <div class="card">
                                                        <div class="card-body">
                                                            <h5 class="font-weight-bolder">{{ $t('vacancies.conditions') }}</h5>
                                                            <div class="row">
                                                                <div class="col-8">
                                                                    <Text v-model="vacancy.closest_city" name="closest_city" :label="$t('vacancies.closest_city')"/>
                                                                </div>
                                                                <div class="col-4">
                                                                    <Text v-model="vacancy.closest_city_km" name="closest_city_km" :label="$t('vacancies.closest_city_km')"/>
                                                                </div>
                                                            </div>
                                                            <Text  v-model="vacancy.contract_type" name="contract_type" :label="$t('vacancies.contract_type')"/>
                                                            <Text v-model="vacancy.conditions" name="conditions" :label="$t('vacancies.conditions_description')"/>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-sm-6 mt-4">
                                                    <div class="card">
                                                        <div class="card-body">
                                                            <h5 class="font-weight-bolder">{{ $t('vacancies.work_hours') }}</h5>
                                                            <checkbox :onlyActive="false" :label="$t('vacancies.night_shifts')" name="night_shifts" template="inline" v-model="vacancy.night_shifts"></checkbox>
                                                            <div class="row mt-4">
                                                                <div class="col-12">
                                                                    <Text v-model="vacancy.work_schedule_text" name="work_schedule_text" label=""/>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="tab2" v-show="tab===2">
                                    <div class="row">
                                        <div class="col-sm-12 mt-4">
                                            <div class="card">
                                                <div class="card-body">
                                                    <h5 class="font-weight-bolder">{{ $t('vacancies.vacancy_description') }}</h5>
                                                    <Text v-model="vacancy.description" name="description" label="" />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="tab3" v-show="tab===3">
                                    <div class="row">
                                        <div class="col-sm-12 mt-4">
                                            <div class="card">
                                                <Images :images="vacancy.files ?? []"/>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
           </perfect-scrollbar>
        </div>
    </div>
</template>

<script>
import { Link } from '@inertiajs/vue3';
import { PerfectScrollbar } from 'vue3-perfect-scrollbar';
import { usePermissions } from '../../Composables/usePermissions';
import Listnav from "../../Layouts/Navbars/Auth/Listnav.vue";
import Checkbox from "../../Components/FormShow/Checkbox.vue";
import Icon from "../../Components/FormShow/Icon.vue";
import GendersIcon from "../../Components/FormShow/GendersIcon.vue";
import Text from "../../Components/FormShow/Text.vue";
import Images from "../../Components/FormShow/Images.vue";

export default {
    components: {
        Listnav, 
        Link, 
        Checkbox, 
        Icon, 
        GendersIcon, 
        Text,
        PerfectScrollbar,
        Images
    },
    setup(props) {
        return usePermissions();
    },
    data() {
        return {
            tab: 1,
            show: false,
            vacancy: {}
        }
    },
    methods: {
        goBack() {
            window.history.back();
        },
        open(vacancy) {
            console.log(vacancy);
            this.vacancy = vacancy;
            this.show = true;
        }
    }
}
</script>

<style lang="scss" scoped>
    .card-header{
        display: flex;
        justify-content: space-between;
        .header-icons{
            display: flex;
            align-self: center;
        }
    }

    .edit-plugin-close-button{
        float: right;
        margin-bottom: 0.5rem;
        margin-top: 0.5rem;
        font-size: 1.1rem;
    }

    .tabs{
        .nav-item{
            margin-left: 5px;
        }
        .nav-item:not(.active):hover{
            cursor: pointer;
            //font-weight: 600;
            box-shadow: 0 1px 5px 1px #ddd;
            //animation: .2s ease;
            background-color: #fff;
            border-radius: 0.5rem;
        }
        .nav-item.active {
            cursor: default;
            .nav-link, .nav-link:hover{
                font-weight: 600;
                box-shadow: 0 1px 5px 1px #ddd;
                color: #344767;
            }
        }
    }
</style>