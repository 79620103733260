<template>
    <Head :title="$t('show')"/>
    <Listnav :title="vacancy.title">
        <template #buttons>
            <div class="col-auto my-auto">
                <button class="btn btn-primary btn-sm ms-auto m-0" @click="goBack">{{ $t('Go back') }}</button>
            </div>
        </template>
    </Listnav>
    <div class="vacancy-edit py-4">
        <div class="container-fluid">
            <div class="row">
                <div class="col-md-12">
                    <div class="card pb-4 shadow-none bg-transparent">
                        <div class="row mt-2">
                            <div class="col">
                                <div class="nav-wrapper position-relative end-0 mb-3">
                                    <ul class="nav nav-pills nav-fill p-1 tabs">
                                        <li class="nav-item" :class="(tab===1)?'active':''">
                                            <a href="javascript:void(0);" class="nav-link mb-0 px-0 py-1" @click.prevent="tab=1" :class="(tab===1)?'active':''">
                                                {{ $t('vacancies.parameters') }}
                                            </a>
                                        </li>
                                        <li class="nav-item" :class="(tab===2)?'active':''">
                                            <a href="javascript:void(0);" class="nav-link mb-0 px-0 py-1" @click.prevent="tab=2" :class="(tab===2)?'active':''">
                                                {{ $t('vacancies.description') }}
                                            </a>
                                        </li>
                                        <li class="nav-item" :class="(tab===3)?'active':''">
                                            <a href="javascript:void(0);" class="nav-link mb-0 px-0 py-1" @click.prevent="tab=3" :class="(tab==3)?'active':''">
                                                    {{ $t('vacancies.photo') }}
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div class="tab1" v-show="tab===1">
                            <div class="row mt-2">
                                <div class="col-lg-4">
                                    <div class="card">
                                        <div class="card-body">
                                            <div class="row">
                                                <div class="col-12 col-xxl-4">
                                                    <checkbox :onlyActive="false" :label="$t('vacancies.active')" name="active" template="inline" v-model="vacancy.active"></checkbox>
                                                </div>
                                                <div class="col-12 col-xxl-4">
                                                    <checkbox :onlyActive="false" :label="$t('vacancies.featured')" name="featured" template="inline" v-model="vacancy.featured"></checkbox>
                                                </div>
                                                <div class="col-12 col-xxl-4">
                                                    <checkbox :onlyActive="false" :label="$t('vacancies.hot')" name="hot" template="inline" iconColor="#ff7e05" iconClass="fab fa-hotjar" v-model="vacancy.hot"></checkbox>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="card mt-4">
                                        <div class="card-body">
                                            <div class="card-header p-0">
                                                <h5 class="font-weight-bolder">{{ $t('vacancies.requirements') }}</h5>
                                                <div class="header-icons">
                                                    <icon :onlyActive="false" :label="$t('vacancies.medical_book')" name="medical_book" template="icon" iconColor="#ff0000" iconClass="fas fa-book-medical" v-model="vacancy.medical_book"></icon>
                                                    <icon :onlyActive="false" :label="$t('vacancies.udt')" name="udt" template="icon" iconColor="#ffa500" iconClass="fas fa-dolly" v-model="vacancy.udt"></icon>
                                                    <icon :onlyActive="false" :label="$t('vacancies.experience')" name="experience" template="icon" iconColor="#454545" iconClass="fas fa-user-tie" v-model="vacancy.experience"></icon>
                                                    <gendersIcon :onlyActive="false" :label="$t('vacancies.allowed_genders')" v-model="vacancy.allowed_genders"></gendersIcon>
                                                </div>
                                            </div>
                                            <div class="row mt-4">
                                                <div class="col-12 col-sm-6">
                                                    <label>{{ $t('vacancies.age') }}</label>
                                                    <div class="row">
                                                        <div class="col-6">
                                                            <Text v-model="vacancy.min_age" name="min_age" label=""/>
                                                        </div>
                                                        <div class="col-6">
                                                            <Text v-model="vacancy.max_age" name="max_age" label=""/>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-12 col-sm-6">
                                                    <Text v-model="vacancy.number_of_workplaces" name="number_of_workplaces" :label="$t('vacancies.number_of_workplaces')"/>
                                                </div>
                                            </div>
                                            <div class="row mt-4">
                                                <div class="col-12 col-sm-12">
                                                    <Text v-model="vacancy.position" name="position" :label="$t('vacancies.position')"/>
                                                </div>
                                            </div>
                                            <div class="row mt-4">
                                                <div class="col-12 col-sm-12">
                                                    <Text  v-model="vacancy.citizenships" name="citizenships" :label="$t('Citizenship')"/>
                                                </div>
                                            </div>
                                            <div class="row mt-4">
                                                <div class="col-12">
                                                    <Text v-model="vacancy.special_requirements" name="special_requirements" :label="$t('vacancies.special_requirements')" />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-8 mt-lg-0 mt-4">
                                    <div class="row">
                                        <div class="col-sm-4">
                                            <div class="card">
                                                <div class="card-body">
                                                    <h5 class="font-weight-bolder">{{ $t('vacancies.workplace') }}</h5>
                                                    <Text  v-model="vacancy.facility" name="facility" :label="$t('Facility')"/>

                                                    <template v-if="hasPermission('edit-vacancy-company')">
                                                        <Text  v-model="vacancy.company" name="company" :label="$t('Company')"/>
                                                    </template>
                                                    
                                                    <Text  v-model="vacancy.work_city" name="work_city" :label="$t('vacancies.work_city')"/>
                                                    <Text  v-model="vacancy.documents_city" name="documents_city" :label="$t('vacancies.documents_city')"/>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-sm-4">
                                            <div class="card">
                                                <div class="card-body">
                                                    <h5 class="font-weight-bolder">{{ $t('vacancies.salary') }}</h5>
                                                    <Text v-model="vacancy.base_rate" name="base_rate" :label="$t('vacancies.base_rate')" />
                                                    <Text v-model="vacancy.young_rate" name="young_rate" :label="$t('vacancies.young_rate')" />
                                                    <Text v-model="vacancy.student_rate" name="student_rate" :label="$t('vacancies.student_rate')"/>
                                                    <Text v-model="vacancy.avg_salary" name="avg_salary" :label="$t('vacancies.avg_salary')" />
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-sm-4">
                                            <div class="card">
                                                <div class="card-body">
                                                    <h5 class="font-weight-bolder">{{ $t('vacancies.housing') }}</h5>
                                                    <checkbox :onlyActive="false" :label="$t('vacancies.housing_compensation')" name="housing_compensation" template="inline" v-model="vacancy.housing_compensation"></checkbox>
                                                    <Text  v-model="vacancy.housing_type" name="housing_type" :label="$t('vacancies.housing_type')"/>
                                                    <Text v-model="vacancy.housing_cost" name="housing_cost" :label="$t('vacancies.housing_cost')"/>
                                                    <Text v-model="vacancy.student_housing_cost" name="student_housing_cost" :label="$t('vacancies.student_housing_cost')" />
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-sm-6 mt-4">
                                            <div class="card">
                                                <div class="card-body">
                                                    <h5 class="font-weight-bolder">{{ $t('vacancies.conditions') }}</h5>
                                                    <div class="row">
                                                        <div class="col-8">
                                                            <Text v-model="vacancy.closest_city" name="closest_city" :label="$t('vacancies.closest_city')"/>
                                                        </div>
                                                        <div class="col-4">
                                                            <Text v-model="vacancy.closest_city_km" name="closest_city_km" :label="$t('vacancies.closest_city_km')"/>
                                                        </div>
                                                    </div>
                                                    <Text v-model="vacancy.contract_type" name="contract_type" :label="$t('vacancies.contract_type')"/>
                                                    <Text v-model="vacancy.conditions" name="conditions" :label="$t('vacancies.conditions_description')"/>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-sm-6 mt-4">
                                            <div class="card">
                                                <div class="card-body">
                                                    <h5 class="font-weight-bolder">{{ $t('vacancies.work_hours') }}</h5>
                                                    <checkbox :onlyActive="false" :label="$t('vacancies.night_shifts')" name="night_shifts" template="inline" v-model="vacancy.night_shifts"></checkbox>
                                                    <div class="row mt-4">
                                                        <div class="col-12">
                                                            <Text v-model="vacancy.work_schedule_text" name="work_schedule_text" label=""/>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="tab2" v-show="tab===2">
                            <div class="row">
                                <div class="col-sm-12 mt-4">
                                    <div class="card">
                                        <div class="card-body">
                                            <h5 class="font-weight-bolder">{{ $t('vacancies.vacancy_description') }}
                                                <a href="" @click.prevent="copyDesc()" class="px-2"><i class="fas fa-copy"></i></a>
                                            </h5>
                                            <SimpleSelect  v-model="template" name="template_switcher" :label="$t('DescriptionTemplate')" :options="templates" :multiple="false"/>
                                            <Text v-model="description" name="description" label="" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-sm-12 mt-4">
                                    <div class="card">
                                        <div class="card-body">
                                            <h5 class="font-weight-bolder">{{ $t('vacancies.recruiter_description') }}</h5>
                                            <Text v-model="vacancy.recruiter_description" name="recruiter_description" label="" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-sm-12 mt-4">
                                    <div class="card">
                                        <div class="card-body">
                                            <h5 class="font-weight-bolder">{{ $t('v_companies.recruiter_description') }}</h5>
                                            <Text v-model="vacancy.company_recruiter_description" name="company_recruiter_description" label="" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="tab3" v-show="tab===3">
                            <div class="row">
                                <div class="col-sm-12 mt-4">
                                    <div class="card">
                                        <Images :images="vacancy.files"/>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { Head } from '@inertiajs/vue3'
import { usePermissions } from '../../Composables/usePermissions';
import Listnav from "../../Layouts/Navbars/Auth/Listnav.vue";
import AuthenticatedLayout from '../../Layouts/AuthenticatedLayout.vue';
import Checkbox from "../../Components/FormShow/Checkbox.vue";
import Icon from "../../Components/FormShow/Icon.vue";
import GendersIcon from "../../Components/FormShow/GendersIcon.vue";
import Text from "../../Components/FormShow/Text.vue";
import Images from "../../Components/FormShow/Images.vue";
import { SimpleSelect } from '../../Components/Form';

export default {
    layout: AuthenticatedLayout,
    components: {
        Head, Listnav, Checkbox, Icon, GendersIcon, Text, Images, SimpleSelect
    },
    props: {
        vacancy: Object,
        templates: Array
    },
    setup(props) {
        return usePermissions();
    },
    data() {
        return {
            tab: 1,
            description: this.vacancy.description,
            template: 0
        }
    },
    methods: {
        goBack() {
            window.history.back();
        },
        copyDesc() {
            this.copyRichText(this.description);
        },
        copyRichText(html) {
            const htmlContent = html;
            // Create a temporary element to hold the HTML content
            const tempElement = document.createElement("div");
            tempElement.innerHTML = htmlContent;
            document.body.appendChild(tempElement);
            // Select the HTML content
            const range = document.createRange();
            range.selectNode(tempElement);
            // Copy the selected HTML content to the clipboard
            const selection = window.getSelection();
            selection.removeAllRanges();
            selection.addRange(range);
            document.execCommand("copy");
            selection.removeAllRanges();
            document.body.removeChild(tempElement);
        },
        makePreview(template_id) { 
            axios.post(route('template.make'), { vacancy_id: this.vacancy.id, template_id: template_id }).then((response) => {
                this.description = response.data.text;
            });
        }
    },
    watch: {
        template(newVal) {
            if (newVal > 0) {
                this.makePreview(newVal);
            }
            else {
                this.description = this.vacancy.description;
            }
        }
    }
}
</script>

<style lang="scss" scoped>
    .card-header{
        display: flex;
        justify-content: space-between;
        .header-icons{
            display: flex;
            align-self: center;
        }
    }

    .tabs{
        .nav-item{
            margin-left: 5px;
        }
        .nav-item:not(.active):hover{
            cursor: pointer;
            //font-weight: 600;
            box-shadow: 0 1px 5px 1px #ddd;
            //animation: .2s ease;
            background-color: #fff;
            border-radius: 0.5rem;
        }
        .nav-item.active {
            cursor: default;
            .nav-link, .nav-link:hover{
                font-weight: 600;
                box-shadow: 0 1px 5px 1px #ddd;
                color: #344767;
            }
        }
    }
</style>