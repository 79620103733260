<script setup>
import { watch } from 'vue';
import { Link } from '@inertiajs/vue3';
import { usePermissions } from '../../../Composables/usePermissions';

const props = defineProps(['show']);
const className = 'g-sidenav-pinned';
const body = document.getElementsByTagName('body')[0];

watch(() => props.show, (newValue, oldValue) => {
    if (body.classList.contains(className)) {
        body.classList.remove(className);
    } else {
        body.classList.add(className);
    }
});

const { hasRole, hasPermission } = usePermissions();
</script>
<template>
    <aside class="sidenav bg-white navbar navbar-vertical navbar-expand-xs border-0 border-radius-xl my-3 fixed-start ms-4" :class="show?'bg-white':''" id="sidenav-main">
        <div class="sidenav-header">
            <i class="fas fa-times p-3 cursor-pointer text-secondary opacity-5 position-absolute end-0 top-0 d-none d-xl-none"
                aria-hidden="true" id="iconSidenav"></i>
            <a class="navbar-brand m-0" href="javascript:void(0);">
                <span class="ms-1 font-weight-bold">TOT Group</span>
            </a>
        </div>
        <hr class="horizontal dark mt-0">
        <div class="w-auto " id="sidenav-collapse-main">
            <ul class="navbar-nav">
                <li v-if="hasPermission('show-lead')" class="nav-item">
                    <Link class="nav-link" :class="$page.url.startsWith('/lead')?'active':''" :href="route('lead.index')">
                        <div
                            class="icon icon-shape icon-sm border-radius-md text-center me-2 d-flex align-items-center justify-content-center">
                            <i class="ni ni-user-run text-primary text-sm opacity-10"></i>
                        </div>
                        <span class="nav-link-text ms-1">Лиды</span>
                    </Link>
                </li>
                <li v-if="hasPermission('show-vacancy')" class="nav-item">
                    <Link class="nav-link" :class="$page.url.startsWith('/vacancy')?'active':''" :href="route('vacancy.index')">
                        <div
                            class="icon icon-shape icon-sm border-radius-md text-center me-2 d-flex align-items-center justify-content-center">
                            <i class="ni ni-briefcase-24 text-primary text-sm opacity-10"></i>
                        </div>
                        <span class="nav-link-text ms-1">Вакансии</span>
                    </Link>
                </li>
                <li v-if="hasPermission('show-vacancy-company')" class="nav-item">
                    <Link class="nav-link" :class="$page.url.startsWith('/company')?'active':''" :href="route('company.index')">
                        <div
                            class="icon icon-shape icon-sm border-radius-md text-center me-2 d-flex align-items-center justify-content-center">
                            <i class="ni ni-vector text-primary text-sm opacity-10"></i>
                        </div>
                        <span class="nav-link-text ms-1">Посредники</span>
                    </Link>
                </li>
                <li v-if="hasPermission('show-vacancy-facility')" class="nav-item">
                    <Link class="nav-link" :class="$page.url.startsWith('/facility')?'active':''" :href="route('facility.index')">
                        <div
                            class="icon icon-shape icon-sm border-radius-md text-center me-2 d-flex align-items-center justify-content-center">
                            <i class="ni ni-building text-primary text-sm opacity-10"></i>
                        </div>
                        <span class="nav-link-text ms-1">Объекты</span>
                    </Link>
                </li>
                <li v-if="hasPermission('show-citizenship')" class="nav-item">
                    <Link class="nav-link" :class="$page.url.startsWith('/citizenship')?'active':''" :href="route('citizenship.index')">
                        <div
                            class="icon icon-shape icon-sm border-radius-md text-center me-2 d-flex align-items-center justify-content-center">
                            <i class="ni ni-paper-diploma text-primary text-sm opacity-10"></i>
                        </div>
                        <span class="nav-link-text ms-1">Гражданство</span>
                    </Link>
                </li>
                <li v-if="hasRole('Admin') || hasRole('Manager')" class="nav-item">
                    <Link class="nav-link" :class="$page.url.startsWith('/moderation/leads')?'active':''" :href="route('moderation.leads')">
                        <div
                            class="icon icon-shape icon-sm border-radius-md text-center me-2 d-flex align-items-center justify-content-center">
                            <i class="fas fa-money-check-alt text-primary text-sm opacity-10"></i>
                        </div>
                        <span class="nav-link-text ms-1">Модерация</span>
                    </Link>
                </li>
                <li v-if="hasRole('Admin')" class="nav-item">
                    <Link class="nav-link" :class="$page.url.startsWith('/user')?'active':''" :href="route('user.index')">
                        <div
                            class="icon icon-shape icon-sm border-radius-md text-center me-2 d-flex align-items-center justify-content-center">
                            <i class="ni ni-single-02 text-primary text-sm opacity-10"></i>
                        </div>
                        <span class="nav-link-text ms-1">Пользователи</span>
                    </Link>
                </li>
                <li v-if="hasRole('Admin') || hasRole('Manager')" class="nav-item">
                    <Link class="nav-link" :class="$page.url.startsWith('/template')?'active':''" :href="route('template.index')">
                        <div
                            class="icon icon-shape icon-sm border-radius-md text-center me-2 d-flex align-items-center justify-content-center">
                            <i class="fas fa-newspaper text-primary text-sm opacity-10"></i>
                        </div>
                        <span class="nav-link-text ms-1">Шаблоны</span>
                    </Link>
                </li>
                <li v-if="hasRole('Admin') || hasRole('Manager')" class="nav-item">
                    <Link class="nav-link" :class="$page.url.startsWith('/recruiter-plan')?'active':''" :href="route('recruiter-plan.index')">
                        <div
                            class="icon icon-shape icon-sm border-radius-md text-center me-2 d-flex align-items-center justify-content-center">
                            <i class="fas fa-tasks text-primary text-sm opacity-10"></i>
                        </div>
                        <span class="nav-link-text ms-1">Планы рекрутеров</span>
                    </Link>
                </li>
                <li v-if="hasRole('Admin') || hasRole('Manager')" class="nav-item">
                    <Link class="nav-link" :class="$page.url.startsWith('/daily-report/show')?'active':''" :href="route('daily-report.show')">
                        <div
                            class="icon icon-shape icon-sm border-radius-md text-center me-2 d-flex align-items-center justify-content-center">
                            <i class="fas fa-clock text-primary text-sm opacity-10"></i>
                        </div>
                        <span class="nav-link-text ms-1">Отчеты рекрутеров</span>
                    </Link>
                </li>
            </ul>
        </div>
    </aside>
</template>