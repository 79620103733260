<template>
    <div class="filter-plugin" :class="show?'show':''">
        <div class="card shadow-lg">
            <div class="card-header pb-0 pt-3 ">
                <div class="float-start">
                    <h5 class="mt-3 mb-0">Фильтр</h5>
                </div>
                <div class="float-end mt-4">
                    <button class="btn btn-link text-dark p-0 filter-plugin-close-button" @click="close">
                        <i class="fas fa-times"></i>
                    </button>
                </div>
                <!-- End Toggle Button -->
            </div>
            <hr class="horizontal dark my-1">
            <div class="card-body pt-sm-3 pt-0 overflow-auto">
                <template v-for="(field, name) in fields">
                    <template v-if="field.type === 'range'">
                        <div class="slider-wrapper form-group">
                            <label class="form-control-label">{{ field.label }}</label>
                            <Slider :min="field.min" :max="field.max" :format="null" :step="field.step" v-model="field.value" class="slider-blue" @change="handleRangeChange($event, name)"/>
                        </div>
                    </template>

                    <template v-if="field.type === 'simple-select'">
                        <simple-select :options="field.options" :name="name" :label="field.label" :multiple="field?.multiple" v-model.lazy="query[name]"/>
                    </template>

                    <template v-if="field.type === 'select-ajax'">
                        <div class="form-group">
                            <select-ajax v-model.lazy="query[name]" :name="name" :label="field.label" :clearable="field.clearable ?? false" :ajax="field.ajax"/>
                        </div>
                    </template>

                    <template v-if="field.type === 'select-ajax-multiple'">
                        <div class="form-group">
                            <select-ajax-multiple class="form-group" v-model.lazy="query[name]" :name="name" :label="field.label" :clearable="field.clearable ?? false" :ajax="field.ajax"/>
                        </div>
                    </template>

                    <template v-if="field.type === 'checkbox'">
                        <checkbox v-model.lazy="query[name]" :name="name" :label="field.label" template="inline"/>
                    </template>

                    <template v-if="field.type === 'input'">
                        <TextInput v-model.lazy="query[name]" :name="name" :label="field.label"/>
                    </template>
                </template>
                
                
                <!-- <button @click.prevent="urlParams(query)" class="btn btn-primary btn-sm ms-auto my-4 w-100">Фильтр</button>   -->
                <a class="btn btn-secondary btn-sm ms-auto w-100 my-4" @click.prevent="clear">Очистить</a>
            </div>
            <debug :show="false" :param="query"/>
        </div>
    </div>
</template>

<script>
import { SimpleSelect, SelectAjaxMultiple, SelectAjax, Checkbox, TextInput } from "../Components/Form";
import Debug from '../Components/Debug.vue';
import { reactive } from "vue";
import Slider from '@vueform/slider'

export default {
    props: {
        show: Boolean,
        preset: {
            type: Object,
            default: {}
        },
        fields: {
            type: Object,
            required: true
        }
    },
    components: {
        SelectAjaxMultiple,
        SelectAjax,
        SimpleSelect,
        Checkbox,
        Debug,
        Slider,
        TextInput,
    },
    setup(props){
        let preset = props.preset ?? {};
        const query = reactive(preset);
        
        return { query };
    },
    data() {
        return {}
    },
    methods: {
        handleRangeChange(newValue, fieldName) {
            this.query[fieldName] = newValue;
        },
        close() {
            this.$emit('close');
        },
        clear() {
            //Нельзя просто изменить обьект, теряется реактивность
            Object.keys(this.query).forEach(key => {
                this.query[key] = null;
            });

            Object.keys(this.fields).forEach(key => {
                if (this.fields[key].type === 'range') {
                    this.fields[key].value = [this.fields[key].min, this.fields[key].max];
                }
            });

            this.$emit('filter-clear');
        },
        urlParams(query) {
            let params = {};
            Object.keys(query).forEach(function (key) {
                const value = query[key];
                
                if (value !== null) {
                    if (
                        (typeof value[Symbol.iterator] === 'function' && value.length > 0) ||
                        (typeof value[Symbol.iterator] !== 'function' && value !== null) 
                    ) {
                        if (typeof value === 'object') {
                            if (Array.isArray(value)) {
                                params['filter[' + key + ']'] = value;
                            } else {
                                params['filter[' + key + ']'] = value.id;
                            }
                        } else {
                            params['filter[' + key + ']'] = value;
                        }
                    }
                }

            });
            return params;
        }
    },
    watch: {
        query: {
            handler(newValue, oldValue) {
                const params = this.urlParams(newValue);
                this.$emit('filter-change', params);
            },
            deep: true,
        },
    }
}
</script>
<style scoped>
.slider-wrapper{
    margin: 0 0 25px 0;
    padding: 0 10px;
}

.slider-wrapper .form-control-label{
    margin-bottom: 50px;
    margin-left: -10px;
}
</style>