<template>
    <div :class="wrapper">
        <div v-if="$page.props.flash.success && showSuccess" class="alert alert-success alert-dismissible fade show" role="alert">
            <p class="text-white mb-0">{{ success || $page.props.flash.success }}</p>
        </div>

        <div v-if="$page.props.flash.error && showErrors" class="alert alert-danger" role="alert">
            <p class="text-white mb-0">{{ error || $page.props.flash.error }}</p>
        </div>

        <div v-if="Object.keys(filteredErrors).length" class="alert alert-danger" role="alert">
            <p v-for="error in filteredErrors" class="text-white mb-0">
                <span v-if="Array.isArray(error)" v-for="text in error">
                    {{ text }} <br>
                </span>
                <span v-else>
                    {{ error }}
                </span>
            </p>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        wrapper: {
            type: String,
            default: "px-4 pt-4"
        },
        success: {
            type: String,
            default: null
        },
        error: {
            type: String,
            default: null
        },
        errors: {
            type: Object,
            default: {}
        },
        fields: {
            type: Array,
            default: []
        },
        showSuccess: {
            type: Boolean,
            default: true
        },
        showErrors: {
            type: Boolean,
            default: true
        }
    },
    computed: {
        filteredErrors() {
            if (this.fields.length > 0) {
                const filtered = Object.keys(this.errors)
                    .filter(key => this.fields.includes(key))
                    .reduce((obj, key) => {
                        obj[key] = this.errors[key];
                        return obj;
                    }, {});
                return filtered;
            }
            return this.errors;
        }
    }
}
</script>