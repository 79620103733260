<template>
    <Head :title="$t('Users')"/>
    <Listnav :title="$t('Users')">
        <template #buttons>
            <div v-if="hasPermission('create-user')" class="col-auto my-auto">
                <Link :href="route('user.create')" class="btn btn-primary btn-sm ms-auto m-0">{{ $t('create') }}</Link>
            </div>
        </template>
    </Listnav>
    <div class="container-fluid py-4">
        <div class="row">
            <div class="col-12">
                <div class="card">
                    <div class="card-body pb-2">
                        <p v-if="!users" class="text-sm text-center">{{ $t('No records') }}</p>

                        <div class="table-responsive">
                            <table class="table align-items-center mb-0">
                                <thead>
                                    <tr>
                                        <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">
                                            {{ $t('user.id') }}
                                        </th>
                                        <th
                                            class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2">
                                            {{ $t('user.username') }}
                                        </th>
                                        <th
                                            class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2">
                                            {{ $t('user.email') }}
                                        </th>
                                        <th
                                            class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2">
                                            {{ $t('user.firstname') }}
                                        </th>
                                        <th
                                            class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">
                                            {{ $t('user.lastname') }}
                                        </th>
                                        <th class="text-secondary action-buttons"></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr v-for="user in users.data">
                                        <td>
                                            <p class="text-sm text-secondary mb-0 ps-3">{{ user?.id }}</p>
                                        </td>
                                        <td>
                                            <Link :href="route('user.edit', user.id)">
                                            <div class="d-flex px-2 py-1">
                                                <div>
                                                    <img :src="user?.avatar ?? '/img/team-2.jpg'" class="avatar avatar-sm me-3"
                                                        alt="avatar image">
                                                </div>
                                                <div class="d-flex flex-column justify-content-center">
                                                    <h6 class="mb-0 text-sm">{{ user?.username }}</h6>
                                                </div>
                                            </div>
                                            </Link>
                                        </td>
                                        <td>
                                            <p class="text-sm text-secondary mb-0">{{ user?.email }}</p>
                                        </td>
                                        <td>
                                            <p class="text-sm text-secondary mb-0">{{ user?.firstname }}</p>
                                        </td>
                                        <td class="align-middle text-center text-sm">
                                            <p class="text-sm text-secondary mb-0">{{ user?.lastname }}</p>
                                        </td>

                                        <td class="align-middle">

                                            <Link :href="route('user.login-as', user.id)" class="mx-3 action-link"
                                                title="Авторизироваться" v-if="hasRole('Admin')">
                                                <i class="fas fa-user-secret"></i></Link>

                                            <Link :href="route('user.edit', user.id)" class="mx-3 action-link"
                                                title="Редактировать" v-if="hasPermission('edit-user')"><i
                                                class="far fa-edit"></i></Link>

                                            <a @click.prevent="deleteUser(user.id)"
                                                :href="route('user.destroy', user.id)" class="mx-3 action-link"
                                                title="Удалить" v-if="hasPermission('delete-user')">
                                                <i class="far fa-trash-alt"></i>
                                            </a>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <Pagination :meta="users.meta" :links="users.links"/>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>


<script>
import { Head, Link, router } from "@inertiajs/vue3";
import AuthenticatedLayout from "../../Layouts/AuthenticatedLayout.vue";
import { usePermissions } from '../../Composables/usePermissions';
import Pagination from "../../Components/Pagination.vue";
import Listnav from "../../Layouts/Navbars/Auth/Listnav.vue";
import { useToast } from "vue-toastification";

export default {
    layout: AuthenticatedLayout,
    components: {
        Head, Pagination, Link, Listnav
    },
    props: {
        users: Object,
    },
    setup(props) {
        const { hasRole, hasPermission } = usePermissions();
        return { hasRole, hasPermission };
    },
    data() {
        return {};
    },
    methods: {
        deleteUser(id)
        {
            Swal.fire({
                title: "Подтвердить удаление?",
                text: "Изменения нельзя будет отменить",
                icon: "warning",
                showCancelButton: true,
                confirmButtonText: "Да",
                cancelButtonText: "Отменить",
                confirmButtonColor: "#2dce89",
                cancelButtonColor: "#d33",
            })
            .then((result) => {
                if (result.isConfirmed) {
                    router.delete(route('user.destroy', id), {
                        onSuccess: () => {
                        },
                        onError: (error) => {
                            Swal.fire({
                                icon: 'error',
                                width: 300,
                                text: response.text,
                                position: 'top-end',
                                toast: true,
                                timer: 2000,
                                showConfirmButton: false,
                            });
                        },
                    });
                }
            });
        }
    },
    mounted() {
        const toast = useToast();
        if (this.$page.props.flash.success) {
            toast.success(this.$page.props.flash.success, {
                timeout: 2000
            });
        }
    }
}

</script>