<template>
    <div class="ajax-select-wrapper">
        <label class="form-control-label">{{ label }}</label>
        <vue-select
            multiple
            :label="ajax.attribute"
            :clearable="clearable"
            :clear-search-on-select="true"
            :name="name"
            v-model="selectValue"
            :options = options
            :filterable="false"
            :class="hasError ? 'is-invalid' : ''"
        >   
            <template #open-indicator="{ attributes }">
                <span class="select-indicator"></span>
            </template>
            <template  #list-footer>
                <li v-show="pagination.has_next_page" ref="load" :id="'load-element-'+name" class="loader text-center">
                    {{ loadingLabel }}
                </li>
            </template>
            <template #no-options="{ search, searching, loading }">
                Нет результатов
            </template>
        </vue-select>
    </div>
</template>

<script>
import vSelect from "vue-select";
import _ from 'lodash';
import axios from "axios";

export default {
    components: {
        "vue-select":vSelect
    },
    props: {
        modelValue: null,
        multiple: {
            type: Boolean,
            default: false
        },
        hasError: {
            type: Boolean,
            default: false
        },
        clearable: {
            type: Boolean,
            default: true
        },
        ajax: {
            type: Object,
            required: true
        },
        name: {
            type: String,
            required: true
        },
        ajax_url: {
            type: String,
            default: '/ajax/select2'
        },
        loading_label: {
            type: String,
            default: 'Загрузка...'
        },
        label: {
            type: String,
            default: null
        },
        class: {
            type: String,
            default: null
        }
    },
    data(){
        return {
            options: [],
            pagination: {}
        };
    },
    methods: {
        fetchOptions: _.debounce((vm, url, search=null, loading=null, target=null) => {
            if (url) {
                const ul = target?.offsetParent;
                const scrollTop = target?.offsetParent.scrollTop;
              
                axios
                    .post(url, {
                        q: search,
                        selected: vm.selectValue,
                        attribute: vm.ajax.title,
                        related_model: vm.ajax.relatedModel,
                        resource: vm.ajax.resource,
                    })
                    .then(response => {
                        vm.options = vm.options.concat(response.data.data);
                        vm.pagination.next_page_url = response.data.next_page_url;
                        vm.pagination.current_page = response.data.current_page;
                        vm.pagination.last_page = response.data.last_page;
                        vm.pagination.has_next_page = (response.data.current_page != response.data.last_page);
                    }).then(function () {
                        if (ul !== undefined)
                            ul.scrollTop = scrollTop;
                    });
            }
        }, 350)
    },
    mounted() {
        this.fetchOptions(this,this.ajaxUrl);
    },
    computed: {
        selectValue:{
            get: function () {
                return this.modelValue;
            },
            set: function (newValue) {
                this.$emit('update:modelValue',newValue);
            }
        },
        ajaxUrl: function(){
            return this.ajax_url;
        },
        loadingLabel: function() {
            return this.loading_label;
        }
    },
}
</script>