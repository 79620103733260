<template>
    <div class="form-group">
        <label v-if="label" :for="id" class="form-control-label">{{ label }}</label>
        <vue-tel-input :inputOptions="options" v-model="value" :id="id" :class="class" :name="name"/>
    </div>
</template>


<script>
import { VueTelInput } from 'vue-tel-input';
import { nanoid } from 'nanoid'

export default {
    components: {
        'vue-tel-input':VueTelInput
    },
    props: [
        'name',
        'modelValue',
        'label',
        'class',
        'type',
        'repeaterUpdate',
        'repeaterIndex',
        'placeholder'
    ],
    methods: {},
    computed: {
        value: {
            get: function () {
                return this.modelValue;
            },
            set: function (newValue) {
                if (typeof this.repeaterUpdate == 'function')
                    this.repeaterUpdate(this.repeaterIndex, this.name, newValue);
                this.$emit('update:modelValue', newValue);
            },
        },
        id: function ()  {
            return this.name + "-" + nanoid(5);
        },
        options: function () {
            return {
                placeholder: this.placeholder
            }
        }
    },
}
</script>