<template>
    <Head :title="$t('auth')"/>
    <div class="container position-sticky z-index-sticky top-0">
        <div class="row">
            <div class="col-12">
                
            </div>
        </div>
    </div>
    <main class="main-content mt-0 bg-white">
        <section>
            <div class="page-header min-vh-100">
                <div class="container">
                    <div class="row">
                        <div class="col-xl-4 col-lg-5 col-md-7 d-flex flex-column mx-lg-0 mx-auto">
                            <div class="card card-plain">
                                <div class="card-header pb-0 text-start">
                                    <h4 class="font-weight-bolder">{{ $t('auth') }}</h4>
                                    <p class="mb-0">{{ $t('auth_text') }}</p>
                                </div>
                                <div class="card-body">
                                    <form role="form" @submit.prevent="loginForm.post(route('login.perform'))">
                                        <div class="flex flex-col mb-3">
                                            <input type="text" placeholder="username" name="username" class="form-control form-control-lg" v-model="loginForm.username" aria-label="Username">
                                            <p class="text-danger text-xs pt-1" v-if="errors.username">{{ errors.username }}</p>
                                        </div>
                                        <div class="flex flex-col mb-3">
                                            <input type="password" name="password" placeholder="*******" class="form-control form-control-lg" aria-label="Password" v-model="loginForm.password" >
                                            <p class="text-danger text-xs pt-1" v-if="errors.password">{{ errors.password }}</p>
                                        </div>
                                        <div class="text-center">
                                            <button type="submit" class="btn btn-lg btn-primary btn-lg w-100 mt-4 mb-0">{{ $t('Login') }}</button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                        <div
                            class="col-6 d-lg-flex d-none h-100 my-auto pe-0 position-absolute top-0 end-0 text-center justify-content-center flex-column">
                            <div class="position-relative bg-gradient-primary h-100 m-3 px-7 border-radius-lg d-flex flex-column justify-content-center overflow-hidden"
                                style="background-image: url('https://raw.githubusercontent.com/creativetimofficial/public-assets/master/argon-dashboard-pro/assets/img/signin-ill.jpg');
              background-size: cover;">
                                <span class="mask bg-gradient-primary opacity-6"></span>
                                <h4 class="mt-5 text-white font-weight-bolder position-relative">TOT Group</h4>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </main>
</template>

<script>
import { Head, useForm } from "@inertiajs/vue3";
import { trans } from 'laravel-vue-i18n';

export default {
    components: {
        Head
    },
    props: {
        errors: Object
    },
    setup(){
        const loginForm = useForm({
            username: null,
            password: null
        });

        return { loginForm };
    }
}
</script>