<template>
<div class="card shadow-lg mx-4">
    <div class="card-body py-2 px-3">
        <div class="row gx-4">
            <slot name="title">
                <div class="col-auto my-auto">
                    <div class="h-100">
                        <h5 class="mb-1">
                            {{ title }}
                        </h5>
                    </div>
                </div>
            </slot>
            <slot name="middle">
                <div class="col-lg-4 col-md-6 my-sm-auto ms-sm-auto me-sm-0 mx-auto mt-3"></div>
            </slot>
            <div class="col-auto my-auto">
                <slot name="plugins">
                </slot>
            </div>
            <slot name="buttons">
                <div v-if="hasPermission(createPermission)" class="col-auto my-auto">
                    <Link v-if="createUrl" :href="createUrl" class="btn btn-primary btn-sm ms-auto m-0">Создать</Link>
                </div>
            </slot>
        </div>
    </div>
</div>
</template>

<script>
import { Link } from "@inertiajs/vue3";
import { usePermissions } from "../../../Composables/usePermissions";
export default {
    components: {
        Link
    },
    props: {
        title: {
            type: String,
            default: ''
        },
        filter: {
            type: Boolean,
            default: false
        },
        createUrl: {
            type: String,
            default: null
        },
        createPermission: {
            type: String,
            default: ''
        }
    },
    setup() {
        return usePermissions();
    }
}
</script>