<template>
    <Head :title="$t('leads.moderation_title')"/>
    <Listnav :title="$t('leads.moderation_title')">
        <template #plugins>
            <a href="javascript:;" class="filter-link p-0" @click.prevent="this.showFilter = !this.showFilter">
                <i class="fas fa-filter filter-plugin-button-nav cursor-pointer" aria-hidden="true"></i>
            </a>
        </template>
        <template #buttons>
            <div class="col-auto my-auto" v-if="changesMade && calcingLeadKey === null">
                <button class="btn btn-primary btn-sm ms-auto m-0" @click="saveAllChanges()">{{ $t('save_all') }}</button>
            </div>
        </template>
    </Listnav>
    <div class="leads-moderation">
        <div class="container-fluid pt-4">
            <div id="list_wrap">
                <div class="row">
                    <div class="col-12">
                        <div class="card" id="list">
                            <div class="px-4 pt-4">
                                <span>
                                    <Alert :errors="errors_single" :wrapper="null" :fields="['recruited_date', 'first_workday', 'leave_date', 'worked_days', 'required_workdays']" :showSuccess="false"/>
                                </span>
                                <span>
                                    <Alert :errors="errors" :showSuccess="false" :wrapper="null"/>
                                </span>
                            </div>
                            <div class="card-body pb-2">
                                <scroll-arrows :perfect-scrollbar="ps"/>
                                <perfect-scrollbar ref="scroll" id="list_container" class="table-responsive p-0" :options="scrollOptions">
                                    <table class="table align-items-center mb-0">
                                        <thead>
                                            <tr>
                                                <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">
                                                </th>
                                                <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">
                                                    {{ $t('leads.lead') }}
                                                </th>
                                                <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2">
                                                    {{ $t('leads.recruited_date') }}
                                                </th>
                                                <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2">
                                                    {{ $t('leads.work_start_date') }}
                                                </th>
                                                <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2">
                                                    {{ $t('leads.leave_date') }}
                                                </th>
                                                <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2">
                                                    <a href="javascript:;" @click.prevent="onSort('worked_days')">
                                                        {{ $t('leads.worked_days') }}
                                                    </a>
                                                </th>
                                                <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2">
                                                    <a href="javascript:;" @click.prevent="onSort('required_workdays')">
                                                        {{ $t('leads.need_work') }}
                                                    </a>
                                                </th>
                                                <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2">
                                                    {{ $t('leads.vacancy') }}
                                                </th>
                                                <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2">
                                                    {{ $t('leads.status') }}
                                                </th>
                                                <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2 action-buttons"></th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr v-for="(lead, key) in leads.data">
                                                <td>
                                                    <a href="javascript:;" v-tooltip="lead.recruiter.full_name">
                                                        <img :src="lead.recruiter.avatar" class="avatar avatar-sm" alt="avatar image">
                                                    </a>
                                                </td>
                                                <td>
                                                    <div class="d-flex py-1">
                                                        <div class="icon-wrapper">
                                                            <i v-if="lead.sex == 1" class="fas fa-mars"></i>
                                                            <i v-if="lead.sex == 2" class="fas fa-venus"></i>
                                                            <i v-if="lead.sex == 3" class="fas fa-venus-mars"></i>
                                                        </div>
                                                        <div class="d-flex flex-column justify-content-center">
                                                            <Link :href="route('lead.edit', lead.lead_id)" class="h6 mb-0 text-sm">{{ lead.full_name }}</Link>
                                                        </div>
                                                    </div>
                                                </td>
                                                <td>
                                                    <template v-if="lead.work_status == 'confirmed'">
                                                        <p class="text-sm font-weight-bold mb-0">{{ formatDate(lead.recruited_date) }} </p>
                                                    </template>
                                                    <template v-else>
                                                        <Date v-model="lead.recruited_date" wrapperClass="m-0" @update:modelValue="handleChange(key)" :class="[((key == savingLeadKey && errors_single.recruited_date) ? 'is-invalid' : ''), ((key in badLeads && badLeads[key].includes('recruited_date')) ? 'is-invalid' : '')]"></Date>
                                                    </template>
                                                </td>
                                                <td>
                                                    <template v-if="lead.work_status == 'confirmed'">
                                                        <p class="text-sm font-weight-bold mb-0">{{ formatDate(lead.first_workday) }}</p>
                                                    </template>
                                                    <template v-else>
                                                        <Date v-model="lead.first_workday" wrapperClass="m-0" @update:modelValue="handleChange(key, 'first_workday')" :class="[((key == savingLeadKey && errors_single.first_workday) ? 'is-invalid' : ''), ((key in badLeads && badLeads[key].includes('first_workday')) ? 'is-invalid' : '')]"></Date>
                                                    </template>
                                                </td>
                                                <td>
                                                    <template v-if="lead.work_status == 'confirmed'">
                                                        <p class="text-sm font-weight-bold mb-0"> {{ formatDate(lead.leave_date) }}</p>
                                                    </template>
                                                    <template v-else>
                                                        <Date v-model="lead.leave_date" wrapperClass="m-0" @update:modelValue="handleChange(key, 'leave_date')" :class="[((key == savingLeadKey && errors_single.leave_date) ? 'is-invalid' : ''), ((key in badLeads && badLeads[key].includes('leave_date')) ? 'is-invalid' : '')]"></Date>
                                                    </template>
                                                </td>
                                                <td class="align-middle text-center">
                                                    <template v-if="lead.work_status == 'confirmed'">
                                                        <p class="text-sm font-weight-bold mb-0">{{ lead.worked_days }}</p>
                                                    </template>
                                                    <template v-else>
                                                        <TextInput v-model="lead.worked_days" :name="lead.id+'_workedDays'" label="" wrapperClass="mb-0" class="list-input font-weight-bold" :class="[((key == savingLeadKey && errors_single.worked_days) ? 'is-invalid' : ''), ((key in badLeads && badLeads[key].includes('worked_days')) ? 'is-invalid' : '')]" @update:modelValue="handleChange(key, 'worked_days')"/>
                                                    </template>
                                                </td>
                                                <td class="align-middle text-center">
                                                    <template v-if="lead.work_status == 'confirmed'">
                                                        <p class="text-sm font-weight-bold mb-0">{{ lead.required_workdays }}</p>
                                                    </template>
                                                    <template v-else>
                                                        <TextInput v-model="lead.required_workdays" :name="lead.id+'_requiredWorkdays'" label="" wrapperClass="mb-0" class="list-input font-weight-bold" :class="[((key == savingLeadKey && errors_single.required_workdays) ? 'is-invalid' : ''), ((key in badLeads && badLeads[key].includes('required_workdays')) ? 'is-invalid' : '')]" @update:modelValue="handleChange(key)"/>
                                                    </template>
                                                </td>
                                                <td>
                                                    <Link v-if="lead.vacancy_id" :href="hasPermission('edit-vacancy') === true ? route('vacancy.edit', lead.vacancy_id) : route('vacancy.show', lead.vacancy_id)"><p class="text-sm font-weight-bold mb-0">{{ lead.vacancy_title }}</p></Link>
                                                </td>
                                                <td class="align-middle">
                                                    <p class="text-sm font-weight-bold mb-0">
                                                        <span v-if="lead.work_status == 'fired'" class="text-danger">{{ $t('leads.work_status_fired') }}</span>
                                                        <span v-if="lead.work_status == 'worked'" class="text-warning">{{ $t('leads.work_status_worked') }}</span>
                                                        <span v-if="lead.work_status == 'confirmed'" class="text-success">{{ $t('leads.work_status_confirmed') }}</span>
                                                        <span v-if="lead.work_status == 'working'" class="text-primary">{{ $t('leads.work_status_working') }}</span>
                                                    </p>
                                                </td>
                                                <td class="align-middle text-center">
                                                    <template v-if="lead.changed">
                                                        <template v-if="calcingLeadKey === key">
                                                            <div class="spinner-border text-default" role="status">
                                                                <span class="sr-only">Loading...</span>
                                                            </div>
                                                        </template>
                                                        <template v-else>
                                                            <button class="btn btn-primary btn-mdr mb-0" type="button" @click="saveLeadStat(lead, key)"><i class="fas fa-share"></i></button>
                                                        </template>
                                                    </template>
                                                    <template v-else>
                                                        <template v-if="lead.work_status == 'worked'">
                                                            <button class="btn btn-success btn-mdr mb-0" type="button" @click="handleConfirm(key)"><i class="fas fa-check opacity-10" style="top: -1px;" aria-hidden="true"></i></button>
                                                        </template>
                                                        <template v-if="lead.work_status == 'confirmed'">
                                                            <button class="btn btn-danger btn-mdr mb-0" type="button" @click="handleCancel(key)"><i class="fas fa-times opacity-10" aria-hidden="true"></i></button>
                                                        </template>
                                                    </template>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </perfect-scrollbar>
                                <Pagination :meta="leads.meta" :links="leads.links"/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <sidebar-filter :show="showFilter" :fields="filterFields" :preset="this.preset?.filter" @close="showFilter=false" @filter-change="onFilterChange" @filter-clear="onFilterClear"></sidebar-filter>
</template>

<script>
import { useForm, Link, Head, router  } from "@inertiajs/vue3";
import AuthenticatedLayout from "../../Layouts/AuthenticatedLayout.vue";
import Alert from "../../Components/Alert.vue";
import Listnav from "../../Layouts/Navbars/Auth/Listnav.vue";
import Pagination from "../../Components/Pagination.vue";
import SidebarFilter from "../../Components/SidebarFilter.vue";
import { usePermissions } from '../../Composables/usePermissions';
import { PerfectScrollbar } from 'vue3-perfect-scrollbar';
import ScrollArrows from '../../Components/ScrollArrows.vue';
import { useToast } from "vue-toastification";
import { Date, TextInput } from "../../Components/Form";
import moment from 'moment';
import _ from 'lodash';

export default {
    layout: AuthenticatedLayout,
    components: {
        PerfectScrollbar, ScrollArrows, Listnav, Alert, Link, Head, Pagination, SidebarFilter, TextInput, Date
    },
    props: {
        leads: Object,
        preset: {
            type: Object,
            default: {}
        }
    },
    setup(props) {
        const { hasRole, hasPermission } = usePermissions();
        const toast = useToast();
        return { toast, hasRole, hasPermission };
    },
    data(){
        return {
            queries: this.preset,
            showFilter: false,
            filterFields: {
                'lead.vacancy_id': {
                    label: 'Вакансия',
                    type: 'select-ajax',
                    ajax: {
                        relatedModel: 'App\\Models\\Vacancy',
                        resource: 'App\\Http\\Resources\\VacancyShortResource',
                        attribute: 'title'
                    }
                },
                'worked_confirmed': {
                    label: 'Отработка подтверждена',
                    type: 'simple-select',
                    options: [
                        {
                            label: 'Да',
                            value: 1
                        },
                        {
                            label: 'Нет',
                            value: 0
                        },
                    ],
                }
            },
            scrollOptions: {
                handlers: ['drag-thumb', 'keyboard', 'touch', 'wheel']
            },
            ps: null,
            savingLeadKey: null,
            calcingLeadKey: null,
            errors: {},
            errors_single: {},
            badLeads: [],
        };
    },
    created() {
        /**
         * Запрос для просчета и изменения значений в таблице на лету
         */
        this.calcRequest = _.debounce((vm, lead_statistic, key, field_name) => {
            axios.post(route('moderation.leads.calc', lead_statistic.id), {
                changed_field_name: field_name,
                first_workday: lead_statistic.first_workday,
                worked_days: parseInt(lead_statistic.worked_days),
                leave_date: lead_statistic.leave_date,
            }).then(response => {
                vm.leads.data[key]['first_workday'] = response.data.first_workday;
                vm.leads.data[key]['worked_days'] = response.data.worked_days;
                vm.leads.data[key]['leave_date'] = response.data.leave_date;
                vm.calcingLeadKey = null;
            });
        }, 1000);
    },
    methods: {

        /*
        |--------------------------------------------------------------------------
        | Логика модерации
        |--------------------------------------------------------------------------
        */

        /**
         * Подтверждение отработки
         * @param key 
         */
        handleConfirm(key) {
            this.leads.data[key]['worked_confirmed'] = true;
            this.workConfirm(this.leads.data[key], key);
        },

        /**
         * Отмена подтверждения отработки
         * @param key 
         */
        handleCancel(key) {
            this.leads.data[key]['worked_confirmed'] = false;
            this.workConfirm(this.leads.data[key], key);
        },

        /**
         * Помечает строку как измененную + отправляет запрос на пересчет
         * @param key 
         * @param field_name 
         */
        handleChange(key, field_name = null) {
            this.leads.data[key]['changed'] = true;

            if (field_name === 'first_workday' || field_name === 'worked_days' || field_name === 'leave_date') {
                this.calcingLeadKey = key;
                this.calcRequest(this, this.leads.data[key], key, field_name);
            }
        },

        /**
         * Помечает лида как 
         * @param id 
         */
        findLeadKeyById(id)
        {
            for (let index = 0; index < this.leads.data.length; index++) {
                const lead = this.leads.data[index];
                if (lead.id === id) {
                    return index;
                }
            }
        },

        /**
         * Выфильтровывает строки с ошибками для подсветки
         */
        setBadLeads(leads, changed_leads)
        {
            const tmp = [];
            const result = Object.keys(leads).map(key => {
                const match = key.match(/^(\d+)\.(.+)$/);
                return match ? { id: match[1], field: match[2] } : null;
            }).filter(item => item !== null);

            result.forEach(item => {
                if (!Array.isArray(tmp[item.id])) {
                    tmp[item.id] = [];
                }

                tmp[item.id].push(item.field);
            });

            for (let index = 0; index < changed_leads.length; index++) {
                const item = changed_leads[index];
                if (tmp[index]) {
                    this.badLeads[this.findLeadKeyById(item.id)] = tmp[index];
                }
            }
        },

        /**
         * Чистит все переменные отвечающие за хранение ошибок
         */
        cleanAllErrors()
        {
            this.errors = {};
            this.errors_single = {};
            this.badLeads = [];
        },

        /*
        |--------------------------------------------------------------------------
        | Запросы
        |--------------------------------------------------------------------------
        */

        /**
         * Сохранение одной строки
         * @param lead_statistic 
         * @param key 
         */
        saveLeadStat(lead_statistic, key)
        {
            this.cleanAllErrors();
            this.savingLeadKey = key;
            axios.post(route('moderation.leads.update', lead_statistic.id), lead_statistic)
                .then(response => {
                    this.toast.success(response.data.success, { timeout: 2000 });
                    this.leads.data[key] = response.data.lead_statistic;
                    this.leads.data[key]['changed'] = false;
                })
                .catch(error => {
                    this.errors_single = error.response.data.errors ?? {};
                    this.toast.error('Ошибка', { timeout: 2000 });
                });
        },

        /**
         * Сохранение всех строчек с изменениями
         */
        saveAllChanges()
        {
            this.cleanAllErrors();
            let changed_leads = this.leads.data.filter(lead => lead.changed === true);
            
            axios.post(route('moderation.leads.update.multiple'), changed_leads)
                .then(response => {
                    this.toast.success(response.data.success, { timeout: 2000 });

                    if (response.data.lead_statistics) {
                        for (let index = 0; index < response.data.lead_statistics.length; index++) {
                            const lead = response.data.lead_statistics[index];
                            const key = this.findLeadKeyById(lead.id);

                            if (key >= 0 && lead) {
                                this.leads.data[key] = lead;
                                this.leads.data[key]['changed'] = false;
                            }
                        }
                    }
                })
                .catch(error => {
                    this.errors = { error: error.response.data.message };
                    this.toast.error('Ошибка', { timeout: 2000 });
                    this.setBadLeads(error.response.data.errors, changed_leads);
                });
        },

        /**
         * Подтверждение/Отмена подтверждения отработки
         */
        workConfirm(lead_statistic, key)
        {
            this.savingLeadKey = key;
            axios.post(route('moderation.leads.confirm', lead_statistic.id), {
                worked_confirmed: lead_statistic.worked_confirmed
            }).then(response => {
                this.toast.success(response.data.success, { timeout: 2000 });
                this.leads.data[key]['changed'] = false;
                this.leads.data[key]['work_status'] = response.data.work_status;
            });
        },

        /*
        |--------------------------------------------------------------------------
        | Прочие функции
        |--------------------------------------------------------------------------
        */

        formatDate(date) {
            if (date) {
                return moment(date, 'YYYY-MM-DD').format('DD.MM.YYYY');
            }

            return null;
        },
        onSort(column)
        {
            this.cleanAllErrors();
            if (this.queries.sort == column)
                column = '-'+column;
            this.queries.sort = column;
        },
        onFilterChange(filter) {
            this.cleanAllErrors();
            Object.keys(this.queries).forEach((key) => {
                if (key.includes('filter'))
                    delete this.queries[key];
            });
            this.queries = { ...this.queries, ...filter };
        },
        onFilterClear() {
            this.cleanAllErrors();
            this.onFilterChange({});
        },
        setHeight() {
            const correction = 72;
            let offset = document.getElementById("list_wrap").offsetTop;
            let height = window.innerHeight - offset - correction;
            document.getElementById('list_container').style.height = height + "px"
        }
    },
    mounted() {
        window.onload = this.setHeight;
        window.onresize = this.setHeight;
        this.setHeight();
        this.ps = this.$refs.scroll.$el;
        this.leads.data.forEach(lead => {
            lead.changed = false;
        });

        /**
         * Подтверждение о потери изменений
         */
        this.event = router.on('before', (event) => {
            if (this.changesMade) {
                if (!confirm('Вы потеряете внесенные изменения. Вы действительно хотите уйти?')) {
                    event.preventDefault();
                } else {
                    this.errors = {};
                    this.badLeads = [];
                    this.event();
                }
            }
        })
    },
    watch: {
        queries: {
            handler(newValue) {
                let queryRoute = route(
                    'moderation.leads',
                    newValue
                );
                this.$inertia.get(queryRoute, {}, { preserveState: true, replace: true });
            },
            deep: true
        }
    },
    computed: {
        changesMade() {
            let changed_leads = this.leads.data.filter(lead => lead.changed === true);
            if (changed_leads.length > 0) {
                return true;
            }
            return false;
        }
    }
}
</script>

<style type="scss">
    .editable_field{
        cursor: pointer;
    }
    .list-input{
        width: 50px;
        margin: auto;
        text-align: center;
    }
    .list-input:focus{
        border: 1px solid #5e72e4;
    }
    .list-input.is-invalid{
        background: none;
        padding-right: 0.75rem;
        border: 1px solid #fd5c70;
    }

    .btn-mdr{
        width: 34px;
        height: 34px;
        padding: 0.5rem 0.5rem;
        font-size: 0.75rem;
        border-radius: 0.5rem;
    }

    .spinner-border{
        width: 34px;
        height: 34px;
        margin-left: 7px;
        vertical-align: middle;
    }
</style>