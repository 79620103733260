import { usePage } from "@inertiajs/vue3";

export function usePermissions() {
    const hasRole = (name) => usePage().props.auth.user.roles.includes(name);
    const hasPermission = (name) => {
        if (!name) {
            return false;
        }
        if (usePage().props.auth.user.roles.includes('Admin')) {
            return true;
        }

        return usePage().props.auth.user.permissions.some(permission => permission.name === name)
    };

    return { hasRole, hasPermission };
}