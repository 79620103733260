// Filter
function initFilter() {
    if (document.querySelector('.filter-plugin')) {
        var fixedPlugin = document.querySelector('.filter-plugin');
        var fixedPluginButton = document.querySelector('.filter-plugin-button');
        var fixedPluginButtonNav = document.querySelector('.filter-plugin-button-nav');
        var fixedPluginCard = document.querySelector('.filter-plugin .card');
        var fixedPluginCloseButton = document.querySelectorAll('.filter-plugin-close-button');
        
        var navbar = document.getElementById('navbarBlur');
        var buttonNavbarFixed = document.getElementById('navbarFixed');
  
        if (fixedPluginButton) {
            fixedPluginButton.onclick = function () {
                if (!fixedPlugin.classList.contains('show')) {
                    fixedPlugin.classList.add('show');
                } else {
                    fixedPlugin.classList.remove('show');
                }
            }
        }
  
        if (fixedPluginButtonNav) {
            fixedPluginButtonNav.onclick = function () {
                if (!fixedPlugin.classList.contains('show')) {
                    fixedPlugin.classList.add('show');
                } else {
                    fixedPlugin.classList.remove('show');
                }
            }
        }
  
        fixedPluginCloseButton.forEach(function (el) {
            el.onclick = function () {
                fixedPlugin.classList.remove('show');
            }
        })
  
        // document.querySelector('body').onclick = function (e) {
        //     if (e.target != fixedPluginButton && e.target != fixedPluginButtonNav && e.target.closest('.filter-plugin .card') != fixedPluginCard) {
        //         fixedPlugin.classList.remove('show');
        //     }
        // }
  
        if (navbar) {
            if (navbar.getAttribute('data-scroll') == 'true' && buttonNavbarFixed) {
                buttonNavbarFixed.setAttribute("checked", "true");
            }
        }
  
    }
}

// Side Edit
function initSideEdit() {
    var editPluginButtonNav = document.querySelector('.edit-plugin-button-nav');

    if (editPluginButtonNav) {
        editPluginButtonNav.onclick = function (event) {
            event.preventDefault();
            vm.$refs.kanban.toggleShowForm();
        }
    }
}

function initSideVacancyShow() {
    const sideShows = document.querySelectorAll('.custom-lead-bage');

    if (sideShows.length > 0) {
      for (const sideShow of sideShows) {
        sideShow.onclick = function (event) {
          event.preventDefault();
            let id = $(this).data('id');
            if (id > 0) {
                svs.$refs.sidebar_vacancy_show.showItemById(id);
            }
        };
      }
    } 
}



function initComponents(target='body') {
    $(target).find("[data-init-function]").not("[data-initialized=true]").each(function () {
        var element = $(this);

        var functionName = element.data('init-function');

        if (typeof window[functionName] === "function") {
            window[functionName](element);
            element.attr('data-initialized', 'true');
        }
    });
}

$(function () {
    initComponents();

    initFilter();
    initSideEdit();
    initSideVacancyShow();
});