<template>
    <Doughnut :data="data" :options="options" />
</template>
  

<script>
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import { Doughnut } from 'vue-chartjs';

ChartJS.register(ArcElement, Tooltip, Legend)

export default {
  components: {
    Doughnut
  },
  props: {
    data: Object,
    options: Object,
  }
}
</script>