<template>
    <div class="form-group">
        <label v-if="label" :for="id" class="form-control-label">{{ label }}</label>
        <input class="form-control" type="file" @input="$emit('input', $event)" :id="id" :class="class" :name="name"/>
    </div>
</template>

<script>
import { nanoid } from 'nanoid'

export default {
    components: {},
    props: ['name', 'label', 'class', 'type'],
    data() {
        return {};
    },
    computed: {
        id: function ()  {
            return this.name + "-" + nanoid(5);
        }
    },
}
</script>