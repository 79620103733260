<template>
    <Head :title="$t('Recruiter plans')"/>
    <Listnav :title="$t('Recruiter plans')"></Listnav>
    <div class="recruiter-plans">
        <div class="container-fluid pt-4">
            <div id="list_wrap">
                <div class="row">
                    <div class="col-12">
                        <div class="card" id="list">
                            <div class="px-4 pt-4">
                                <span>
                                    <Alert :errors="errors" :showSuccess="false" :wrapper="null"/>
                                </span>
                            </div>
                            <div class="row text-center">
                                <div class="col-3">{{ $t('User') }}</div>
                                <div class="col-2">{{ $t('Plan') }}</div>
                                <div class="col-3">{{ $t('From') }}</div>
                                <div class="col-3">{{ $t('To') }}</div>
                                <div class="col-1"></div>
                            </div>
                            <div class="card-body pb-2">
                                <div class="row mb-2 text-center" v-for="record in plans">
                                    <div class="col-3">{{ getUser(record.user_id) }}</div>
                                    <div class="col-2">{{ record.ads }}</div>
                                    <div class="col-3">{{ record.from }}</div>
                                    <div class="col-3">{{ record.to }}</div>
                                    <div class="col-1">
                                        <button class="btn btn-danger btn-mdr mb-0" type="button" @click="deleteRecord(record.id)">
                                            <i class="fas fa-minus opacity-10" aria-hidden="true" style="top: -1px;"></i>
                                        </button>
                                    </div>
                                </div>
                                <div class="row text-center">
                                    <div class="col-3">
                                        <SimpleSelect  
                                            v-model="form.user_id"
                                            name="user" 
                                            :label="$t('recruiter')" 
                                            :multiple="false" 
                                            :options="users" 
                                            :class="[(errors.user_id ? 'is-invalid' : '')]"
                                        />
                                    </div>
                                    <div class="col-2">
                                        <TextInput 
                                            v-model="form.ads"
                                            :label="$t('ads')" 
                                        />
                                    </div>
                                    <div class="col-3">
                                        <div class="form-group">
                                            <label class="form-control-label">Период</label>
                                            <VueDatePicker 
                                                class="mx-auto"
                                                v-model="form.week" 
                                                format="ww.yyyy" 
                                                locale="ru"
                                                :enable-time-picker="false"
                                                week-picker
                                                auto-apply 
                                            />
                                        </div>
                                    </div>
                                    <div class="col-3 d-flex align-items-center justify-content-center">
                                        <span class="mt-3">{{ displayRange }}</span>
                                    </div>
                                    <div class="col-1 d-flex align-items-center justify-content-center">
                                        <button class="btn btn-success btn-mdr mb-0 mt-2" type="button" @click="form.post(route('recruiter-plan.store'))">
                                            <i class="fas fa-check opacity-10" aria-hidden="true" style="top: -1px;"></i>
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { useForm, Link, Head, router  } from "@inertiajs/vue3";
import AuthenticatedLayout from "../../Layouts/AuthenticatedLayout.vue";
import Alert from "../../Components/Alert.vue";
import Listnav from "../../Layouts/Navbars/Auth/Listnav.vue";
import Pagination from "../../Components/Pagination.vue";
import { usePermissions } from '../../Composables/usePermissions';
import { useToast } from "vue-toastification";
import { Date, TextInput, SimpleSelect } from "../../Components/Form";
import VueDatePicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css';
import moment from 'moment';
import _ from 'lodash';

export default {
    layout: AuthenticatedLayout,
    components: {
        Listnav, Alert, Link, Head, Pagination, TextInput, Date, SimpleSelect, VueDatePicker
    },
    props: {
        plans: Array,
        users: Array,
        errors: Object
    },
    setup() {
        const form = useForm({
            user_id: null,
            ads: 0,
            from: null,
            to: null,
            week: null
        });

        return { form };
    },
    data() {
        return {
            displayRange: null,
        }
    },
    methods: {
        getUser(userId) {
            const user = this.users.filter(x => x.value == userId);
            if (user.length)
                return user[0].label;
        },
        deleteRecord(id) {
            router.delete(route('recruiter-plan.destroy', { recruiter_plan: id }));
        },
        submit() {
            form.transform((data) => {
                data.from = data.week[0];
                data.to = data.week[1];
                return data;
            }).post(route('recruiter-plan.store'));
        }
    },
    watch: {
        'form.week': function(val){
            if (val) {
                const from = moment(val[0]);
                const to = moment(val[1]);
                this.displayRange = from.format('DD.MM.YYYY') + ' - ' + to.format('DD.MM.YYYY');
                this.form.from = from.format('YYYY-MM-DD');
                this.form.to = to.format('YYYY-MM-DD');
            }
            else {
                this.displayRange = null;
                this.form.from = null;
                this.form.to = null;
            }
            console.log(val);
        }
    }
}
</script>